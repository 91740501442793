import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CMSCATEGORY } from './cmsCategoryAction';
import { getNewToken } from '../../../services/getNewToken';
import {
    getAllCmsCategoriesSuccessAction,
    getAllCmsCategoriesFailureAction,
    getByIdCmsCategorySuccessAction,
    getByIdCmsCategoryFailureAction,
    changeStatusCmsCategorySuccessAction,
    changeStatusCmsCategoryFailureAction,
    deleteCmsCategorySuccessAction,
    deleteCmsCategoryFailureAction,
    createCmsCategorySuccessAction,
    createCmsCategoryFailureAction,
    updateCmsCategorySuccessAction,
    updateCmsCategoryFailureAction,
    removeCmsCategorySuccessAction,
    removeCmsCategoryFailureAction,
    deleteImageCmsCategorySuccessAction,
    deleteImageCmsCategoryFailureAction,
    getAllCmsCategoriesContentSuccessAction,
    getAllCmsCategoriesContentFailureAction,
    getAllCmsCategoriesByContentIdSuccessAction,
    getAllCmsCategoriesByContentIdFailureAction
} from './cmsCategoryAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsCategory/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/CmsCategory/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsCategory/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsCategory/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/CmsCategory/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/CmsCategory/Update';
const API_REMOVE = config.HOST + config.VERSION + '/CmsCategory/Remove';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/CmsCategoryAttachment/DeleteImage?imagePath=';
const API_GETALL_CONTENT = config.HOST + config.VERSION + '/CmsContent/GetAll';
const API_GETALL_BY_CONTENT_ID = config.HOST + config.VERSION + '/CmsCategory/GetAllCategoriesByContentId';

// GETALL
const getAllCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsCategoriesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsCategoriesFailureAction(error));
                    }
                })
            );
    })
);

// GETALL BY CONTENT ID
const getAllCmsCategoryByContentIdEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.GETALL_BY_CONTENT_ID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_CONTENT_ID + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsCategoriesByContentIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsCategoriesByContentIdFailureAction(error));
                    }
                })
            );
    })
);

// GET ALL CONTENT
const getAllCmsCategoryContentEpic = action$ => action$.pipe(
    ofType(CMSCATEGORY.GETALL_CONTENT),
    mergeMap(() => {
        return ajax.getJSON(API_GETALL_CONTENT, config.HEADERS()).pipe(
            map(response => getAllCmsCategoriesContentSuccessAction(response)),
            catchError(error => of(getAllCmsCategoriesContentFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

// GETBYID
const getByIdCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.CREATE),
    mergeMap((action) => {
        console.log(action.payload)
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(createCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(removeCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

// DELETE IMAGE
const deleteImageCmsCategoryEpic = (action$, store) => action$.pipe(
    ofType(CMSCATEGORY.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteImageCmsCategorySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteImageCmsCategoryFailureAction(error));
                    }
                })
            );
    })
);

export {
    getAllCmsCategoryEpic, getByIdCmsCategoryEpic, changeStatusCmsCategoryEpic,
    deleteCmsCategoryEpic, getAllCmsCategoryByContentIdEpic, createCmsCategoryEpic,
    updateCmsCategoryEpic, removeCmsCategoryEpic, deleteImageCmsCategoryEpic,
    getAllCmsCategoryContentEpic,
};