import { combineEpics } from 'redux-observable';

//DASHBAORD
import { dashboardEpic } from 'features/dashboard/dashboardEpic';

//Login
import { loginEpic, getCurrentProfileEpic } from 'features/login/loginEpic';

//Logout
import { logoutEpic } from 'features/logout/logoutEpic';

//FORGOT PASSWORD
import { forgotpasswordEpic } from 'features/forgotpassword/forgotpasswordEpic';

//RESET PASSWORD
import { resetpasswordEpic } from 'features/resetpassword/resetpasswordEpic';

//PROFILE
import {
  getByIdProfileEpic,
  updateProfileEpic,
} from 'features/profile/profileEpic';

//ROLE
import {
  getAllRoleEpic,
  getByIdRoleEpic,
  changeStatusRoleEpic,
  deleteRoleEpic,
  addRoleEpic,
  editRoleEpic,
} from 'features/role/roleEpic';

//System API
import {
  getSystemApiEpic,
  getSystemApiByIdEpic,
  changeStatusSystemApiEpic,
  deleteSystemApiEpic,
  createSystemApiEpic,
  updateSystemApiEpic,
} from 'features/systemApi/systemApiEpic';

//USER
import {
  getAllUserEpic,
  changeStatusUserEpic,
  deleteUserEpic,
  addUserEpic,
  editUserEpic,
  getByIdUserEpic,
  checkValidUserNameEpic,
  checkValidEmailEpic,
  updateSystemApiForUserEpic,
} from 'features/user/userEpic';

//PROVINCIAL
import {
  getAllProvincialEpic,
  changeStatusProvincialEpic,
  deleteProvincialEpic,
  addProvincialEpic,
  editProvincialEpic,
  getByIdProvincialEpic,
} from 'features/provincial/provincialEpic';

//DISTRICT
import {
  getAllDistrictEpic,
  getAllFullDistrictEpic,
  changeStatusDistrictEpic,
  deleteDistrictEpic,
  addDistrictEpic,
  editDistrictEpic,
  getByIdDistrictEpic,
  getByProvinceCodeEpic,
} from 'features/district/districtEpic';

//WARD
import {
  getAllWardEpic,
  getAllFullWardEpic,
  changeStatusWardEpic,
  deleteWardEpic,
  addWardEpic,
  editWardEpic,
  getByIdWardEpic,
  getByDistrictEpic,
  getByDistrictCodeWardEpic,
} from 'features/ward/wardEpic';

//FEEDBACK
import {
  getAllFeedbackEpic,
  changeStatusFeedbackEpic,
  deleteFeedbackEpic,
  addFeedbackEpic,
  editFeedbackEpic,
  getByIdFeedbackEpic,
} from 'features/feedback/feedbackEpic';

//ACCESS
import {
  getAllAccessEpic,
  changeStatusAccessEpic,
  deleteAccessEpic,
  addAccessEpic,
  editAccessEpic,
  getByIdAccessEpic,
  getByPageNumberAccessEpic,
} from 'features/access/accessEpic';

//CONTACT
import {
  getAllContactEpic,
  changeStatusContactEpic,
  deleteContactEpic,
  addContactEpic,
  editContactEpic,
  getByIdContactEpic,
} from 'features/contact/contactEpic';

//CURRENT
import {
  getAllCurrencyEpic,
  changeStatusCurrencyEpic,
  deleteCurrencyEpic,
  addCurrencyEpic,
  editCurrencyEpic,
  getByIdCurrencyEpic,
} from 'features/currency/currencyEpic';

//CURRENT
import {
  getAllBuildingClassEpic,
  changeStatusBuildingClassEpic,
  deleteBuildingClassEpic,
  addBuildingClassEpic,
  editBuildingClassEpic,
  getByIdBuildingClassEpic,
} from 'features/buildingClass/buildingClassEpic';

//DEVELOPMENTTYPE
import {
  getAllDevelopmentTypeEpic,
  changeStatusDevelopmentTypeEpic,
  deleteDevelopmentTypeEpic,
  addDevelopmentTypeEpic,
  editDevelopmentTypeEpic,
  getByIdDevelopmentTypeEpic,
} from 'features/developmentType/developmentTypeEpic';

//DIRECTION
import {
  getAllDirectionEpic,
  changeStatusDirectionEpic,
  deleteDirectionEpic,
  addDirectionEpic,
  editDirectionEpic,
  getByIdDirectionEpic,
} from 'features/direction/directionEpic';

//OBJECTTYPE
import {
  getAllObjectTypeEpic,
  changeStatusObjectTypeEpic,
  deleteObjectTypeEpic,
  addObjectTypeEpic,
  editObjectTypeEpic,
  getByIdObjectTypeEpic,
} from 'features/objectType/objectTypeEpic';

//BUILDING
import {
  getAllBuildingEpic,
  changeStatusBuildingEpic,
  deleteBuildingEpic,
  addBuildingEpic,
  editBuildingEpic,
  getByIdBuildingEpic,
} from 'features/building/buildingEpic';

//FEEDBACKBYUSER
import {
  getAllFeedbackByUserEpic,
  changeStatusFeedbackByUserEpic,
  deleteFeedbackByUserEpic,
  addFeedbackByUserEpic,
  editFeedbackByUserEpic,
  getByIdFeedbackByUserEpic,
} from 'features/feedbackByUser/feedbackByUserEpic';

//IMAGETYPE
import {
  getAllImageTypeEpic,
  changeStatusImageTypeEpic,
  deleteImageTypeEpic,
  addImageTypeEpic,
  editImageTypeEpic,
  getByIdImageTypeEpic,
} from 'features/imageType/imageTypeEpic';

//TOKEN
import { tokenEpic } from 'features/token/tokenEpic';

//CMS Article
import {
  getAllCmsArticleEpic,
  getByIdCmsArticleEpic,
  changeStatusCmsArticleEpic,
  deleteCmsArticleEpic,
  createCmsArticleEpic,
  updateCmsArticleEpic,
  removeCmsArticleEpic,
  deleteImageCmsArticleEpic,
  getAllCmsArticleByContentIdEpic,
  getAllCmsArticleByCategoryIdEpic,
  updateSpecialFieldCmsArticleEpic,
} from 'features/cms/article/cmsArticleEpic';

//CMS Category
import {
  getAllCmsCategoryEpic,
  getByIdCmsCategoryEpic,
  changeStatusCmsCategoryEpic,
  deleteCmsCategoryEpic,
  createCmsCategoryEpic,
  updateCmsCategoryEpic,
  removeCmsCategoryEpic,
  deleteImageCmsCategoryEpic,
  getAllCmsCategoryContentEpic,
  getAllCmsCategoryByContentIdEpic,
} from 'features/cms/category/cmsCategoryEpic';

//CMS Carousel
import {
  getAllCmsCarouselsEpic,
  getByIdCmsCarouselEpic,
  changeStatusCmsCarouselEpic,
  deleteCmsCarouselEpic,
  createCmsCarouselEpic,
  updateCmsCarouselEpic,
  removeCmsCarouselEpic,
  deleteImageCmsCarouselEpic,
} from 'features/cms/carousel/cmsCarouselEpic';

//CMS CONTENT
import {
  getAllCmsContentsEpic,
  getAllCmsContentsByStatusEpic,
  getByIdCmsContentEpic,
  changeStatusCmsContentEpic,
  deleteCmsContentEpic,
  createCmsContentEpic,
  updateCmsContentEpic,
  removeCmsContentEpic,
  deleteImageCmsContentEpic,
} from 'features/cms/content/cmsContentEpic';

//UPLOAD
import {
  chunkUploadEpic,
  createUploadEpic,
} from 'common/components/upload/uploadEpic';

//UPLOAD MULTIPLE
import {
  chunkMultipleUploadEpic,
  createMultipleUploadEpic,
} from 'common/components/uploadMultiple/uploadMultipleEpic';

//UPLOAD MULTIPLE
import {
  chunkThumbnailMultipleUploadEpic,
  thumbnailMultipleUploadEpic,
  createThumbnailMultipleUploadEpic,
} from 'common/components/uploadThumbnailMultiple/uploadThumbnailMultipleEpic';

//UPLOAD CROP MULTIPLE
import {
  chunkCropMultipleUploadEpic,
  createCropMultipleUploadEpic,
} from 'common/components/uploadCropMultiple/uploadCropMultipleEpic';

//UPLOAD VIDEO
import {
  chunkVideoUploadEpic,
  createVideoUploadEpic,
} from 'common/components/uploadVideo/uploadVideoEpic';

//UPLOAD IMAGE CROP
import {
  uploadImageBase64Epic,
  deleteImageBase64Epic,
} from 'common/components/uploadImageCrop/uploadImageCropEpic';

//FILE
import { getAllFileEpic, deleteFileEpic } from 'features/file/fileEpic';

//PROPERTY
import {
  getAllPropertyEpic,
  getByIdPropertyEpic,
  changeStatusPropertyEpic,
  changeOutStandingPropertyEpic,
  deletePropertyEpic,
  deleteMultiplePropertyEpic,
  createPropertyEpic,
  updatePropertyEpic,
  removePropertyEpic,
  deleteImagePropertyEpic,
  loadPropertyTypeEpic,
  loadDistrictEpic,
  loadWardEpic,
} from 'features/property/propertyEpic';

//PROPERTYTYPE
import {
  getAllPropertyTypeEpic,
  getByIdPropertyTypeEpic,
  changeStatusPropertyTypeEpic,
  deletePropertyTypeEpic,
  createPropertyTypeEpic,
  updatePropertyTypeEpic,
  removePropertyTypeEpic,
  getAllPropertyTypeByPurposeIdEpic,
} from 'features/propertytype/propertyTypeEpic';

//PROJECT
import {
  getAllProjectEpic,
  getByIdProjectEpic,
  changeStatusProjectEpic,
  deleteProjectEpic,
  updateFieldProjectEpic,
  createProjectEpic,
  updateProjectEpic,
  removeProjectEpic,
  loadWardProjectEpic,
  loadProvinceProjectEpic,
  deleteProjectImageTypeEpic,
} from 'features/project/projectEpic';

//OFFICE
import {
  getAllOfficeEpic,
  getByIdOfficeEpic,
  changeStatusOfficeEpic,
  deleteOfficeEpic,
  updateFieldOfficeEpic,
  createOfficeEpic,
  updateOfficeEpic,
  removeOfficeEpic,
  loadWardOfficeEpic,
  loadProvinceOfficeEpic,
  deleteOfficeImageTypeEpic,
} from 'features/office/officeEpic';

//PURPOSE
import {
  getAllPurposeEpic,
  getAllPurposeByStatusEpic,
  getByIdPurposeEpic,
  changeStatusPurposeEpic,
  deletePurposeEpic,
  createPurposeEpic,
  updatePurposeEpic,
  removePurposeEpic,
} from 'features/purpose/purposeEpic';

//UTILITY
import {
  getAllUtilityEpic,
  getByIdUtilityEpic,
  changeStatusUtilityEpic,
  deleteUtilityEpic,
  createUtilityEpic,
  updateUtilityEpic,
  removeUtilityEpic,
} from 'features/utility/utilityEpic';

//UTILITY
import {
  getAllProgressEpic,
  getByIdProgressEpic,
  changeStatusProgressEpic,
  deleteProgressEpic,
  createProgressEpic,
  updateProgressEpic,
  removeProgressEpic,
} from 'features/progress/progressEpic';

//GLOBAL CONFIG
import {
  getConfigsEpic,
  changeStatusConfigEpic,
  deleteConfigEpic,
  createConfigEpic,
  updateConfigEpic,
  getConfigByIdEpic,
} from 'features/globalConfig/globalConfigEpic';

//CONFIG Type
import {
  getConfigTypesEpic,
  changeStatusConfigTypeEpic,
  deleteConfigTypeEpic,
  createConfigTypeEpic,
  updateConfigTypeEpic,
  getConfigTypeByIdEpic,
} from 'features/configType/configTypeEpic';

//Language
import {
  getLanguagesEpic,
  changeStatusLanguageEpic,
  deleteLanguageEpic,
  createLanguageEpic,
  updateLanguageEpic,
  getLanguageByIdEpic,
} from 'features/languageConfig/languageEpic';

//Menu
import {
  getMenusEpic,
  changeStatusMenuEpic,
  deleteMenuEpic,
  createMenuEpic,
  updateMenuEpic,
  getMenuByIdEpic,
} from 'features/menu/menuEpic';

//TransLationEpic
import { changeTransLationEpic } from '../common/components/transLation/transLationEpic';

//ChartEpic
import { getByYearProvincelAccessEpic } from '../features/access/Components/chart/chartEpic';

//RecycleBin
import {
  getAllRecycleBinEpic,
  deleteAllRecycleBinEpic,
  restoreRecordRecycleBinEpic,
} from "../features/recyclebin/recyclebinEpic";

//SignUpSeeHouse
import {
  getAllSignUpSeeHouseEpic,
  changeStatusSignUpSeeHouseEpic,
  deleteSignUpSeeHouseEpic,
} from 'features/signupseehouse/signupseehouseEpic';

const rootEpic = combineEpics(
  //DASHBOARD
  dashboardEpic,

  //LOGIN
  loginEpic,
  getCurrentProfileEpic,

  //LOGOUT
  logoutEpic,

  //FORGOTPASSWORD
  forgotpasswordEpic,

  //RESETPASSWORD
  resetpasswordEpic,

  //PROFILE
  getByIdProfileEpic,
  updateProfileEpic,

  //ROLE
  getAllRoleEpic,
  getByIdRoleEpic,
  changeStatusRoleEpic,
  deleteRoleEpic,
  addRoleEpic,
  editRoleEpic,

  //System API
  getSystemApiEpic,
  getSystemApiByIdEpic,
  changeStatusSystemApiEpic,
  deleteSystemApiEpic,
  createSystemApiEpic,
  updateSystemApiEpic,

  //USER
  getAllUserEpic,
  changeStatusUserEpic,
  deleteUserEpic,
  addUserEpic,
  editUserEpic,
  getByIdUserEpic,
  checkValidUserNameEpic,
  checkValidEmailEpic,

  //TOKEN
  tokenEpic,

  //CMS ARTICLE
  getAllCmsArticleEpic,
  getByIdCmsArticleEpic,
  changeStatusCmsArticleEpic,
  deleteCmsArticleEpic,
  createCmsArticleEpic,
  updateCmsArticleEpic,
  removeCmsArticleEpic,
  deleteImageCmsArticleEpic,
  getAllCmsArticleByContentIdEpic,
  getAllCmsArticleByCategoryIdEpic,
  updateSpecialFieldCmsArticleEpic,

  //CMS CATEGORY
  getAllCmsCategoryEpic,
  getByIdCmsCategoryEpic,
  changeStatusCmsCategoryEpic,
  deleteCmsCategoryEpic,
  createCmsCategoryEpic,
  updateCmsCategoryEpic,
  removeCmsCategoryEpic,
  deleteImageCmsCategoryEpic,
  getAllCmsCategoryContentEpic,
  getAllCmsCategoryByContentIdEpic,

  //CMS CAROUSEL
  getAllCmsCarouselsEpic,
  getByIdCmsCarouselEpic,
  changeStatusCmsCarouselEpic,
  deleteCmsCarouselEpic,
  createCmsCarouselEpic,
  updateCmsCarouselEpic,
  removeCmsCarouselEpic,
  deleteImageCmsCarouselEpic,

  //CMS CONTENT
  getAllCmsContentsEpic,
  getAllCmsContentsByStatusEpic,
  getByIdCmsContentEpic,
  changeStatusCmsContentEpic,
  deleteCmsContentEpic,
  createCmsContentEpic,
  updateCmsContentEpic,
  removeCmsContentEpic,
  deleteImageCmsContentEpic,

  //UPLOAD
  chunkUploadEpic,
  createUploadEpic,

  //UPLOAD MULTIPLE
  chunkMultipleUploadEpic,
  //thumbnailMultipleUploadEpic,
  createMultipleUploadEpic,

  //UPLOAD THUMBNAIL MULTIPLE
  chunkThumbnailMultipleUploadEpic,
  thumbnailMultipleUploadEpic,
  createThumbnailMultipleUploadEpic,

  //UPLOAD CROP MULTIPLE
  chunkCropMultipleUploadEpic,
  createCropMultipleUploadEpic,

  //UPLOAD IMAGE CROP
  uploadImageBase64Epic,
  deleteImageBase64Epic,

  //UPLOAD VIDEO
  chunkVideoUploadEpic,
  createVideoUploadEpic,

  //FILE
  getAllFileEpic,
  deleteFileEpic,

  //PROPERTY
  getAllPropertyEpic,
  getByIdPropertyEpic,
  changeStatusPropertyEpic,
  changeOutStandingPropertyEpic,
  deletePropertyEpic,
  deleteMultiplePropertyEpic,
  createPropertyEpic,
  updatePropertyEpic,
  removePropertyEpic,
  deleteImagePropertyEpic,
  loadPropertyTypeEpic,
  loadDistrictEpic,
  loadWardEpic,

  //PROPERTY TYPE
  getAllPropertyTypeEpic,
  getByIdPropertyTypeEpic,
  changeStatusPropertyTypeEpic,
  deletePropertyTypeEpic,
  createPropertyTypeEpic,
  updatePropertyTypeEpic,
  removePropertyTypeEpic,
  getAllPropertyTypeByPurposeIdEpic,

  //PROJECT
  getAllProjectEpic,
  getByIdProjectEpic,
  changeStatusProjectEpic,
  deleteProjectEpic,
  updateFieldProjectEpic,
  createProjectEpic,
  updateProjectEpic,
  removeProjectEpic,
  loadWardProjectEpic,
  loadProvinceProjectEpic,
  deleteProjectImageTypeEpic,

  //OFFICE
  getAllOfficeEpic,
  getByIdOfficeEpic,
  changeStatusOfficeEpic,
  deleteOfficeEpic,
  updateFieldOfficeEpic,
  createOfficeEpic,
  updateOfficeEpic,
  removeOfficeEpic,
  loadWardOfficeEpic,
  loadProvinceOfficeEpic,
  deleteOfficeImageTypeEpic,

  //PURPOSE
  getAllPurposeEpic,
  getByIdPurposeEpic,
  changeStatusPurposeEpic,
  deletePurposeEpic,
  createPurposeEpic,
  updatePurposeEpic,
  removePurposeEpic,
  getAllPurposeByStatusEpic,

  //UTILITY
  getAllUtilityEpic,
  getByIdUtilityEpic,
  changeStatusUtilityEpic,
  deleteUtilityEpic,
  createUtilityEpic,
  updateUtilityEpic,
  removeUtilityEpic,

  //PROGRESS
  getAllProgressEpic,
  getByIdProgressEpic,
  changeStatusProgressEpic,
  deleteProgressEpic,
  createProgressEpic,
  updateProgressEpic,
  removeProgressEpic,

  //Global Config
  getConfigsEpic,
  changeStatusConfigEpic,
  deleteConfigEpic,
  createConfigEpic,
  updateConfigEpic,
  getConfigByIdEpic,

  //Config Type
  getConfigTypesEpic,
  changeStatusConfigTypeEpic,
  deleteConfigTypeEpic,
  createConfigTypeEpic,
  updateConfigTypeEpic,
  getConfigTypeByIdEpic,

  //Language
  getLanguagesEpic,
  changeStatusLanguageEpic,
  deleteLanguageEpic,
  createLanguageEpic,
  updateLanguageEpic,
  getLanguageByIdEpic,

  //Menu
  getMenusEpic,
  changeStatusMenuEpic,
  deleteMenuEpic,
  createMenuEpic,
  updateMenuEpic,
  getMenuByIdEpic,

  //provincial
  getAllProvincialEpic,
  changeStatusProvincialEpic,
  deleteProvincialEpic,
  addProvincialEpic,
  editProvincialEpic,
  getByIdProvincialEpic,
  checkValidEmailEpic,

  //dictrict
  getAllDistrictEpic,
  getAllFullDistrictEpic,
  changeStatusDistrictEpic,
  deleteDistrictEpic,
  addDistrictEpic,
  editDistrictEpic,
  getByIdDistrictEpic,
  getByProvinceCodeEpic,

  //ward
  getAllWardEpic,
  getAllFullWardEpic,
  changeStatusWardEpic,
  deleteWardEpic,
  addWardEpic,
  editWardEpic,
  getByIdWardEpic,
  getByDistrictEpic,
  getByDistrictCodeWardEpic,

  //Feedback
  getAllFeedbackEpic,
  changeStatusFeedbackEpic,
  deleteFeedbackEpic,
  addFeedbackEpic,
  editFeedbackEpic,
  getByIdFeedbackEpic,

  //Access
  getAllAccessEpic,
  changeStatusAccessEpic,
  deleteAccessEpic,
  addAccessEpic,
  editAccessEpic,
  getByIdAccessEpic,
  getByPageNumberAccessEpic,

  //Contact
  getAllContactEpic,
  changeStatusContactEpic,
  deleteContactEpic,
  addContactEpic,
  editContactEpic,
  getByIdContactEpic,

  //Currency
  getAllCurrencyEpic,
  changeStatusCurrencyEpic,
  deleteCurrencyEpic,
  addCurrencyEpic,
  editCurrencyEpic,
  getByIdCurrencyEpic,

  //BUILDINGCLASS
  getAllBuildingClassEpic,
  changeStatusBuildingClassEpic,
  deleteBuildingClassEpic,
  addBuildingClassEpic,
  editBuildingClassEpic,
  getByIdBuildingClassEpic,

  //DevelopmentType
  getAllDevelopmentTypeEpic,
  changeStatusDevelopmentTypeEpic,
  deleteDevelopmentTypeEpic,
  addDevelopmentTypeEpic,
  editDevelopmentTypeEpic,
  getByIdDevelopmentTypeEpic,

  //Direction
  getAllDirectionEpic,
  changeStatusDirectionEpic,
  deleteDirectionEpic,
  addDirectionEpic,
  editDirectionEpic,
  getByIdDirectionEpic,

  //ObjectType
  getAllObjectTypeEpic,
  changeStatusObjectTypeEpic,
  deleteObjectTypeEpic,
  addObjectTypeEpic,
  editObjectTypeEpic,
  getByIdObjectTypeEpic,

  //ObjectType
  getAllBuildingEpic,
  changeStatusBuildingEpic,
  deleteBuildingEpic,
  addBuildingEpic,
  editBuildingEpic,
  getByIdBuildingEpic,

  //FeedbackByUser
  getAllFeedbackByUserEpic,
  changeStatusFeedbackByUserEpic,
  deleteFeedbackByUserEpic,
  addFeedbackByUserEpic,
  editFeedbackByUserEpic,
  getByIdFeedbackByUserEpic,

  //ImageType

  getAllImageTypeEpic,
  changeStatusImageTypeEpic,
  deleteImageTypeEpic,
  addImageTypeEpic,
  editImageTypeEpic,
  getByIdImageTypeEpic,

  //TransLation
  changeTransLationEpic,

  //Chart
  getByYearProvincelAccessEpic,

  //recyclebin
  getAllRecycleBinEpic,
  deleteAllRecycleBinEpic,
  restoreRecordRecycleBinEpic,
  //SignUpSeeHouse
  getAllSignUpSeeHouseEpic,
  changeStatusSignUpSeeHouseEpic,
  deleteSignUpSeeHouseEpic
);
export default rootEpic;
