import { handleActions } from "redux-actions";
import { CONFIG_TYPE } from "../configTypeAction";
const initialState = {
    isLoading: false,
    isError: false,
    responseData: {},
};
const actions = {
    [CONFIG_TYPE.ADD]: () => ({
        isLoading: true,
    }),
    [CONFIG_TYPE.ADD_SUCCESS]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        responseData: responseData.response ? responseData.response : responseData,
    }),
    [CONFIG_TYPE.ADD_FAILURE]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        isError: true,
        responseData,
    }),
    [CONFIG_TYPE.CREATE_RESET]: (state, { payload: responseData }) => {
        state = initialState;
        return state;
    },
};
export default handleActions(actions, initialState);