import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UPLOAD_VIDEO } from './uploadVideoAction';
import { chunkVideoUploadSuccessAction, chunkVideoUploadFailureAction, createVideoUploadSuccessAction, createVideoUploadFailureAction } from './uploadVideoAction';
import config from 'common/config';


const API_CHUNK = config.HOST + config.VERSION + '/File/FileChunks';
const API_CREATE = config.HOST + config.VERSION + '/File/Create';


// CHUNK UPLOAD_VIDEO
const chunkVideoUploadEpic = action$ => action$.pipe(
    ofType(UPLOAD_VIDEO.CHUNK_VIDEO),
    mergeMap((action) => {
        const formData = new FormData();
        formData.append('fileName', action.payload.fileName);
        formData.append('file', action.payload.chunk);
        //HEADERS: Content-Type: application/octet-stream
        return ajax.post(API_CHUNK, formData, config.HEADERS(true)).pipe(
            map(response => chunkVideoUploadSuccessAction(response)),
            catchError(error => of(chunkVideoUploadFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

// CREATE UPLOAD_VIDEO
const createVideoUploadEpic = action$ => action$.pipe(
    ofType(UPLOAD_VIDEO.CREATE_VIDEO),
    mergeMap((action) => {
        return ajax.post(API_CREATE, action.payload, config.HEADERS()).pipe(
            map(response => createVideoUploadSuccessAction(response)),
            catchError(error => of(createVideoUploadFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);


export { chunkVideoUploadEpic, createVideoUploadEpic };