import { createAction } from "redux-actions";
export const FEEDBACK = {
    GETALL: "FEEDBACK_GETALL",
    GETALL_SUCCESS: "FEEDBACK_GETALL_SUCCESS",
    GETALL_FAILURE: "FEEDBACK_GETALL_FAILURE",
    GETBYID: "FEEDBACK_GETBYID",
    GETBYID_SUCCESS: "FEEDBACK_GETBYID_SUCCESS",
    GETBYID_FAILURE: "FEEDBACK_GETBYID_FAILURE",
    CHANGE_STATUS: "FEEDBACK_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "FEEDBACK_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "FEEDBACK_CHANGE_STATUS_FAILURE",
    DELETE: "FEEDBACK_DELETE",
    DELETE_SUCCESS: "FEEDBACK_DELETE_SUCCESS",
    DELETE_FAILURE: "FEEDBACK_DELETE_FAILURE",
    ADD: "FEEDBACK_ADD",
    ADD_SUCCESS: "FEEDBACK_ADD_SUCCESS",
    ADD_FAILURE: "FEEDBACK_ADD_FAILURE",
    EDIT: "FEEDBACK_EDIT",
    EDIT_SUCCESS: "FEEDBACK_EDIT_SUCCESS",
    EDIT_FAILURE: "FEEDBACK_EDIT_FAILURE",
    CREATE_RESET: 'FEEDBACK_CREATE_RESET',
    GETBY_PROVINCE:'FEEDBACK_GETBY_PROVINCE_CODE',
    GETBY_PROVINCE_SUCCESS:'FEEDBACK_GETBY_PROVINCE_SUCCESS',
    GETBY_PROVINCE_FAILURE:'FEEDBACK_GETBY_PROVINCE_FAILURE'
};
export const getAllFeedbackAction = createAction(FEEDBACK.GETALL);
export const getAllFeedbackSuccessAction = createAction(FEEDBACK.GETALL_SUCCESS);
export const getAllFeedbackFailureAction = createAction(FEEDBACK.GETALL_FAILURE);
export const getByIdFeedbackAction = createAction(FEEDBACK.GETBYID);
export const getByIdFeedbackSuccessAction = createAction(FEEDBACK.GETBYID_SUCCESS);
export const getByIdFeedbackFailureAction = createAction(FEEDBACK.GETBYID_FAILURE);
export const changeStatusFeedbackAction = createAction(FEEDBACK.CHANGE_STATUS);
export const changeStatusFeedbackSuccessAction = createAction(FEEDBACK.CHANGE_STATUS_SUCCESS);
export const changeStatusFeedbackFailureAction = createAction(FEEDBACK.CHANGE_STATUS_FAILURE);
export const deleteFeedbackAction = createAction(FEEDBACK.DELETE);
export const deleteFeedbackSuccessAction = createAction(FEEDBACK.DELETE_SUCCESS);
export const deleteFeedbackFailureAction = createAction(FEEDBACK.DELETE_FAILURE);
export const addFeedbackAction = createAction(FEEDBACK.ADD);
export const addFeedbackSuccessAction = createAction(FEEDBACK.ADD_SUCCESS);
export const addFeedbackFailureAction = createAction(FEEDBACK.ADD_FAILURE);
export const editFeedbackAction = createAction(FEEDBACK.EDIT);
export const editFeedbackSuccessAction = createAction(FEEDBACK.EDIT_SUCCESS);
export const editFeedbackFailureAction = createAction(FEEDBACK.EDIT_FAILURE);
export const createResetFeedbackAction = createAction(FEEDBACK.CREATE_RESET);