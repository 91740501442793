import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CHART } from './chartAction';
import { getNewToken } from '../../../../services/getNewToken';
import {
    getByYearProvincelAccessOfChartSuccessAction,
    getByYearProvincelAccessOfChartFailureAction,
} from './chartAction';
import config from 'common/config';

const API_GETBY_PROVINCEL_YEAR = config.HOST + config.VERSION + '/CmsStatisticalAccess/StatisticalAccessByYearAndMonth?year=';
// GETBYID
const getByYearProvincelAccessEpic = (action$, store) => action$.pipe(
    ofType(CHART.GETBY_PROVINCEL_YEAR),
    mergeMap((action) => {
        console.log("action",action)
        // let encodeUrl = encodeURI(action.payload.provinceName)  
        return defer(() => {
            return ajax.getJSON(API_GETBY_PROVINCEL_YEAR + action.payload.year + "&month=" + action.payload.month, config.HEADERS());
        })
            .pipe(
                map((response) => getByYearProvincelAccessOfChartSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByYearProvincelAccessOfChartFailureAction(error));
                    }
                })
            );
    })
);



export {getByYearProvincelAccessEpic};