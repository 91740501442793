import { createAction } from "redux-actions";
export const SIGNUP_SEE_HOUSE = {
    GETALL: "SIGNUP_SEE_HOUSE_GETALL",
    GETALL_SUCCESS: "SIGNUP_SEE_HOUSE_GETALL_SUCCESS",
    GETALL_FAILURE: "SIGNUP_SEE_HOUSE_GETALL_FAILURE",
    CHANGE_STATUS: "SIGNUP_SEE_HOUSE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "SIGNUP_SEE_HOUSE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "SIGNUP_SEE_HOUSE_CHANGE_STATUS_FAILURE",
    DELETE: "SIGNUP_SEE_HOUSE_DELETE",
    DELETE_SUCCESS: "SIGNUP_SEE_HOUSE_DELETE_SUCCESS",
    DELETE_FAILURE: "SIGNUP_SEE_HOUSE_DELETE_FAILURE",
    CREATE_RESET: 'SIGNUP_SEE_HOUSE_CREATE_RESET'
};
export const getAllSignUpSeeHouseAction = createAction(SIGNUP_SEE_HOUSE.GETALL);
export const getAllSignUpSeeHouseSuccessAction = createAction(SIGNUP_SEE_HOUSE.GETALL_SUCCESS);
export const getAllSignUpSeeHouseFailureAction = createAction(SIGNUP_SEE_HOUSE.GETALL_FAILURE);
export const changeStatusSignUpSeeHouseAction = createAction(SIGNUP_SEE_HOUSE.CHANGE_STATUS);
export const changeStatusSignUpSeeHouseSuccessAction = createAction(SIGNUP_SEE_HOUSE.CHANGE_STATUS_SUCCESS);
export const changeStatusSignUpSeeHouseFailureAction = createAction(SIGNUP_SEE_HOUSE.CHANGE_STATUS_FAILURE);
export const deleteSignUpSeeHouseAction = createAction(SIGNUP_SEE_HOUSE.DELETE);
export const deleteSignUpSeeHouseSuccessAction = createAction(SIGNUP_SEE_HOUSE.DELETE_SUCCESS);
export const deleteSignUpSeeHouseFailureAction = createAction(SIGNUP_SEE_HOUSE.DELETE_FAILURE);
export const createResetSignUpSeeHouseAction = createAction(SIGNUP_SEE_HOUSE.CREATE_RESET);
