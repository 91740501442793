import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROPERTY_TYPE } from './propertyTypeAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllPropertyTypesSuccessAction,
    getAllPropertyTypesFailureAction,
    getAllPropertyTypesByPurposeIdSuccessAction,
    getAllPropertyTypesByPurposeIdFailureAction,
    getByIdPropertyTypeSuccessAction,
    getByIdPropertyTypeFailureAction,
    changeStatusPropertyTypeSuccessAction,
    changeStatusPropertyTypeFailureAction,
    deletePropertyTypeSuccessAction,
    deletePropertyTypeFailureAction,
    createPropertyTypeSuccessAction,
    createPropertyTypeFailureAction,
    updatePropertyTypeSuccessAction,
    updatePropertyTypeFailureAction,
    removePropertyTypeSuccessAction,
    removePropertyTypeFailureAction
} from './propertyTypeAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/PropertyType/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/PropertyType/GetById?id=';
const API_GETALL_BY_PURPOSE_ID = config.HOST + config.VERSION + '/PropertyType/GetAllByPurposeId/';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/PropertyType/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/PropertyType/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/PropertyType/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/PropertyType/Update';
const API_REMOVE = config.HOST + config.VERSION + '/PropertyType/Remove';

// GETALL
const getAllPropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllPropertyTypesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllPropertyTypesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdPropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdPropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getByIdPropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

// GETALL_BY_PURPOSE_ID
const getAllPropertyTypeByPurposeIdEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.GETALL_BY_PURPOSE_ID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_PURPOSE_ID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getAllPropertyTypesByPurposeIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllPropertyTypesByPurposeIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusPropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusPropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusPropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deletePropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deletePropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deletePropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createPropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createPropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createPropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updatePropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updatePropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updatePropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removePropertyTypeEpic = (action$, store) => action$.pipe(
    ofType(PROPERTY_TYPE.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removePropertyTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removePropertyTypeFailureAction(error));
                    }
                })
            );
    })
);

export { getAllPropertyTypeEpic, getByIdPropertyTypeEpic, changeStatusPropertyTypeEpic, deletePropertyTypeEpic,
    createPropertyTypeEpic, updatePropertyTypeEpic, removePropertyTypeEpic, getAllPropertyTypeByPurposeIdEpic };