import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UTILITY } from './utilityAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllUtilitiesSuccessAction,
    getAllUtilitiesFailureAction,
    getByIdUtilitySuccessAction,
    getByIdUtilityFailureAction,
    changeStatusUtilitySuccessAction,
    changeStatusUtilityFailureAction,
    deleteUtilitySuccessAction,
    deleteUtilityFailureAction,
    createUtilitySuccessAction,
    createUtilityFailureAction,
    updateUtilitySuccessAction,
    updateUtilityFailureAction,
    removeUtilitySuccessAction,
    removeUtilityFailureAction
} from './utilityAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Utility/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/Utility/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Utility/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Utility/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/Utility/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/Utility/Update';
const API_REMOVE = config.HOST + config.VERSION + '/Utility/Remove';

// GETALL
const getAllUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllUtilitiesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllUtilitiesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getByIdUtilityFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusUtilityFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteUtilityFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createUtilityFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateUtilityFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeUtilityEpic = (action$, store) => action$.pipe(
    ofType(UTILITY.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeUtilitySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removeUtilityFailureAction(error));
                    }
                })
            );
    })
);

export { getAllUtilityEpic, getByIdUtilityEpic, changeStatusUtilityEpic, deleteUtilityEpic,
    createUtilityEpic, updateUtilityEpic, removeUtilityEpic };