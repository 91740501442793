import { createAction } from "redux-actions";
export const CONTACT = {
    GETALL: "CONTACT_GETALL",
    GETALL_SUCCESS: "CONTACT_GETALL_SUCCESS",
    GETALL_FAILURE: "CONTACT_GETALL_FAILURE",
    GETBYID: "CONTACT_GETBYID",
    GETBYID_SUCCESS: "CONTACT_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CONTACT_GETBYID_FAILURE",
    CHANGE_STATUS: "CONTACT_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CONTACT_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CONTACT_CHANGE_STATUS_FAILURE",
    DELETE: "CONTACT_DELETE",
    DELETE_SUCCESS: "CONTACT_DELETE_SUCCESS",
    DELETE_FAILURE: "CONTACT_DELETE_FAILURE",
    ADD: "CONTACT_ADD",
    ADD_SUCCESS: "CONTACT_ADD_SUCCESS",
    ADD_FAILURE: "CONTACT_ADD_FAILURE",
    EDIT: "CONTACT_EDIT",
    EDIT_SUCCESS: "CONTACT_EDIT_SUCCESS",
    EDIT_FAILURE: "CONTACT_EDIT_FAILURE",
    CREATE_RESET: 'CONTACT_CREATE_RESET'
};
export const getAllContactAction = createAction(CONTACT.GETALL);
export const getAllContactSuccessAction = createAction(CONTACT.GETALL_SUCCESS);
export const getAllContactFailureAction = createAction(CONTACT.GETALL_FAILURE);
export const getByIdContactAction = createAction(CONTACT.GETBYID);
export const getByIdContactSuccessAction = createAction(CONTACT.GETBYID_SUCCESS);
export const getByIdContactFailureAction = createAction(CONTACT.GETBYID_FAILURE);
export const changeStatusContactAction = createAction(CONTACT.CHANGE_STATUS);
export const changeStatusContactSuccessAction = createAction(CONTACT.CHANGE_STATUS_SUCCESS);
export const changeStatusContactFailureAction = createAction(CONTACT.CHANGE_STATUS_FAILURE);
export const deleteContactAction = createAction(CONTACT.DELETE);
export const deleteContactSuccessAction = createAction(CONTACT.DELETE_SUCCESS);
export const deleteContactFailureAction = createAction(CONTACT.DELETE_FAILURE);
export const addContactAction = createAction(CONTACT.ADD);
export const addContactSuccessAction = createAction(CONTACT.ADD_SUCCESS);
export const addContactFailureAction = createAction(CONTACT.ADD_FAILURE);
export const editContactAction = createAction(CONTACT.EDIT);
export const editContactSuccessAction = createAction(CONTACT.EDIT_SUCCESS);
export const editContactFailureAction = createAction(CONTACT.EDIT_FAILURE);
export const createResetContactAction = createAction(CONTACT.CREATE_RESET);
