import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PURPOSE } from './purposeAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllPurposesSuccessAction,
    getAllPurposesFailureAction,
    getAllPurposesByStatusSuccessAction,
    getAllPurposesByStatusFailureAction,
    getByIdPurposeSuccessAction,
    getByIdPurposeFailureAction,
    changeStatusPurposeSuccessAction,
    changeStatusPurposeFailureAction,
    deletePurposeSuccessAction,
    deletePurposeFailureAction,
    createPurposeSuccessAction,
    createPurposeFailureAction,
    updatePurposeSuccessAction,
    updatePurposeFailureAction,
    removePurposeSuccessAction,
    removePurposeFailureAction
} from './purposeAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Purpose/GetAll';
const API_GETALL_BY_STATUS = config.HOST + config.VERSION + '/Purpose/GetAllByStatus';
const API_GETBYID = config.HOST + config.VERSION + '/Purpose/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Purpose/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Purpose/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/Purpose/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/Purpose/Update';
const API_REMOVE = config.HOST + config.VERSION + '/Purpose/Remove';

// GETALL
const getAllPurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllPurposesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllPurposesFailureAction(error));
                    }
                })
            );
    })
);

// GETALL Status
const getAllPurposeByStatusEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.GETALL_BY_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_STATUS + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllPurposesByStatusSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllPurposesByStatusFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdPurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdPurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getByIdPurposeFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusPurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusPurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusPurposeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deletePurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deletePurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deletePurposeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createPurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createPurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createPurposeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updatePurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updatePurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updatePurposeFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removePurposeEpic = (action$, store) => action$.pipe(
    ofType(PURPOSE.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removePurposeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removePurposeFailureAction(error));
                    }
                })
            );
    })
);

export { getAllPurposeEpic, getByIdPurposeEpic, changeStatusPurposeEpic, deletePurposeEpic,
    createPurposeEpic, updatePurposeEpic, removePurposeEpic, getAllPurposeByStatusEpic};