import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
    cibSkype, cibFacebook, cibTwitter, cibLinkedin, cibFlickr, cibTumblr, cibXing, cibGithub, cibStackoverflow, cibYoutube, cibDribbble, cibInstagram, cibPinterest, cibVk, cibYahoo, cibBehance, cibReddit, cibVimeo, cibCcMastercard, cibCcVisa, cibStripe, cibPaypal, cibGooglePay, cibCcAmex
} from '@coreui/icons'
import {
    cifUs, cifBr, cifIn, cifFr, cifEs, cifPl, cifVn, cilShortText
} from '@coreui/icons'
import {
    cilAlignCenter, cilAlignLeft, cilHouse, cilCog, cilBarcode, cilImage, cilLanguage, cilContact, cilCommentBubble, cilAlignRight, cilLan, cilNewspaper, cilApplicationsSettings, cilArrowRight, cilArrowTop, cilAsterisk, cilBan, cilBasket, cilBell, cilBold, cilBookmark, cilCalculator, cilCalendar, cilCloudDownload, cilCheck, cilChevronBottom, cilChevronLeft, cilChevronRight, cilChevronTop, cilCircle, cilCheckCircle, cilCode, cilCommentSquare, cilCreditCard, cilCursor, cilCursorMove, cilDrop, cilDollar, cilEnvelopeClosed, cilEnvelopeLetter, cilEnvelopeOpen, cilEuro, cilGlobeAlt, cilGrid, cilFile, cilFullscreen, cilFullscreenExit, cilGraph, cilHome, cilInbox, cilIndentDecrease, cilIndentIncrease, cilInputPower, cilItalic, cilJustifyCenter, cilJustifyLeft, cilLaptop, cilLayers, cilLightbulb, cilList, cilListNumbered, cilListRich, cilLocationPin, cilLockLocked, cilMagnifyingGlass, cilMap, cilMoon, cilNotes, cilOptions, cilPaperclip, cilPaperPlane, cilPencil, cilPeople, cilPhone, cilPrint, cilPuzzle, cilSave, cilScrubber, cilSettings, cilShare, cilShareAll, cilShareBoxed, cilShieldAlt, cilSpeech, cilSpeedometer, cilSpreadsheet, cilStar, cilSun, cilTags, cilTask, cilTrash, cilUnderline, cilUser, cilUserFemale, cilUserFollow, cilUserUnfollow, cilX, cilXCircle, cilVideo, cilAlbum, cilWarning, cilTextStrike, cilTextSquare, cilMinus, cilPlus, cilPlaylistAdd, cilImagePlus, cilShareAlt, cilBuilding, cilHandPointRight, cilChartPie, cilChart
} from '@coreui/icons'

export const icons = Object.assign({}, {
    sygnet, logo, logoNegative
}, {
    cilAlignCenter, cilAlignLeft, cilHouse, cilCog, cilBarcode, cilImage, cilLanguage, cilContact, cilCommentBubble, cilAlignRight, cilLan, cilNewspaper, cilApplicationsSettings, cilArrowRight, cilArrowTop, cilAsterisk, cilBan, cilBasket, cilBell, cilBold, cilBookmark, cilCalculator, cilCalendar, cilCloudDownload, cilCheck, cilChevronBottom, cilChevronLeft, cilChevronRight, cilChevronTop, cilCircle, cilCheckCircle, cilCode, cilCommentSquare, cilCreditCard, cilCursor, cilCursorMove, cilDrop, cilDollar, cilEnvelopeClosed, cilEnvelopeLetter, cilEnvelopeOpen, cilEuro, cilGlobeAlt, cilGrid, cilFile, cilFullscreen, cilFullscreenExit, cilGraph, cilHome, cilInbox, cilIndentDecrease, cilIndentIncrease, cilInputPower, cilItalic, cilJustifyCenter, cilJustifyLeft, cilLaptop, cilLayers, cilLightbulb, cilList, cilListNumbered, cilListRich, cilLocationPin, cilLockLocked, cilMagnifyingGlass, cilMap, cilMoon, cilNotes, cilOptions, cilPaperclip, cilPaperPlane, cilPencil, cilPeople, cilPhone, cilPrint, cilPuzzle, cilSave, cilScrubber, cilSettings, cilShare, cilShareAll, cilShareBoxed, cilShieldAlt, cilSpeech, cilSpeedometer, cilSpreadsheet, cilStar, cilSun, cilTags, cilTask, cilTrash, cilUnderline, cilUser, cilUserFemale, cilUserFollow, cilUserUnfollow, cilX, cilXCircle, cilVideo, cilAlbum, cilWarning, cilTextStrike, cilTextSquare, cilMinus, cilPlus, cilPlaylistAdd, cilImagePlus, cilShareAlt, cilBuilding, cilHandPointRight, cilChartPie, cilChart
}, {
    cifUs, cifBr, cifIn, cifFr, cifEs, cifPl, cifVn, cilShortText
}, {
    cibSkype, cibFacebook, cibTwitter, cibLinkedin, cibFlickr, cibTumblr, cibXing, cibGithub, cibStackoverflow, cibYoutube, cibDribbble, cibInstagram, cibPinterest, cibVk, cibYahoo, cibBehance, cibReddit, cibVimeo, cibCcMastercard, cibCcVisa, cibStripe, cibPaypal, cibGooglePay, cibCcAmex
})
