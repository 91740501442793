import { createAction } from "redux-actions";
export const PURPOSE = {
    GETALL: "PURPOSE_GETALL",
    GETALL_SUCCESS: "PURPOSE_GETALL_SUCCESS",
    GETALL_FAILURE: "PURPOSE_GETALL_FAILURE",
    GETALL_BY_STATUS: "PURPOSE_GETALL_BY_STATUS",
    GETALL_BY_STATUS_SUCCESS: "PURPOSE_GETALL_BY_STATUS_SUCCESS",
    GETALL_BY_STATUS_FAILURE: "PURPOSE_GETALL_BY_STATUS_FAILURE",
    GETBYID: "PURPOSE_GETBYID",
    GETBYID_SUCCESS: "PURPOSE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PURPOSE_GETBYID_FAILURE",
    CHANGE_STATUS: "PURPOSE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "PURPOSE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "PURPOSE_CHANGE_STATUS_FAILURE",
    DELETE: "PURPOSE_DELETE",
    DELETE_SUCCESS: "PURPOSE_DELETE_SUCCESS",
    DELETE_FAILURE: "PURPOSE_DELETE_FAILURE",
    CREATE: "PURPOSE_CREATE",
    CREATE_SUCCESS: "PURPOSE_CREATE_SUCCESS",
    CREATE_FAILURE: "PURPOSE_CREATE_FAILURE",
    UPDATE: "PURPOSE_UPDATE",
    UPDATE_SUCCESS: "PURPOSE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PURPOSE_UPDATE_FAILURE",
    REMOVE: "PURPOSE_REMOVE",
    REMOVE_SUCCESS: "PURPOSE_REMOVE_SUCCESS",
    REMOVE_FAILURE: "PURPOSE_REMOVE_FAILURE",
    CREATE_RESET: 'PURPOSE_CREATE_RESET',
    UPDATE_RESET: 'PURPOSE_UPDATE_RESET'
};
export const getAllPurposesAction = createAction(PURPOSE.GETALL);
export const getAllPurposesSuccessAction = createAction(PURPOSE.GETALL_SUCCESS);
export const getAllPurposesFailureAction = createAction(PURPOSE.GETALL_FAILURE);
export const getAllPurposesByStatusAction = createAction(PURPOSE.GETALL_BY_STATUS);
export const getAllPurposesByStatusSuccessAction = createAction(PURPOSE.GETALL_BY_STATUS_SUCCESS);
export const getAllPurposesByStatusFailureAction = createAction(PURPOSE.GETALL_BY_STATUS_FAILURE);
export const getByIdPurposeAction = createAction(PURPOSE.GETBYID);
export const getByIdPurposeSuccessAction = createAction(PURPOSE.GETBYID_SUCCESS);
export const getByIdPurposeFailureAction = createAction(PURPOSE.GETBYID_FAILURE);
export const changeStatusPurposeAction = createAction(PURPOSE.CHANGE_STATUS);
export const changeStatusPurposeSuccessAction = createAction(PURPOSE.CHANGE_STATUS_SUCCESS);
export const changeStatusPurposeFailureAction = createAction(PURPOSE.CHANGE_STATUS_FAILURE);
export const deletePurposeAction = createAction(PURPOSE.DELETE);
export const deletePurposeSuccessAction = createAction(PURPOSE.DELETE_SUCCESS);
export const deletePurposeFailureAction = createAction(PURPOSE.DELETE_FAILURE);
export const createPurposeAction = createAction(PURPOSE.CREATE);
export const createPurposeSuccessAction = createAction(PURPOSE.CREATE_SUCCESS);
export const createPurposeFailureAction = createAction(PURPOSE.CREATE_FAILURE);
export const updatePurposeAction = createAction(PURPOSE.UPDATE);
export const updatePurposeSuccessAction = createAction(PURPOSE.UPDATE_SUCCESS);
export const updatePurposeFailureAction = createAction(PURPOSE.UPDATE_FAILURE);
export const removePurposeAction = createAction(PURPOSE.REMOVE);
export const removePurposeSuccessAction = createAction(PURPOSE.REMOVE_SUCCESS);
export const removePurposeFailureAction = createAction(PURPOSE.REMOVE_FAILURE);
export const createResetPurposeAction = createAction(PURPOSE.CREATE_RESET);
export const updateResetPurposeAction = createAction(PURPOSE.UPDATE_RESET);