import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CONTACT } from './contactAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllContactSuccessAction,
    getAllContactFailureAction,
    getByIdContactSuccessAction,
    getByIdContactFailureAction,
    changeStatusContactSuccessAction,
    changeStatusContactFailureAction,
    deleteContactSuccessAction,
    deleteContactFailureAction,
    addContactSuccessAction,
    addContactFailureAction,
    editContactSuccessAction,
    editContactFailureAction,
} from './contactAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsContact/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/CmsContact/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsContact/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsContact/Delete/';
const API_POST = config.HOST + config.VERSION + '/CmsContact/Create';
const API_UPSERT = config.HOST + config.VERSION + '/CmsContact/Update';

const getAllContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllContactFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdContactFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusContactFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteContactFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addContactFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editContactEpic = (action$, store) => action$.pipe(
    ofType(CONTACT.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editContactSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editContactFailureAction(error));
                    }
                })
            );
    })
);


export { getAllContactEpic, getByIdContactEpic, changeStatusContactEpic, deleteContactEpic, addContactEpic, editContactEpic };