import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { IMAGETYPE } from './imageTypeAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllImageTypeSuccessAction,
    getAllImageTypeFailureAction,
    getByIdImageTypeSuccessAction,
    getByIdImageTypeFailureAction,
    changeStatusImageTypeSuccessAction,
    changeStatusImageTypeFailureAction,
    deleteImageTypeSuccessAction,
    deleteImageTypeFailureAction,
    addImageTypeSuccessAction,
    addImageTypeFailureAction,
    editImageTypeSuccessAction,
    editImageTypeFailureAction,
} from './imageTypeAction';
import config from 'common/config';
const API_GETALL = config.HOST + config.VERSION + '/ImageType/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/ImageType/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/ImageType/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/ImageType/Delete/';
const API_POST = config.HOST + config.VERSION + '/ImageType/Create';
const API_UPSERT = config.HOST + config.VERSION + '/ImageType/Update';

const getAllImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllImageTypeFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdImageTypeFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusImageTypeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteImageTypeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addImageTypeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editImageTypeEpic = (action$, store) => action$.pipe(
    ofType(IMAGETYPE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editImageTypeFailureAction(error));
                    }
                })
            );
    })
);


export { getAllImageTypeEpic, getByIdImageTypeEpic, changeStatusImageTypeEpic, deleteImageTypeEpic, addImageTypeEpic, editImageTypeEpic };