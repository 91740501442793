import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROPERTY } from './propertyAction';
//import { getNewToken } from '../../services/getNewToken';
import {
  getAllPropertiesSuccessAction,
  getAllPropertiesFailureAction,
  getByIdPropertySuccessAction,
  getByIdPropertyFailureAction,
  changeStatusPropertySuccessAction,
  changeStatusPropertyFailureAction,
  changeOutStandingPropertySuccessAction,
  changeOutStandingPropertyFailureAction,
  deletePropertySuccessAction,
  deletePropertyFailureAction,
  deletePropertyMultipleSuccessAction,
  deletePropertyMultipleFailureAction,
  createPropertySuccessAction,
  createPropertyFailureAction,
  updatePropertySuccessAction,
  updatePropertyFailureAction,
  removePropertySuccessAction,
  removePropertyFailureAction,
  loadPropertyTypeSuccessAction,
  loadPropertyTypeFailureAction,
  loadDistrictSuccessAction,
  loadDistrictFailureAction,
  loadWardSuccessAction,
  loadWardFailureAction,
  deleteImagePropertySuccessAction,
  deleteImagePropertyFailureAction,
} from './propertyAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Property/GetAllByFilter';
const API_GETBYID = config.HOST + config.VERSION + '/Property/GetById?id=';
const API_CHANGE_STATUS =
  config.HOST + config.VERSION + '/Property/ChangeStatus/';
const API_CHANGE_OUTSTANDING =
  config.HOST + config.VERSION + '/Property/ChangeIsOutstanding/';
const API_DELETE = config.HOST + config.VERSION + '/Property/Delete/';
const API_DELETE_MULTIPLE =
  config.HOST + config.VERSION + '/Property/DeleteMultiple';
const API_CREATE = config.HOST + config.VERSION + '/Property/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/Property/Update';
const API_REMOVE = config.HOST + config.VERSION + '/Property/Remove';
const API_DELETE_BLUESPRINT_IMAGE =
  config.HOST +
  config.VERSION +
  '/PropertyBlueprintImage/DeleteImage?imagePath=';
const API_DELETE_HOUSE_IMAGE =
  config.HOST + config.VERSION + '/PropertyHouseImage/DeleteImage?imagePath=';
const API_GETPROPERTYTYPE =
  config.HOST + config.VERSION + '/Property/GetPropertyTypeById?id=';
const API_GETDISTRICT =
  config.HOST + config.VERSION + '/Property/GetDistrictById?id=';
const API_GETWARD = config.HOST + config.VERSION + '/Property/GetWardById?id=';

// GETALL
const getAllPropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.GETALL),
    mergeMap((action) => {
      return defer(() => {
        return ajax.getJSON(API_GETALL + action.payload, config.HEADERS());
      }).pipe(
        map((response) => getAllPropertiesSuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(getAllPropertiesFailureAction(error));
          }
        })
      );
    })
  );

// GETBYID
const getByIdPropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.GETBYID),
    mergeMap((action) => {
      return defer(() => {
        return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
      }).pipe(
        map((response) => getByIdPropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(getByIdPropertyFailureAction(error));
          }
        })
      );
    })
  );

// CHANGE STATUS
const changeStatusPropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.CHANGE_STATUS),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(
          API_CHANGE_STATUS + action.payload,
          null,
          config.HEADERS()
        );
      }).pipe(
        map((response) => changeStatusPropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(changeStatusPropertyFailureAction(error));
          }
        })
      );
    })
  );

// CHANGE OUT STANDING
const changeOutStandingPropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.CHANGE_OUTSTANDING),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(
          API_CHANGE_OUTSTANDING,
          action.payload,
          config.HEADERS()
        );
      }).pipe(
        map((response) => changeOutStandingPropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(changeOutStandingPropertyFailureAction(error));
          }
        })
      );
    })
  );

// DELETE
const deletePropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.DELETE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
      }).pipe(
        map((response) => deletePropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(deletePropertyFailureAction(error));
          }
        })
      );
    })
  );

// DELETE
const deleteMultiplePropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.DELETE_MULTIPLE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(API_DELETE_MULTIPLE, action.payload, config.HEADERS());
      }).pipe(
        map((response) => deletePropertyMultipleSuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(deletePropertyMultipleFailureAction(error));
          }
        })
      );
    })
  );

// ADD
const createPropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.CREATE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.post(API_CREATE, action.payload, config.HEADERS());
      }).pipe(
        map((response) => createPropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(createPropertyFailureAction(error));
          }
        })
      );
    })
  );

// EDIT
const updatePropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.UPDATE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(API_UPDATE, action.payload, config.HEADERS());
      }).pipe(
        map((response) => updatePropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(updatePropertyFailureAction(error));
          }
        })
      );
    })
  );

// REMOVE
const removePropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.REMOVE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
      }).pipe(
        map((response) => removePropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(removePropertyFailureAction(error));
          }
        })
      );
    })
  );

// DELETE IMAGE
const deleteImagePropertyEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.DELETE_IMAGE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.put(
          (action.payload.type === 'House'
            ? API_DELETE_HOUSE_IMAGE
            : API_DELETE_BLUESPRINT_IMAGE) +
            action.payload.path +
            '&id=' +
            action.payload.id,
          null,
          config.HEADERS()
        );
      }).pipe(
        map((response) => deleteImagePropertySuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(deleteImagePropertyFailureAction(error));
          }
        })
      );
    })
  );

const loadPropertyTypeEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.LOAD_PROPERTYTYPE),
    mergeMap((action) => {
      return defer(() => {
        return ajax.getJSON(
          API_GETPROPERTYTYPE + action.payload,
          config.HEADERS()
        );
      }).pipe(
        map((response) => loadPropertyTypeSuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(loadPropertyTypeFailureAction(error));
          }
        })
      );
    })
  );

const loadDistrictEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.LOAD_DISTRICT),
    mergeMap((action) => {
      return defer(() => {
        return ajax.getJSON(API_GETDISTRICT + action.payload, config.HEADERS());
      }).pipe(
        map((response) => loadDistrictSuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(loadDistrictFailureAction(error));
          }
        })
      );
    })
  );

const loadWardEpic = (action$, store) =>
  action$.pipe(
    ofType(PROPERTY.LOAD_WARD),
    mergeMap((action) => {
      return defer(() => {
        return ajax.getJSON(API_GETWARD + action.payload, config.HEADERS());
      }).pipe(
        map((response) => loadWardSuccessAction(response)),
        catchError((error, source) => {
          if (error.status === 401) {
            //return getNewToken(action$, source);
          } else {
            return of(loadWardFailureAction(error));
          }
        })
      );
    })
  );

export {
  getAllPropertyEpic,
  getByIdPropertyEpic,
  changeStatusPropertyEpic,
  changeOutStandingPropertyEpic,
  deletePropertyEpic,
  deleteMultiplePropertyEpic,
  createPropertyEpic,
  updatePropertyEpic,
  removePropertyEpic,
  deleteImagePropertyEpic,
  loadPropertyTypeEpic,
  loadDistrictEpic,
  loadWardEpic,
};
