import { createAction } from "redux-actions";
export const CMSARTICLE = {
    GETALL: "CMSARTICLE_GETALL",
    GETALL_SUCCESS: "CMSARTICLE_GETALL_SUCCESS",
    GETALL_FAILURE: "CMSARTICLE_GETALL_FAILURE",
    GETALL_BY_CONTENT_ID: "CMSARTICLE_GETALL_BY_CONTENT_ID",
    GETALL_BY_CONTENT_ID_SUCCESS: "CMSARTICLE_GETALL_BY_CONTENT_ID_SUCCESS",
    GETALL_BY_CONTENT_ID_FAILURE: "CMSARTICLE_GETALL_BY_CONTENT_ID_FAILURE",
    GETALL_BY_CATEGORY_ID: "CMSARTICLE_GETALL_BY_CATEGORY_ID",
    GETALL_BY_CATEGORY_ID_SUCCESS: "CMSARTICLE_GETALL_BY_CATEGORY_ID_SUCCESS",
    GETALL_BY_CATEGORY_ID_FAILURE: "CMSARTICLE_GETALL_BY_CATEGORY_ID_FAILURE",
    GETBYID: "CMSARTICLE_GETBYID",
    GETBYID_SUCCESS: "CMSARTICLE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CMSARTICLE_GETBYID_FAILURE",
    CHANGE_STATUS: "CMSARTICLE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CMSARTICLE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CMSARTICLE_CHANGE_STATUS_FAILURE",
    DELETE: "CMSARTICLE_DELETE",
    DELETE_SUCCESS: "CMSARTICLE_DELETE_SUCCESS",
    DELETE_FAILURE: "CMSARTICLE_DELETE_FAILURE",
    CREATE: "CMSARTICLE_CREATE",
    CREATE_SUCCESS: "CMSARTICLE_CREATE_SUCCESS",
    CREATE_FAILURE: "CMSARTICLE_CREATE_FAILURE",
    UPDATE: "CMSARTICLE_UPDATE",
    UPDATE_SUCCESS: "CMSARTICLE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "CMSARTICLE_UPDATE_FAILURE",
    UPDATE_SPECIAL_FIELD: "CMSARTICLE_UPDATE_SPECIAL_FIELD",
    UPDATE_SPECIAL_FIELD_SUCCESS: "CMSARTICLE_UPDATE_SPECIAL_FIELD_SUCCESS",
    UPDATE_SPECIAL_FIELD_FAILURE: "CMSARTICLE_UPDATE_SPECIAL_FIELD_FAILURE",
    REMOVE: "CMSARTICLE_REMOVE",
    REMOVE_SUCCESS: "CMSARTICLE_REMOVE_SUCCESS",
    REMOVE_FAILURE: "CMSARTICLE_REMOVE_FAILURE",
    CREATE_RESET: 'CMSARTICLE_CREATE_RESET',
    DELETE_IMAGE: 'CMSARTICLE_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'CMSARTICLE_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'CMSARTICLE_DELETE_IMAGE_FAILURE',
    CKEDITOR: 'CKEDITOR',
    CKEDITOR_NEW: 'CKEDITOR_NEW',
};
export const getAllCmsArticlesAction = createAction(CMSARTICLE.GETALL);
export const getAllCmsArticlesSuccessAction = createAction(CMSARTICLE.GETALL_SUCCESS);
export const getAllCmsArticlesFailureAction = createAction(CMSARTICLE.GETALL_FAILURE);
export const getAllCmsArticlesByContentIdAction = createAction(CMSARTICLE.GETALL_BY_CONTENT_ID);
export const getAllCmsArticlesByContentIdSuccessAction = createAction(CMSARTICLE.GETALL_BY_CONTENT_ID_SUCCESS);
export const getAllCmsArticlesByContentIdFailureAction = createAction(CMSARTICLE.GETALL_BY_CONTENT_ID_FAILURE);
export const getAllCmsArticlesByCategoryIdAction = createAction(CMSARTICLE.GETALL_BY_CATEGORY_ID);
export const getAllCmsArticlesByCategoryIdSuccessAction = createAction(CMSARTICLE.GETALL_BY_CATEGORY_ID_SUCCESS);
export const getAllCmsArticlesByCategoryIdFailureAction = createAction(CMSARTICLE.GETALL_BY_CATEGORY_ID_FAILURE);
export const getByIdCmsArticleAction = createAction(CMSARTICLE.GETBYID);
export const getByIdCmsArticleSuccessAction = createAction(CMSARTICLE.GETBYID_SUCCESS);
export const getByIdCmsArticleFailureAction = createAction(CMSARTICLE.GETBYID_FAILURE);
export const changeStatusCmsArticleAction = createAction(CMSARTICLE.CHANGE_STATUS);
export const changeStatusCmsArticleSuccessAction = createAction(CMSARTICLE.CHANGE_STATUS_SUCCESS);
export const changeStatusCmsArticleFailureAction = createAction(CMSARTICLE.CHANGE_STATUS_FAILURE);
export const deleteCmsArticleAction = createAction(CMSARTICLE.DELETE);
export const deleteCmsArticleSuccessAction = createAction(CMSARTICLE.DELETE_SUCCESS);
export const deleteCmsArticleFailureAction = createAction(CMSARTICLE.DELETE_FAILURE);
export const createCmsArticleAction = createAction(CMSARTICLE.CREATE);
export const createCmsArticleSuccessAction = createAction(CMSARTICLE.CREATE_SUCCESS);
export const createCmsArticleFailureAction = createAction(CMSARTICLE.CREATE_FAILURE);
export const updateCmsArticleAction = createAction(CMSARTICLE.UPDATE);
export const updateCmsArticleSuccessAction = createAction(CMSARTICLE.UPDATE_SUCCESS);
export const updateCmsArticleFailureAction = createAction(CMSARTICLE.UPDATE_FAILURE);
export const updateSpecialFieldCmsArticleAction = createAction(CMSARTICLE.UPDATE_SPECIAL_FIELD);
export const updateSpecialFieldCmsArticleSuccessAction = createAction(CMSARTICLE.UPDATE_SPECIAL_FIELD_SUCCESS);
export const updateSpecialFieldCmsArticleFailureAction = createAction(CMSARTICLE.UPDATE_SPECIAL_FIELD_FAILURE);
export const removeCmsArticleAction = createAction(CMSARTICLE.REMOVE);
export const removeCmsArticleSuccessAction = createAction(CMSARTICLE.REMOVE_SUCCESS);
export const removeCmsArticleFailureAction = createAction(CMSARTICLE.REMOVE_FAILURE);
export const createResetCmsArticleAction = createAction(CMSARTICLE.CREATE_RESET);
export const deleteImageCmsArticleAction = createAction(CMSARTICLE.DELETE_IMAGE);
export const deleteImageCmsArticleSuccessAction = createAction(CMSARTICLE.DELETE_IMAGE_SUCCESS);
export const deleteImageCmsArticleFailureAction = createAction(CMSARTICLE.DELETE_IMAGE_FAILURE);
export const ckEditorCmsArticleAction = createAction(CMSARTICLE.CKEDITOR);
export const ckEditorNewCmsArticleAction = createAction(CMSARTICLE.CKEDITOR_NEW);
