import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ROLE } from './roleAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllRolesSuccessAction,
    getAllRolesFailureAction,
    getByIdRoleSuccessAction,
    getByIdRoleFailureAction,
    changeStatusRoleSuccessAction,
    changeStatusRoleFailureAction,
    deleteRoleSuccessAction,
    deleteRoleFailureAction,
    addRoleSuccessAction,
    addRoleFailureAction,
    editRoleSuccessAction,
    editRoleFailureAction
} from './roleAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Role/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/Role/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Role/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Role/Delete/';
const API_ADD = config.HOST + config.VERSION + '/Role/Create';
const API_EDIT = config.HOST + config.VERSION + '/Role/Update';

// GETALL
const getAllRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllRolesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllRolesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdRoleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdRoleFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusRoleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusRoleFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteRoleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteRoleFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addRoleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(addRoleFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editRoleEpic = (action$, store) => action$.pipe(
    ofType(ROLE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editRoleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editRoleFailureAction(error));
                    }
                })
            );
    })
);

export { getAllRoleEpic, changeStatusRoleEpic, deleteRoleEpic, addRoleEpic, editRoleEpic, getByIdRoleEpic };