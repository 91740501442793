import { createAction } from "redux-actions";
export const PROVINCIAL = {
    GETALL: "PROVINCIAL_GETALL",
    GETALL_SUCCESS: "PROVINCIAL_GETALL_SUCCESS",
    GETALL_FAILURE: "PROVINCIAL_GETALL_FAILURE",
    GETBYID: "PROVINCIAL_GETBYID",
    GETBYID_SUCCESS: "PROVINCIAL_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PROVINCIAL_GETBYID_FAILURE",
    CHANGE_STATUS: "PROVINCIAL_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "PROVINCIAL_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "PROVINCIAL_CHANGE_STATUS_FAILURE",
    DELETE: "PROVINCIAL_DELETE",
    DELETE_SUCCESS: "PROVINCIAL_DELETE_SUCCESS",
    DELETE_FAILURE: "PROVINCIAL_DELETE_FAILURE",
    ADD: "PROVINCIAL_ADD",
    ADD_SUCCESS: "PROVINCIAL_ADD_SUCCESS",
    ADD_FAILURE: "PROVINCIAL_ADD_FAILURE",
    EDIT: "PROVINCIAL_EDIT",
    EDIT_SUCCESS: "PROVINCIAL_EDIT_SUCCESS",
    EDIT_FAILURE: "PROVINCIAL_EDIT_FAILURE",
    CREATE_RESET: 'PROVINCIAL_CREATE_RESET',
};
export const getAllProvincialAction = createAction(PROVINCIAL.GETALL);
export const getAllProvincialSuccessAction = createAction(PROVINCIAL.GETALL_SUCCESS);
export const getAllProvincialFailureAction = createAction(PROVINCIAL.GETALL_FAILURE);
export const getByIdProvincialAction = createAction(PROVINCIAL.GETBYID);
export const getByIdProvincialSuccessAction = createAction(PROVINCIAL.GETBYID_SUCCESS);
export const getByIdProvincialFailureAction = createAction(PROVINCIAL.GETBYID_FAILURE);
export const changeStatusProvincialAction = createAction(PROVINCIAL.CHANGE_STATUS);
export const changeStatusProvincialSuccessAction = createAction(PROVINCIAL.CHANGE_STATUS_SUCCESS);
export const changeStatusProvincialFailureAction = createAction(PROVINCIAL.CHANGE_STATUS_FAILURE);
export const deleteProvincialAction = createAction(PROVINCIAL.DELETE);
export const deleteProvincialSuccessAction = createAction(PROVINCIAL.DELETE_SUCCESS);
export const deleteProvincialFailureAction = createAction(PROVINCIAL.DELETE_FAILURE);
export const addProvincialAction = createAction(PROVINCIAL.ADD);
export const addProvincialSuccessAction = createAction(PROVINCIAL.ADD_SUCCESS);
export const addProvincialFailureAction = createAction(PROVINCIAL.ADD_FAILURE);
export const editProvincialAction = createAction(PROVINCIAL.EDIT);
export const editProvincialSuccessAction = createAction(PROVINCIAL.EDIT_SUCCESS);
export const editProvincialFailureAction = createAction(PROVINCIAL.EDIT_FAILURE);
export const createResetProvincialAction = createAction(PROVINCIAL.CREATE_RESET);