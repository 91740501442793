import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROGRESS } from './progressAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllProgressesSuccessAction,
    getAllProgressesFailureAction,
    getByIdProgressSuccessAction,
    getByIdProgressFailureAction,
    changeStatusProgressSuccessAction,
    changeStatusProgressFailureAction,
    deleteProgressSuccessAction,
    deleteProgressFailureAction,
    createProgressSuccessAction,
    createProgressFailureAction,
    updateProgressSuccessAction,
    updateProgressFailureAction,
    removeProgressSuccessAction,
    removeProgressFailureAction
} from './progressAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/ProjectProgress/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/ProjectProgress/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/ProjectProgress/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/ProjectProgress/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/ProjectProgress/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/ProjectProgress/Update';
const API_REMOVE = config.HOST + config.VERSION + '/ProjectProgress/Remove';

// GETALL
const getAllProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllProgressesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllProgressesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getByIdProgressFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusProgressFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteProgressFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createProgressFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateProgressFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeProgressEpic = (action$, store) => action$.pipe(
    ofType(PROGRESS.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeProgressSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removeProgressFailureAction(error));
                    }
                })
            );
    })
);

export { getAllProgressEpic, getByIdProgressEpic, changeStatusProgressEpic, deleteProgressEpic,
    createProgressEpic, updateProgressEpic, removeProgressEpic };