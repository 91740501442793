import { createAction } from "redux-actions";
export const PROGRESS = {
    GETALL: "PROGRESS_GETALL",
    GETALL_SUCCESS: "PROGRESS_GETALL_SUCCESS",
    GETALL_FAILURE: "PROGRESS_GETALL_FAILURE",
    GETBYID: "PROGRESS_GETBYID",
    GETBYID_SUCCESS: "PROGRESS_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PROGRESS_GETBYID_FAILURE",
    CHANGE_STATUS: "PROGRESS_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "PROGRESS_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "PROGRESS_CHANGE_STATUS_FAILURE",
    DELETE: "PROGRESS_DELETE",
    DELETE_SUCCESS: "PROGRESS_DELETE_SUCCESS",
    DELETE_FAILURE: "PROGRESS_DELETE_FAILURE",
    CREATE: "PROGRESS_CREATE",
    CREATE_SUCCESS: "PROGRESS_CREATE_SUCCESS",
    CREATE_FAILURE: "PROGRESS_CREATE_FAILURE",
    UPDATE: "PROGRESS_UPDATE",
    UPDATE_SUCCESS: "PROGRESS_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PROGRESS_UPDATE_FAILURE",
    REMOVE: "PROGRESS_REMOVE",
    REMOVE_SUCCESS: "PROGRESS_REMOVE_SUCCESS",
    REMOVE_FAILURE: "PROGRESS_REMOVE_FAILURE",
    CREATE_RESET: 'PROGRESS_CREATE_RESET',
    UPDATE_RESET: 'PROGRESS_UPDATE_RESET'
};
export const getAllProgressesAction = createAction(PROGRESS.GETALL);
export const getAllProgressesSuccessAction = createAction(PROGRESS.GETALL_SUCCESS);
export const getAllProgressesFailureAction = createAction(PROGRESS.GETALL_FAILURE);
export const getByIdProgressAction = createAction(PROGRESS.GETBYID);
export const getByIdProgressSuccessAction = createAction(PROGRESS.GETBYID_SUCCESS);
export const getByIdProgressFailureAction = createAction(PROGRESS.GETBYID_FAILURE);
export const changeStatusProgressAction = createAction(PROGRESS.CHANGE_STATUS);
export const changeStatusProgressSuccessAction = createAction(PROGRESS.CHANGE_STATUS_SUCCESS);
export const changeStatusProgressFailureAction = createAction(PROGRESS.CHANGE_STATUS_FAILURE);
export const deleteProgressAction = createAction(PROGRESS.DELETE);
export const deleteProgressSuccessAction = createAction(PROGRESS.DELETE_SUCCESS);
export const deleteProgressFailureAction = createAction(PROGRESS.DELETE_FAILURE);
export const createProgressAction = createAction(PROGRESS.CREATE);
export const createProgressSuccessAction = createAction(PROGRESS.CREATE_SUCCESS);
export const createProgressFailureAction = createAction(PROGRESS.CREATE_FAILURE);
export const updateProgressAction = createAction(PROGRESS.UPDATE);
export const updateProgressSuccessAction = createAction(PROGRESS.UPDATE_SUCCESS);
export const updateProgressFailureAction = createAction(PROGRESS.UPDATE_FAILURE);
export const removeProgressAction = createAction(PROGRESS.REMOVE);
export const removeProgressSuccessAction = createAction(PROGRESS.REMOVE_SUCCESS);
export const removeProgressFailureAction = createAction(PROGRESS.REMOVE_FAILURE);
export const createResetProgressAction = createAction(PROGRESS.CREATE_RESET);
export const updateResetProgressAction = createAction(PROGRESS.UPDATE_RESET);