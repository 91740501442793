import { handleActions } from 'redux-actions';
import { PROPERTY } from '../propertyAction';
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [PROPERTY.CHANGE_OUTSTANDING]: () => ({
    isLoading: true,
  }),
  [PROPERTY.CHANGE_OUTSTANDING_SUCCESS]: (
    state,
    { payload: responseData }
  ) => ({
    ...state,
    isLoading: false,
    responseData: responseData.response ? responseData.response : responseData,
  }),
  [PROPERTY.CHANGE_OUTSTANDING_FAILURE]: (
    state,
    { payload: responseData }
  ) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  }),
};
export default handleActions(actions, initialState);
