import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CMSARTICLE } from './cmsArticleAction';
import { getNewToken } from '../../../services/getNewToken';
import {
    getAllCmsArticlesSuccessAction,
    getAllCmsArticlesFailureAction,
    getAllCmsArticlesByContentIdSuccessAction,
    getAllCmsArticlesByContentIdFailureAction,
    getAllCmsArticlesByCategoryIdSuccessAction,
    getAllCmsArticlesByCategoryIdFailureAction,
    getByIdCmsArticleSuccessAction,
    getByIdCmsArticleFailureAction,
    changeStatusCmsArticleSuccessAction,
    changeStatusCmsArticleFailureAction,
    deleteCmsArticleSuccessAction,
    deleteCmsArticleFailureAction,
    createCmsArticleSuccessAction,
    createCmsArticleFailureAction,
    updateCmsArticleSuccessAction,
    updateCmsArticleFailureAction,
    updateSpecialFieldCmsArticleSuccessAction,
    updateSpecialFieldCmsArticleFailureAction,
    removeCmsArticleSuccessAction,
    removeCmsArticleFailureAction,
    deleteImageCmsArticleSuccessAction,
    deleteImageCmsArticleFailureAction
} from './cmsArticleAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsArticle/GetAllArticlesByQueryStringForAdmin';
const API_GETALL_BY_CONTENT_ID = config.HOST + config.VERSION + '/CmsArticle/GetAllArticlesByContentId';
const API_GETALL_BY_CATEGORY_ID = config.HOST + config.VERSION + '/CmsArticle/GetAllByCategoryId';
const API_GETBYID = config.HOST + config.VERSION + '/CmsArticle/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsArticle/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsArticle/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/CmsArticle/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/CmsArticle/Update';
const API_REMOVE = config.HOST + config.VERSION + '/CmsArticle/Remove';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/CmsArticleAttachment/DeleteImage?imagePath=';
const API_UPDATE_SPECIAL_FIELD = config.HOST + config.VERSION + '/CmsArticle/UpdateSpecialField/';

// GETALL
const getAllCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsArticlesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsArticlesFailureAction(error));
                    }
                })
            );
    })
);

// GETALL BY CONTENT ID
const getAllCmsArticleByContentIdEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.GETALL_BY_CONTENT_ID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_CONTENT_ID + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsArticlesByContentIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsArticlesByContentIdFailureAction(error));
                    }
                })
            );
    })
);

// GETALL BY CONTENT ID
const getAllCmsArticleByCategoryIdEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.GETALL_BY_CATEGORY_ID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_CATEGORY_ID + (action.payload ? action.payload : ''), config.HEADERS());
        }).pipe(
            map((response) => getAllCmsArticlesByCategoryIdSuccessAction(response)),
            catchError((error, source) => {
                if (error.status === 401) {
                    return getNewToken(action$, source);
                } else {
                    return of(getAllCmsArticlesByCategoryIdFailureAction(error));
                }
            })
        );
    })
);

// GETBYID
const getByIdCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusCmsArticleFailureAction(error));
                    }
                })
            );
    })
);
// UPDATE SPECIAL FIELD
const updateSpecialFieldCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.UPDATE_SPECIAL_FIELD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE_SPECIAL_FIELD + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => updateSpecialFieldCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateSpecialFieldCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.CREATE),
    mergeMap((action) => {
        console.log(action.payload)
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(createCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(removeCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

// DELETE IMAGE
const deleteImageCmsArticleEpic = (action$, store) => action$.pipe(
    ofType(CMSARTICLE.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteImageCmsArticleSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteImageCmsArticleFailureAction(error));
                    }
                })
            );
    })
);

export {
    getAllCmsArticleEpic, getByIdCmsArticleEpic,
    changeStatusCmsArticleEpic, deleteCmsArticleEpic,
    createCmsArticleEpic, updateCmsArticleEpic,
    removeCmsArticleEpic, deleteImageCmsArticleEpic,
    getAllCmsArticleByContentIdEpic,
    getAllCmsArticleByCategoryIdEpic,
    updateSpecialFieldCmsArticleEpic,
};