import { of } from 'rxjs';
import { takeUntil, take, mergeMapTo, merge } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { TOKEN, tokenAction } from '../features/token/tokenAction';
import { getSessionStorage } from 'common/utilities/storage';
import commonConstant from 'common/constant';

export const getNewToken = (action$, source) => {
    return action$.pipe(
        ofType(TOKEN.TOKEN_SUCCESS),
        takeUntil(action$.ofType(TOKEN.TOKEN_FAILURE)),
        take(1),
        mergeMapTo(source),
        merge(
          of(tokenAction({ id: getSessionStorage(commonConstant.AUTH_ID), token: getSessionStorage(commonConstant.REFRESH_TOKEN) }))
        ),
      );
}