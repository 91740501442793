import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UPLOAD, uploadImageBase64SuccessAction, uploadImageBase64FailureAction, deleteImageBase64SuccessAction, deleteImageBase64FailureAction } from './uploadImageCropAction';
import config from 'common/config';


const API_UPLOAD = config.HOST + config.VERSION + '/File/UploadImageBase64'
const API_DELETE = config.HOST + config.VERSION + '/File/Remove/'

const uploadImageBase64Epic = action$ => action$.pipe(
    ofType(UPLOAD.UPLOAD_IMAGE_BASE64),
    mergeMap((action) => {
        return ajax.post(API_UPLOAD, action.payload, config.HEADERS()).pipe(
            map(res => uploadImageBase64SuccessAction(res?.response)),
            catchError(error => of(uploadImageBase64FailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

const deleteImageBase64Epic = action$ => action$.pipe(
    ofType(UPLOAD.DELETE_IMAGE_BASE64),
    mergeMap((action) => {
        return ajax.delete(API_DELETE + action.payload, config.HEADERS()).pipe(
            map(res => deleteImageBase64SuccessAction(res?.response)),
            catchError(error => of(deleteImageBase64FailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

export { uploadImageBase64Epic, deleteImageBase64Epic };