import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UPLOAD_CROP_MULTIPLE } from './uploadCropMultipleAction';
import { chunkCropMultipleUploadSuccessAction, chunkCropMultipleUploadFailureAction, createCropMultipleUploadSuccessAction, createCropMultipleUploadFailureAction } from './uploadCropMultipleAction';
import config from 'common/config';


const API_CHUNK = config.HOST + config.VERSION + '/File/FileChunks';
const API_CREATE = config.HOST + config.VERSION + '/File/Create';


// CHUNK UPLOAD_CROP_MULTIPLE
const chunkCropMultipleUploadEpic = action$ => action$.pipe(
    ofType(UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE),
    mergeMap((action) => {
        console.log('chunkCropMultipleUploadEpic action', action)
        const formData = new FormData();
        formData.append('fileName', action.payload.fileName);
        formData.append('file', action.payload.chunk);
        //HEADERS: Content-Type: application/octet-stream
        return ajax.post(API_CHUNK, formData, config.HEADERS(true)).pipe(
            map(response => chunkCropMultipleUploadSuccessAction(response)),
            catchError(error => of(chunkCropMultipleUploadFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

// CREATE UPLOAD_CROP_MULTIPLE
const createCropMultipleUploadEpic = action$ => action$.pipe(
    ofType(UPLOAD_CROP_MULTIPLE.CREATE_CROP_MULTIPLE),
    mergeMap((action) => {
        console.log('createCropMultipleUploadEpic action', action)
        return ajax.post(API_CREATE, action.payload, config.HEADERS()).pipe(
            map(response => createCropMultipleUploadSuccessAction(response)),
            catchError(error => of(createCropMultipleUploadFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);


export { chunkCropMultipleUploadEpic, createCropMultipleUploadEpic };