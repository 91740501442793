import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LOGOUT } from './logoutAction';
import {
    logoutSuccessAction,
    logoutFailureAction,
} from './logoutAction';
import config from 'common/config';

const API_LOGOUT = config.HOST + config.VERSION + '/Auth/Logout';

const logoutEpic = action$ => action$.pipe(
    ofType(LOGOUT.LOGOUT),
    mergeMap((action) => {
        return ajax.post(API_LOGOUT, action.payload, config.HEADERS()).pipe(
            map(response => logoutSuccessAction(response)),
            catchError(error => of(logoutFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

export { logoutEpic };