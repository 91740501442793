import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import sidebarShowReducer from 'containers/sidebarShowReducer';

//LOGIN
import loginReducer from 'features/login/reducers/loginReducer';
import currentProfileReducer from 'features/login/reducers/currentProfileReducer';

//DASHBOARD
import dashboardReducer from 'features/dashboard/reducers/dashboardReducer';

//LOGOUT
import logoutReducer from 'features/logout/reducers/logoutReducer';

//FORGOT PASSWORD
import forgotpasswordReducer from 'features/forgotpassword/reducers/forgotpasswordReducer';

//RESET PASSWORD
import resetpasswordReducer from 'features/resetpassword/reducers/resetpasswordReducer';

//PROFILE
import editProfileReducer from 'features/profile/reducers/editReducer';
import getByIdProfileReducer from 'features/profile/reducers/getByIdReducer';

//TOKEN
import tokenReducer from 'features/token/reducers/tokenReducer';

//ROLES
import getAllRolesReducer from 'features/role/reducers/getAllReducer';
import getByIdRolesReducer from 'features/role/reducers/getByIdReducer';
import changeStatusRoleReducer from 'features/role/reducers/changeStatusReducer';
import deleteRoleReducer from 'features/role/reducers/deleteReducer';
import addRoleReducer from 'features/role/reducers/addReducer';
import editRoleReducer from 'features/role/reducers/editReducer';

//System API
import getSystemApiReducer from 'features/systemApi/reducers/getAllReducer';
import getSystemApiByIdReducer from 'features/systemApi/reducers/getByIdReducer';
import changeSystemApiByStatusReducer from 'features/systemApi/reducers/changeStatusReducer';
import deleteSystemApiReducer from 'features/systemApi/reducers/deleteReducer';
import createSystemApiReducer from 'features/systemApi/reducers/addReducer';
import updateSystemApiReducer from 'features/systemApi/reducers/editReducer';

//USERS
import getAllUsersReducer from 'features/user/reducers/getAllReducer';
import getByIdUserReducer from 'features/user/reducers/getByIdReducer';
import changeStatusUserReducer from 'features/user/reducers/changeStatusReducer';
import deleteUserReducer from 'features/user/reducers/deleteReducer';
import addUserReducer from 'features/user/reducers/addReducer';
import editUserReducer from 'features/user/reducers/editReducer';
import checkValidUserNameReducer from 'features/user/reducers/checkValidUserNameReducer';
import checkValidEmailReducer from 'features/user/reducers/checkValidEmailReducer';
import updateRoleForUserReducer from 'features/user/reducers/updateRoleForUserReducer';

//CMS ARTICLE
import getAllCmsArticlesReducer from 'features/cms/article/reducers/getAllReducer';
import getByIdCmsArticleReducer from 'features/cms/article/reducers/getByIdReducer';
import changeStatusCmsArticleReducer from 'features/cms/article/reducers/changeStatusReducer';
import deleteCmsArticleReducer from 'features/cms/article/reducers/deleteReducer';
import createCmsArticleReducer from 'features/cms/article/reducers/addReducer';
import updateCmsArticleReducer from 'features/cms/article/reducers/editReducer';
import removeCmsArticleReducer from 'features/cms/article/reducers/removeReducer';
import getAllCmsArticlesByContentIdReducer from 'features/cms/article/reducers/getAllByContentIdReducer';
import getAllCmsArticlesByCategoryIdReducer from 'features/cms/article/reducers/getAllByCategoryIdReducer';
import updateSpecialFieldCmsArticleReducer from 'features/cms/article/reducers/updateSpecialFieldReducer';

//CMS CATEGORY
import getAllCmsCategoriesReducer from 'features/cms/category/reducers/getAllReducer';
import getByIdCmsCategoryReducer from 'features/cms/category/reducers/getByIdReducer';
import changeStatusCmsCategoryReducer from 'features/cms/category/reducers/changeStatusReducer';
import deleteCmsCategoryReducer from 'features/cms/category/reducers/deleteReducer';
import createCmsCategoryReducer from 'features/cms/category/reducers/addReducer';
import updateCmsCategoryReducer from 'features/cms/category/reducers/editReducer';
import removeCmsCategoryReducer from 'features/cms/category/reducers/removeReducer';
import getAllCmsCategoriesContentReducer from 'features/cms/category/reducers/getAllCmsCategoryContent';
import getAllCmsCategoriesByContentIdReducer from 'features/cms/category/reducers/getAllByContentIdReducer';

//CMS CAROUSEL
import getAllCmsCarouselsReducer from 'features/cms/carousel/reducers/getAllReducer';
import getByIdCmsCarouselReducer from 'features/cms/carousel/reducers/getByIdReducer';
import changeStatusCmsCarouselReducer from 'features/cms/carousel/reducers/changeStatusReducer';
import deleteCmsCarouselReducer from 'features/cms/carousel/reducers/deleteReducer';
import createCmsCarouselReducer from 'features/cms/carousel/reducers/addReducer';
import updateCmsCarouselReducer from 'features/cms/carousel/reducers/editReducer';
import removeCmsCarouselReducer from 'features/cms/carousel/reducers/removeReducer';

//CMS CONTENT
import getAllCmsContentsReducer from 'features/cms/content/reducers/getAllReducer';
import getAllCmsContentsByStatusReducer from 'features/cms/content/reducers/getAllByStatusReducer';
import getByIdCmsContentReducer from 'features/cms/content/reducers/getByIdReducer';
import changeStatusCmsContentReducer from 'features/cms/content/reducers/changeStatusReducer';
import deleteCmsContentReducer from 'features/cms/content/reducers/deleteReducer';
import createCmsContentReducer from 'features/cms/content/reducers/addReducer';
import updateCmsContentReducer from 'features/cms/content/reducers/editReducer';
import removeCmsContentReducer from 'features/cms/content/reducers/removeReducer';

//UPLOAD
import chunkReducer from 'common/components/upload/reducers/chunkReducer';
import createReducer from 'common/components/upload/reducers/createReducer';
import resetReducer from 'common/components/upload/reducers/resetReducer';

//UPLOAD MULTIPLE
import chunkMultipleReducer from 'common/components/uploadMultiple/reducers/chunkMultipleReducer';
//import thumbnailMultipleReducer from "common/components/uploadMultiple/reducers/thumbnailMultipleReducer";
import createMultipleReducer from 'common/components/uploadMultiple/reducers/createMultipleReducer';
import resetMultipleReducer from 'common/components/uploadMultiple/reducers/resetMultipleReducer';

//UPLOAD THUMNAIL MULTIPLE
import chunkThumbnailMultipleReducer from 'common/components/uploadThumbnailMultiple/reducers/chunkThumbnailMultipleReducer';
import thumbnailMultipleReducer from 'common/components/uploadThumbnailMultiple/reducers/thumbnailMultipleReducer';
import createThumbnailMultipleReducer from 'common/components/uploadThumbnailMultiple/reducers/createThumbnailMultipleReducer';
import resetThumbnailMultipleReducer from 'common/components/uploadThumbnailMultiple/reducers/resetThumbnailMultipleReducer';

//UPLOAD IMAGE CROP
import uploadImageCropReducer from 'common/components/uploadImageCrop/reducers/uploadImageCropReducer';
import deleteImageCropReducer from 'common/components/uploadImageCrop/reducers/deleteImageCropReducer';

//UPLOAD CROP MULTIPLE
import chunkCropMultipleReducer from 'common/components/uploadCropMultiple/reducers/chunkCropMultipleReducer';
import createCropMultipleReducer from 'common/components/uploadCropMultiple/reducers/createCropMultipleReducer';
import resetCropMultipleReducer from 'common/components/uploadCropMultiple/reducers/resetCropMultipleReducer';

//UPLOAD VIDEO
import chunkVideoReducer from 'common/components/uploadVideo/reducers/chunkVideoReducer';
import createVideoReducer from 'common/components/uploadVideo/reducers/createVideoReducer';
import resetVideoReducer from 'common/components/uploadVideo/reducers/resetVideoReducer';

//FILE
import getAllFilesReducer from 'features/file/reducers/getAllReducer';
import deleteFileReducer from 'features/file/reducers/deleteReducer';

//PROPERT
import getAllPropertiesReducer from 'features/property/reducers/getAllReducer';
import getByIdPropertyReducer from 'features/property/reducers/getByIdReducer';
import changeStatusPropertyReducer from 'features/property/reducers/changeStatusReducer';
import changeOutStandingPropertyReducer from 'features/property/reducers/changeOutStandingReducer';
import deletePropertyReducer from 'features/property/reducers/deleteReducer';
import deletePropertyMultipleReducer from 'features/property/reducers/deleteMultipleReducer';
import createPropertyReducer from 'features/property/reducers/addReducer';
import updatePropertyReducer from 'features/property/reducers/editReducer';
import loadPropertyTypeReducer from 'features/property/reducers/loadPropertyTypeReducer';
import loadDistrictReducer from 'features/property/reducers/loadDistrictReducer';
import loadWardReducer from 'features/property/reducers/loadWardReducer';

//PROPERTY TYPE
import getAllPropertyTypesReducer from 'features/propertytype/reducers/getAllReducer';
import getByIdPropertyTypeReducer from 'features/propertytype/reducers/getByIdReducer';
import changeStatusPropertyTypeReducer from 'features/propertytype/reducers/changeStatusReducer';
import deletePropertyTypeReducer from 'features/propertytype/reducers/deleteReducer';
import removePropertyTypeReducer from 'features/propertytype/reducers/removeReducer';
import createPropertyTypeReducer from 'features/propertytype/reducers/addReducer';
import updatePropertyTypeReducer from 'features/propertytype/reducers/editReducer';
import getAllPropertyTypesByPurposeIdReducer from 'features/propertytype/reducers/getAllByPurposeIdReducer';

//PROJECT
import getAllProjectsReducer from 'features/project/reducers/getAllReducer';
import getByIdProjectReducer from 'features/project/reducers/getByIdReducer';
import changeStatusProjectReducer from 'features/project/reducers/changeStatusReducer';
import deleteProjectReducer from 'features/project/reducers/deleteReducer';
import removeProjectReducer from 'features/project/reducers/removeReducer';
import createProjectReducer from 'features/project/reducers/addReducer';
import updateProjectReducer from 'features/project/reducers/editReducer';
import loadWardProjectReducer from 'features/project/reducers/loadWardReducer';
import loadProvinceProjectReducer from 'features/project/reducers/loadProvinceReducer';
import deleteProjectImageTypeReducer from 'features/project/reducers/deleteProjectImageTypeReducer';
import updateFieldProjectReducer from 'features/project/reducers/updateFieldReducer';

//OFFICE
import getAllOfficesReducer from 'features/office/reducers/getAllReducer';
import getByIdOfficeReducer from 'features/office/reducers/getByIdReducer';
import changeStatusOfficeReducer from 'features/office/reducers/changeStatusReducer';
import deleteOfficeReducer from 'features/office/reducers/deleteReducer';
import removeOfficeReducer from 'features/office/reducers/removeReducer';
import createOfficeReducer from 'features/office/reducers/addReducer';
import updateOfficeReducer from 'features/office/reducers/editReducer';
import loadWardOfficeReducer from 'features/office/reducers/loadWardReducer';
import loadProvinceOfficeReducer from 'features/office/reducers/loadProvinceReducer';
import deleteOfficeImageTypeReducer from 'features/office/reducers/deleteOfficeImageTypeReducer';
import updateFieldOfficeReducer from 'features/office/reducers/updateFieldReducer';

//PURPOSE
import getAllPurposesReducer from 'features/purpose/reducers/getAllReducer';
import getAllPurposesByStatusReducer from 'features/purpose/reducers/getAllByStatusReducer';
import getByIdPurposeReducer from 'features/purpose/reducers/getByIdReducer';
import changeStatusPurposeReducer from 'features/purpose/reducers/changeStatusReducer';
import deletePurposeReducer from 'features/purpose/reducers/deleteReducer';
import removePurposeReducer from 'features/purpose/reducers/removeReducer';
import createPurposeReducer from 'features/purpose/reducers/addReducer';
import updatePurposeReducer from 'features/purpose/reducers/editReducer';

//UTILITY
import getAllUtilitiesReducer from 'features/utility/reducers/getAllReducer';
import getByIdUtilityReducer from 'features/utility/reducers/getByIdReducer';
import changeStatusUtilityReducer from 'features/utility/reducers/changeStatusReducer';
import deleteUtilityReducer from 'features/utility/reducers/deleteReducer';
import removeUtilityReducer from 'features/utility/reducers/removeReducer';
import createUtilityReducer from 'features/utility/reducers/addReducer';
import updateUtilityReducer from 'features/utility/reducers/editReducer';

//PROGRESS
import getAllProgressesReducer from 'features/progress/reducers/getAllReducer';
import getByIdProgressReducer from 'features/progress/reducers/getByIdReducer';
import changeStatusProgressReducer from 'features/progress/reducers/changeStatusReducer';
import deleteProgressReducer from 'features/progress/reducers/deleteReducer';
import removeProgressReducer from 'features/progress/reducers/removeReducer';
import createProgressReducer from 'features/progress/reducers/addReducer';
import updateProgressReducer from 'features/progress/reducers/editReducer';

//GLOBAL_CONFIG
import getConfigsReducer from 'features/globalConfig/reducers/getAllReducer';
import getConfigByIdReducer from 'features/globalConfig/reducers/getByIdReducer';
import changeConfigByStatusReducer from 'features/globalConfig/reducers/changeStatusReducer';
import deleteConfigReducer from 'features/globalConfig/reducers/deleteReducer';
import createConfigReducer from 'features/globalConfig/reducers/addReducer';
import updateConfigReducer from 'features/globalConfig/reducers/editReducer';

//Config Type
import getConfigTypesReducer from 'features/configType/reducers/getAllReducer';
import getConfigTypeByIdReducer from 'features/configType/reducers/getByIdReducer';
import changeConfigTypeByStatusReducer from 'features/configType/reducers/changeStatusReducer';
import deleteConfigTypeReducer from 'features/configType/reducers/deleteReducer';
import createConfigTypeReducer from 'features/configType/reducers/addReducer';
import updateConfigTypeReducer from 'features/configType/reducers/editReducer';

//Language
import getLanguagesReducer from 'features/languageConfig/reducers/getAllReducer';
import getLanguageByIdReducer from 'features/languageConfig/reducers/getByIdReducer';
import changeLanguageByStatusReducer from 'features/languageConfig/reducers/changeStatusReducer';
import deleteLanguageReducer from 'features/languageConfig/reducers/deleteReducer';
import createLanguageReducer from 'features/languageConfig/reducers/addReducer';
import updateLanguageReducer from 'features/languageConfig/reducers/editReducer';

//Menu
import getMenusReducer from 'features/menu/reducers/getAllReducer';
import getMenuByIdReducer from 'features/menu/reducers/getByIdReducer';
import changeMenuByStatusReducer from 'features/menu/reducers/changeStatusReducer';
import deleteMenuReducer from 'features/menu/reducers/deleteReducer';
import createMenuReducer from 'features/menu/reducers/addReducer';
import updateMenuReducer from 'features/menu/reducers/editReducer';

//Provincial
import getAllProvincialReducer from 'features/provincial/reducers/getAllReducer';
import addProvincialReducer from 'features/provincial/reducers/addReducer';
import editProvincialReducer from 'features/provincial/reducers/editReducer';
import getByIdProvincialReducer from 'features/provincial/reducers/getByIdReducer';
import changeStatusProvincialReducer from 'features/provincial/reducers/changeStatusReducer';
import deleteProvincialReducer from 'features/provincial/reducers/deleteReducer';
// import updateProvincialReducer from 'features/provincial/reducers/editReducer';

//District
import getAllDistrictReducer from 'features/district/reducers/getAllReducer';
import getAllFullDistrictReducer from 'features/district/reducers/getAllFullReducer';
import addDistrictReducer from 'features/district/reducers/addReducer';
import editDistrictReducer from 'features/district/reducers/editReducer';
import getByIdDistrictReducer from 'features/district/reducers/getByIdReducer';
import changeStatusDistrictReducer from 'features/district/reducers/changeStatusReducer';
import deleteDistrictReducer from 'features/district/reducers/deleteReducer';
import getByProvinceCodeReducer from 'features/district/reducers/getByProvinceCodeReducer';
// import updateProvincialReducer from 'features/provincial/reducers/editReducer';

//Ward
import getAllWardReducer from 'features/ward/reducers/getAllReducer';
import getAllFullWardReducer from 'features/ward/reducers/getAllFullReducer';
import addWardReducer from 'features/ward/reducers/addReducer';
import editWardReducer from 'features/ward/reducers/editReducer';
import getByIdWardReducer from 'features/ward/reducers/getByIdReducer';
import getByDistrictCodeWardReducer from 'features/ward/reducers/getByDistrictCodeReducer';
import changeStatusWardReducer from 'features/ward/reducers/changeStatusReducer';
import deleteWardReducer from 'features/ward/reducers/deleteReducer';
import getByDistrictCodeReducer from 'features/ward/reducers/getByDistrictCodeReducer';

//Feedback
import getAllFeedbackReducer from 'features/feedback/reducers/getAllReducer';
import addFeedbackReducer from 'features/feedback/reducers/addReducer';
import editFeedbackReducer from 'features/feedback/reducers/editReducer';
import getByIdFeedbackReducer from 'features/feedback/reducers/getByIdReducer';
import changeStatusFeedbackReducer from 'features/feedback/reducers/changeStatusReducer';
import deleteFeedbackReducer from 'features/feedback/reducers/deleteReducer';

//Access
import getAllAccessReducer from 'features/access/reducers/getAllReducer';
import addAccessReducer from 'features/access/reducers/addReducer';
import editAccessReducer from 'features/access/reducers/editReducer';
import getByIdAccessReducer from 'features/access/reducers/getByIdReducer';
import changeStatusAccessReducer from 'features/access/reducers/changeStatusReducer';
import deleteAccessReducer from 'features/access/reducers/deleteReducer';
import getByPageNumberReducer from 'features/access/reducers/getByPageNumberReducer';

//Contact
import getAllContactReducer from 'features/contact/reducers/getAllReducer';
import addContactReducer from 'features/contact/reducers/addReducer';
import editContactReducer from 'features/contact/reducers/editReducer';
import getByIdContactReducer from 'features/contact/reducers/getByIdReducer';
import changeStatusContactReducer from 'features/contact/reducers/changeStatusReducer';
import deleteContactReducer from 'features/contact/reducers/deleteReducer';

//Currency
import getAllCurrencyReducer from 'features/currency/reducers/getAllReducer';
import addCurrencyReducer from 'features/currency/reducers/addReducer';
import editCurrencyReducer from 'features/currency/reducers/editReducer';
import getByIdCurrencyReducer from 'features/currency/reducers/getByIdReducer';
import changeStatusCurrencyReducer from 'features/currency/reducers/changeStatusReducer';
import deleteCurrencyReducer from 'features/currency/reducers/deleteReducer';

//BuildingClass
import getAllBuildingClassReducer from 'features/buildingClass/reducers/getAllReducer';
import addBuildingClassReducer from 'features/buildingClass/reducers/addReducer';
import editBuildingClassReducer from 'features/buildingClass/reducers/editReducer';
import getByIdBuildingClassReducer from 'features/buildingClass/reducers/getByIdReducer';
import changeStatusBuildingClassReducer from 'features/buildingClass/reducers/changeStatusReducer';
import deleteBuildingClassReducer from 'features/buildingClass/reducers/deleteReducer';

//developmentType
import getAllDevelopmentTypeReducer from 'features/developmentType/reducers/getAllReducer';
import addDevelopmentTypeReducer from 'features/developmentType/reducers/addReducer';
import editDevelopmentTypeReducer from 'features/developmentType/reducers/editReducer';
import getByIdDevelopmentTypeReducer from 'features/developmentType/reducers/getByIdReducer';
import changeStatusDevelopmentTypeReducer from 'features/developmentType/reducers/changeStatusReducer';
import deleteDevelopmentTypeReducer from 'features/developmentType/reducers/deleteReducer';

//direction
import getAllDirectionReducer from 'features/direction/reducers/getAllReducer';
import addDirectionReducer from 'features/direction/reducers/addReducer';
import editDirectionReducer from 'features/direction/reducers/editReducer';
import getByIdDirectionReducer from 'features/direction/reducers/getByIdReducer';
import changeStatusDirectionReducer from 'features/direction/reducers/changeStatusReducer';
import deleteDirectionReducer from 'features/direction/reducers/deleteReducer';

//objectType
import getAllObjectTypeReducer from 'features/objectType/reducers/getAllReducer';
import addObjectTypeReducer from 'features/objectType/reducers/addReducer';
import editObjectTypeReducer from 'features/objectType/reducers/editReducer';
import getByIdObjectTypeReducer from 'features/objectType/reducers/getByIdReducer';
import changeStatusObjectTypeReducer from 'features/objectType/reducers/changeStatusReducer';
import deleteObjectTypeReducer from 'features/objectType/reducers/deleteReducer';

//Building
import getAllBuildingReducer from 'features/building/reducers/getAllReducer';
import addBuildingReducer from 'features/building/reducers/addReducer';
import editBuildingReducer from 'features/building/reducers/editReducer';
import getByIdBuildingReducer from 'features/building/reducers/getByIdReducer';
import changeStatusBuildingReducer from 'features/building/reducers/changeStatusReducer';
import deleteBuildingReducer from 'features/building/reducers/deleteReducer';

//FeedbackByUser
import getAllFeedbackByUserReducer from 'features/feedbackByUser/reducers/getAllReducer';
import addFeedbackByUserReducer from 'features/feedbackByUser/reducers/addReducer';
import editFeedbackByUserReducer from 'features/feedbackByUser/reducers/editReducer';
import getByIdFeedbackByUserReducer from 'features/feedbackByUser/reducers/getByIdReducer';
import changeStatusFeedbackByUserReducer from 'features/feedbackByUser/reducers/changeStatusReducer';
import deleteFeedbackByUserReducer from 'features/feedbackByUser/reducers/deleteReducer';

//ImageType
import getAllImageTypeReducer from 'features/imageType/reducers/getAllReducer';
import addImageTypeReducer from 'features/imageType/reducers/addReducer';
import editImageTypeReducer from 'features/imageType/reducers/editReducer';
import getByIdImageTypeReducer from 'features/imageType/reducers/getByIdReducer';
import changeStatusImageTypeReducer from 'features/imageType/reducers/changeStatusReducer';
import deleteImageTypeReducer from 'features/imageType/reducers/deleteReducer';

//TransLation
import transLationReducer from '../common/components/transLation/redusers/transLationReducer';

//TransLation
import chartReducer from '../features/access/Components/chart/reducers/chartReducer';

//RecycleBin
import getAllRecycleBinReducer from '../features/recyclebin/reducers/getAllReducer';
import deleteAllRecycleBinReducer from '../features/recyclebin/reducers/deleteReducer';
import restoreRecordRecycleBinReducer from "../features/recyclebin/reducers/restoreReducer";

//SignupSeeHouse
import getAllSignUpSeeHouseReducer from 'features/signupseehouse/reducers/getAllReducer';
import changeStatusSignUpSeeHouseReducer from 'features/signupseehouse/reducers/changeStatusReducer';
import deleteSignUpSeeHouseReducer from 'features/signupseehouse/reducers/deleteReducer';

export default combineReducers({
  router: routerReducer,
  toastr: toastrReducer,
  sidebarShowReducer,

  //DASHBOARD
  dashboardReducer,

  //LOGIN
  loginReducer,
  currentProfileReducer,

  //LOGOUT
  logoutReducer,

  //FORGOT PASSWORD
  forgotpasswordReducer,

  //RESET PASSWORD
  resetpasswordReducer,

  //TOKEN
  tokenReducer,

  //PROFILE
  editProfileReducer,
  getByIdProfileReducer,

  // ROLE
  getAllRolesReducer,
  getByIdRolesReducer,
  changeStatusRoleReducer,
  deleteRoleReducer,
  addRoleReducer,
  editRoleReducer,

  //System API
  getSystemApiReducer,
  getSystemApiByIdReducer,
  changeSystemApiByStatusReducer,
  deleteSystemApiReducer,
  createSystemApiReducer,
  updateSystemApiReducer,

  // USER
  getAllUsersReducer,
  getByIdUserReducer,
  changeStatusUserReducer,
  deleteUserReducer,
  addUserReducer,
  editUserReducer,
  checkValidUserNameReducer,
  checkValidEmailReducer,
  updateRoleForUserReducer,

  // CMS ARTICLE
  getAllCmsArticlesReducer,
  getByIdCmsArticleReducer,
  changeStatusCmsArticleReducer,
  deleteCmsArticleReducer,
  createCmsArticleReducer,
  updateCmsArticleReducer,
  removeCmsArticleReducer,
  getAllCmsArticlesByContentIdReducer,
  getAllCmsArticlesByCategoryIdReducer,
  updateSpecialFieldCmsArticleReducer,

  // CMS CATEGORY
  getAllCmsCategoriesReducer,
  getByIdCmsCategoryReducer,
  changeStatusCmsCategoryReducer,
  deleteCmsCategoryReducer,
  createCmsCategoryReducer,
  updateCmsCategoryReducer,
  removeCmsCategoryReducer,
  getAllCmsCategoriesContentReducer,
  getAllCmsCategoriesByContentIdReducer,

  // CMS CATEGORY
  getAllCmsCarouselsReducer,
  getByIdCmsCarouselReducer,
  changeStatusCmsCarouselReducer,
  deleteCmsCarouselReducer,
  createCmsCarouselReducer,
  updateCmsCarouselReducer,
  removeCmsCarouselReducer,

  // CMS CONTENT
  getAllCmsContentsReducer,
  getAllCmsContentsByStatusReducer,
  getByIdCmsContentReducer,
  changeStatusCmsContentReducer,
  deleteCmsContentReducer,
  createCmsContentReducer,
  updateCmsContentReducer,
  removeCmsContentReducer,

  //UPLOAD
  chunkReducer,
  createReducer,
  resetReducer,

  //UPLOAD MULTIPLE
  chunkMultipleReducer,
  //thumbnailMultipleReducer,
  createMultipleReducer,
  resetMultipleReducer,

  //UPLOAD THUMBNAIL MULTIPLE
  chunkThumbnailMultipleReducer,
  thumbnailMultipleReducer,
  createThumbnailMultipleReducer,
  resetThumbnailMultipleReducer,

  //UPLOAD IMAGE CROP
  uploadImageCropReducer,
  deleteImageCropReducer,

  //UPLOAD CROP MULTIPLE
  chunkCropMultipleReducer,
  createCropMultipleReducer,
  resetCropMultipleReducer,

  //UPLOAD VIDEO
  chunkVideoReducer,
  createVideoReducer,
  resetVideoReducer,

  //FILE
  getAllFilesReducer,
  deleteFileReducer,

  //PROJECT
  getAllProjectsReducer,
  getByIdProjectReducer,
  changeStatusProjectReducer,
  deleteProjectReducer,
  removeProjectReducer,
  createProjectReducer,
  updateProjectReducer,
  loadWardProjectReducer,
  loadProvinceProjectReducer,
  deleteProjectImageTypeReducer,
  updateFieldProjectReducer,

  //OFFICE
  getAllOfficesReducer,
  getByIdOfficeReducer,
  changeStatusOfficeReducer,
  deleteOfficeReducer,
  removeOfficeReducer,
  createOfficeReducer,
  updateOfficeReducer,
  loadWardOfficeReducer,
  loadProvinceOfficeReducer,
  deleteOfficeImageTypeReducer,
  updateFieldOfficeReducer,

  //PROPERTY
  getAllPropertiesReducer,
  getByIdPropertyReducer,
  changeStatusPropertyReducer,
  changeOutStandingPropertyReducer,
  deletePropertyReducer,
  deletePropertyMultipleReducer,
  createPropertyReducer,
  updatePropertyReducer,
  loadPropertyTypeReducer,
  loadDistrictReducer,
  loadWardReducer,

  //PROPERTY TYPE
  getAllPropertyTypesReducer,
  getByIdPropertyTypeReducer,
  changeStatusPropertyTypeReducer,
  deletePropertyTypeReducer,
  removePropertyTypeReducer,
  createPropertyTypeReducer,
  updatePropertyTypeReducer,
  getAllPropertyTypesByPurposeIdReducer,

  //PURPOSE
  getAllPurposesReducer,
  getAllPurposesByStatusReducer,
  getByIdPurposeReducer,
  changeStatusPurposeReducer,
  deletePurposeReducer,
  removePurposeReducer,
  createPurposeReducer,
  updatePurposeReducer,

  //UTILITY
  getAllUtilitiesReducer,
  getByIdUtilityReducer,
  changeStatusUtilityReducer,
  deleteUtilityReducer,
  removeUtilityReducer,
  createUtilityReducer,
  updateUtilityReducer,

  //PROGRESS
  getAllProgressesReducer,
  getByIdProgressReducer,
  changeStatusProgressReducer,
  deleteProgressReducer,
  removeProgressReducer,
  createProgressReducer,
  updateProgressReducer,

  //Global_Config
  getConfigsReducer,
  getConfigByIdReducer,
  changeConfigByStatusReducer,
  deleteConfigReducer,
  createConfigReducer,
  updateConfigReducer,

  //Config Type
  getConfigTypesReducer,
  getConfigTypeByIdReducer,
  changeConfigTypeByStatusReducer,
  deleteConfigTypeReducer,
  createConfigTypeReducer,
  updateConfigTypeReducer,

  //Language
  getLanguagesReducer,
  getLanguageByIdReducer,
  changeLanguageByStatusReducer,
  deleteLanguageReducer,
  createLanguageReducer,
  updateLanguageReducer,

  //Config Type
  getMenusReducer,
  getMenuByIdReducer,
  changeMenuByStatusReducer,
  deleteMenuReducer,
  createMenuReducer,
  updateMenuReducer,

  //Provincial
  getAllProvincialReducer,
  addProvincialReducer,
  editProvincialReducer,
  getByIdProvincialReducer,
  changeStatusProvincialReducer,
  deleteProvincialReducer,
  getByProvinceCodeReducer,
  // createProvincialReducer,
  // updateProvincialReducer,

  //District
  getAllDistrictReducer,
  getAllFullDistrictReducer,
  addDistrictReducer,
  editDistrictReducer,
  getByIdDistrictReducer,
  changeStatusDistrictReducer,
  deleteDistrictReducer,
  // createProvincialReducer,
  // updateProvincialReducer,

  //Ward
  getAllWardReducer,
  getAllFullWardReducer,
  addWardReducer,
  editWardReducer,
  getByIdWardReducer,
  deleteWardReducer,
  changeStatusWardReducer,
  getByDistrictCodeReducer,
  getByDistrictCodeWardReducer,

  //Feedback
  getAllFeedbackReducer,
  addFeedbackReducer,
  editFeedbackReducer,
  changeStatusFeedbackReducer,
  getByIdFeedbackReducer,
  deleteFeedbackReducer,

  //Contact
  getAllContactReducer,
  addContactReducer,
  editContactReducer,
  changeStatusContactReducer,
  getByIdContactReducer,
  deleteContactReducer,

  //Currency
  getAllCurrencyReducer,
  addCurrencyReducer,
  editCurrencyReducer,
  changeStatusCurrencyReducer,
  getByIdCurrencyReducer,
  deleteCurrencyReducer,

  //Currency
  getAllBuildingClassReducer,
  addBuildingClassReducer,
  editBuildingClassReducer,
  changeStatusBuildingClassReducer,
  getByIdBuildingClassReducer,
  deleteBuildingClassReducer,

  //DevelopmentType
  getAllDevelopmentTypeReducer,
  addDevelopmentTypeReducer,
  editDevelopmentTypeReducer,
  changeStatusDevelopmentTypeReducer,
  getByIdDevelopmentTypeReducer,
  deleteDevelopmentTypeReducer,

  //Direction
  getAllDirectionReducer,
  addDirectionReducer,
  editDirectionReducer,
  changeStatusDirectionReducer,
  getByIdDirectionReducer,
  deleteDirectionReducer,

  //ObjectType
  getAllObjectTypeReducer,
  addObjectTypeReducer,
  editObjectTypeReducer,
  changeStatusObjectTypeReducer,
  getByIdObjectTypeReducer,
  deleteObjectTypeReducer,

  //Building
  getAllBuildingReducer,
  addBuildingReducer,
  editBuildingReducer,
  changeStatusBuildingReducer,
  getByIdBuildingReducer,
  deleteBuildingReducer,

  //FeedbackByUser
  getAllFeedbackByUserReducer,
  addFeedbackByUserReducer,
  editFeedbackByUserReducer,
  changeStatusFeedbackByUserReducer,
  getByIdFeedbackByUserReducer,
  deleteFeedbackByUserReducer,

  //ImageType
  getAllImageTypeReducer,
  addImageTypeReducer,
  editImageTypeReducer,
  changeStatusImageTypeReducer,
  getByIdImageTypeReducer,
  deleteImageTypeReducer,

  //Access
  getAllAccessReducer,
  addAccessReducer,
  editAccessReducer,
  changeStatusAccessReducer,
  getByIdAccessReducer,
  deleteAccessReducer,
  getByPageNumberReducer,

  //tranLation
  transLationReducer,

  //chart
  chartReducer,

  //recyclebin
  getAllRecycleBinReducer,
  deleteAllRecycleBinReducer,
  restoreRecordRecycleBinReducer,

  //Access
  getAllSignUpSeeHouseReducer,
  changeStatusSignUpSeeHouseReducer,
  deleteSignUpSeeHouseReducer,
});
