import { createAction } from "redux-actions";
export const GLOBAL_CONFIG = {
    GETALL: "GLOBAL_CONFIG_GETALL",
    GETALL_SUCCESS: "GLOBAL_CONFIG_GETALL_SUCCESS",
    GETALL_FAILURE: "GLOBAL_CONFIG_GETALL_FAILURE",
    GETBYID: "GLOBAL_CONFIG_GETBYID",
    GETBYID_SUCCESS: "GLOBAL_CONFIG_GETBYID_SUCCESS",
    GETBYID_FAILURE: "GLOBAL_CONFIG_GETBYID_FAILURE",
    CHANGE_STATUS: "GLOBAL_CONFIG_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "GLOBAL_CONFIG_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "GLOBAL_CONFIG_CHANGE_STATUS_FAILURE",
    DELETE: "GLOBAL_CONFIG_DELETE",
    DELETE_SUCCESS: "GLOBAL_CONFIG_DELETE_SUCCESS",
    DELETE_FAILURE: "GLOBAL_CONFIG_DELETE_FAILURE",
    ADD: "GLOBAL_CONFIG_ADD",
    ADD_SUCCESS: "GLOBAL_CONFIG_ADD_SUCCESS",
    ADD_FAILURE: "GLOBAL_CONFIG_ADD_FAILURE",
    EDIT: "GLOBAL_CONFIG_EDIT",
    EDIT_SUCCESS: "GLOBAL_CONFIG_EDIT_SUCCESS",
    EDIT_FAILURE: "GLOBAL_CONFIG_EDIT_FAILURE",
    CREATE_RESET: 'GLOBAL_CONFIG_CREATE_RESET'
};
export const getConfigsAction = createAction(GLOBAL_CONFIG.GETALL);
export const getConfigsSuccessAction = createAction(GLOBAL_CONFIG.GETALL_SUCCESS);
export const getConfigsFailureAction = createAction(GLOBAL_CONFIG.GETALL_FAILURE);
export const getConfigByIdAction = createAction(GLOBAL_CONFIG.GETBYID);
export const getConfigByIdSuccessAction = createAction(GLOBAL_CONFIG.GETBYID_SUCCESS);
export const getConfigByIdFailureAction = createAction(GLOBAL_CONFIG.GETBYID_FAILURE);
export const changeStatusConfigAction = createAction(GLOBAL_CONFIG.CHANGE_STATUS);
export const changeStatusConfigSuccessAction = createAction(GLOBAL_CONFIG.CHANGE_STATUS_SUCCESS);
export const changeStatusConfigFailureAction = createAction(GLOBAL_CONFIG.CHANGE_STATUS_FAILURE);
export const deleteConfigAction = createAction(GLOBAL_CONFIG.DELETE);
export const deleteConfigSuccessAction = createAction(GLOBAL_CONFIG.DELETE_SUCCESS);
export const deleteConfigFailureAction = createAction(GLOBAL_CONFIG.DELETE_FAILURE);
export const createConfigAction = createAction(GLOBAL_CONFIG.ADD);
export const createConfigSuccessAction = createAction(GLOBAL_CONFIG.ADD_SUCCESS);
export const createConfigFailureAction = createAction(GLOBAL_CONFIG.ADD_FAILURE);
export const updateConfigAction = createAction(GLOBAL_CONFIG.EDIT);
export const updateConfigSuccessAction = createAction(GLOBAL_CONFIG.EDIT_SUCCESS);
export const updateConfigFailureAction = createAction(GLOBAL_CONFIG.EDIT_FAILURE);
export const createResetAction = createAction(GLOBAL_CONFIG.CREATE_RESET);