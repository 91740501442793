import { createAction } from "redux-actions";
export const WARD = {
    GETALL: "WARD_GETALL",
    GETALL_SUCCESS: "WARD_GETALL_SUCCESS",
    GETALL_FAILURE: "WARD_GETALL_FAILURE",
    
    GETALL_FULL: "WARD_GETALL_FULL",
    GETALL_FULL_SUCCESS: "WARD_GETALL_FULL_SUCCESS",
    GETALL_FULL_FAILURE: "WARD_GETALL_FULL_FAILURE",

    GETBYID: "WARD_GETBYID",
    GETBYID_SUCCESS: "WARD_GETBYID_SUCCESS",
    GETBYID_FAILURE: "WARD_GETBYID_FAILURE",
    GETBY_DISTRICT_CODE: "WARD_GETBY_DISTRICT_CODE",
    GETBY_DISTRICT_CODE_SUCCESS: "WARD_GETBY_DISTRICT_CODE_SUCCESS",
    GETBY_DISTRICT_CODE_FAILURE: "WARD_GETBY_DISTRICT_CODE_FAILURE",
    CHANGE_STATUS: "WARD_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "WARD_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "WARD_CHANGE_STATUS_FAILURE",
    DELETE: "WARD_DELETE",
    DELETE_SUCCESS: "WARD_DELETE_SUCCESS",
    DELETE_FAILURE: "WARD_DELETE_FAILURE",
    ADD: "WARD_ADD",
    ADD_SUCCESS: "WARD_ADD_SUCCESS",
    ADD_FAILURE: "WARD_ADD_FAILURE",
    EDIT: "WARD_EDIT",
    EDIT_SUCCESS: "WARD_EDIT_SUCCESS",
    EDIT_FAILURE: "WARD_EDIT_FAILURE",
    CREATE_RESET: 'WARD_CREATE_RESET',
    GETBY_DICTRICT:'DISTRICT_GETBY_DICTRICT_CODE',
    GETBY_DICTRICT_SUCCESS:'DISTRICT_GETBY_DICTRICT_SUCCESS',
    GETBY_DICTRICT_FAILURE:'DISTRICT_GETBY_DICTRICT_FAILURE'
};
export const getAllWardAction = createAction(WARD.GETALL);
export const getAllWardSuccessAction = createAction(WARD.GETALL_SUCCESS);
export const getAllWardFailureAction = createAction(WARD.GETALL_FAILURE);

export const getAllFullWardAction = createAction(WARD.GETALL_FULL);
export const getAllFullWardSuccessAction = createAction(WARD.GETALL_FULL_SUCCESS);
export const getAllFullWardFailureAction = createAction(WARD.GETALL_FULL_FAILURE);

export const getByIdWardAction = createAction(WARD.GETBYID);
export const getByIdWardSuccessAction = createAction(WARD.GETBYID_SUCCESS);
export const getByIdWardFailureAction = createAction(WARD.GETBYID_FAILURE);

export const getByDistrictCodeWardAction = createAction(WARD.GETBY_DISTRICT_CODE);
export const getByDistrictCodeWardSuccessAction = createAction(WARD.GETBY_DISTRICT_CODE_SUCCESS);
export const getByDistrictCodeWardFailureAction = createAction(WARD.GETBY_DISTRICT_CODE_FAILURE);

export const changeStatusWardAction = createAction(WARD.CHANGE_STATUS);
export const changeStatusWardSuccessAction = createAction(WARD.CHANGE_STATUS_SUCCESS);
export const changeStatusWardFailureAction = createAction(WARD.CHANGE_STATUS_FAILURE);
export const deleteWardAction = createAction(WARD.DELETE);
export const deleteWardSuccessAction = createAction(WARD.DELETE_SUCCESS);
export const deleteWardFailureAction = createAction(WARD.DELETE_FAILURE);
export const addWardAction = createAction(WARD.ADD);
export const addWardSuccessAction = createAction(WARD.ADD_SUCCESS);
export const addWardFailureAction = createAction(WARD.ADD_FAILURE);
export const editWardAction = createAction(WARD.EDIT);
export const editWardSuccessAction = createAction(WARD.EDIT_SUCCESS);
export const editWardFailureAction = createAction(WARD.EDIT_FAILURE);
export const createResetWardAction = createAction(WARD.CREATE_RESET);
export const getByDistrictCodeAction = createAction(WARD.GETBY_DICTRICT)
export const getByDistrictCodeSuccessAction = createAction(WARD.GETBY_DICTRICT_SUCCESS)
export const getByDistrictCodeFailureAction = createAction(WARD.GETBY_DICTRICT_FAILURE)