import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { OFFICE } from './officeAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllOfficesSuccessAction,
    getAllOfficesFailureAction,
    getByIdOfficeSuccessAction,
    getByIdOfficeFailureAction,
    changeStatusOfficeSuccessAction,
    changeStatusOfficeFailureAction,
    deleteOfficeSuccessAction,
    deleteOfficeFailureAction,
    createOfficeSuccessAction,
    createOfficeFailureAction,
    updateOfficeSuccessAction,
    updateOfficeFailureAction,
    removeOfficeSuccessAction,
    removeOfficeFailureAction,
    loadWardOfficeSuccessAction,
    loadWardOfficeFailureAction,

    loadDistrictOfficeSuccessAction,
    loadDistrictOfficeFailureAction,
    
    loadProvinceOfficeSuccessAction,
    loadProvinceOfficeFailureAction,

    deleteOfficeImageTypeSuccessAction,
    deleteOfficeImageTypeFailureAction,

    updateFieldOfficeSuccessAction,
    updateFieldOfficeFailureAction
} from './officeAction';
import config from 'common/config';

// const API_GETALL = config.HOST + config.VERSION + '/Office/GetAll';
const API_GETALL = config.HOST + config.VERSION + '/Office/GetAllByFilter';
// const API_GETBYID = config.HOST + config.VERSION + '/Office/GetById?id=';
const API_GETBYID = config.HOST + config.VERSION + '/Office/GetOfficeById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Office/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Office/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/Office/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/Office/Update';
const API_REMOVE = config.HOST + config.VERSION + '/Office/Remove/';
const API_GETWARD = config.HOST + config.VERSION + '/LctWard/GetAll';
const API_GET_PROVINCE = config.HOST + config.VERSION + '/LctProvince/GetAll';
const API_GET_DISTRICT = config.HOST + config.VERSION + '/LctDistrict/GetAll';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/OfficeImageType/DeleteImage?imagePath=';
const API_UPDATE_FIELD = config.HOST + config.VERSION + '/Office/UpdateField';

const deleteOfficeImageTypeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload.path, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteOfficeImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteOfficeImageTypeFailureAction(error));
                    }
                })
            );
    })
);

const loadProvinceOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.LOAD_PROVINCE),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GET_PROVINCE, config.HEADERS());
        })
            .pipe(
                map((response) => loadProvinceOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(loadProvinceOfficeFailureAction(error));
                    }
                })
            );
    })
);

const loadDistrictOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.LOAD_DISTRICT),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GET_DISTRICT, config.HEADERS());
        })
            .pipe(
                map((response) => loadDistrictOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(loadDistrictOfficeFailureAction(error));
                    }
                })
            );
    })
);

const loadWardOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.LOAD_WARD),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GETWARD, config.HEADERS());
        })
            .pipe(
                map((response) => loadWardOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(loadWardOfficeFailureAction(error));
                    }
                })
            );
    })
);


// GETALL
const getAllOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getAllOfficesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllOfficesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        // return getNewToken(action$, source);
                    } else {
                        return of(getByIdOfficeFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusOfficeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteOfficeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createOfficeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateOfficeFailureAction(error));
                    }
                })
            );
    })
);

// updateField
const updateFieldOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.UPDATE_FIELD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_UPDATE_FIELD + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => updateFieldOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateFieldOfficeFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeOfficeEpic = (action$, store) => action$.pipe(
    ofType(OFFICE.REMOVE),
    mergeMap((action) => {
        return defer(() => {
          return ajax.delete(API_REMOVE, config.HEADERS());
        })
            .pipe(
                map((response) => removeOfficeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removeOfficeFailureAction(error));
                    }
                })
            );
    })
);

export { getAllOfficeEpic, getByIdOfficeEpic, changeStatusOfficeEpic, deleteOfficeEpic,
    createOfficeEpic, updateOfficeEpic, removeOfficeEpic, loadWardOfficeEpic, loadProvinceOfficeEpic, loadDistrictOfficeEpic,deleteOfficeImageTypeEpic, updateFieldOfficeEpic };