import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CMSCONTENT } from './cmsContentAction';
import { getNewToken } from '../../../services/getNewToken';
import {
    getAllCmsContentsSuccessAction,
    getAllCmsContentsFailureAction,
    getAllCmsContentsByStatusSuccessAction,
    getAllCmsContentsByStatusFailureAction,
    getByIdCmsContentSuccessAction,
    getByIdCmsContentFailureAction,
    changeStatusCmsContentSuccessAction,
    changeStatusCmsContentFailureAction,
    deleteCmsContentSuccessAction,
    deleteCmsContentFailureAction,
    createCmsContentSuccessAction,
    createCmsContentFailureAction,
    updateCmsContentSuccessAction,
    updateCmsContentFailureAction,
    removeCmsContentSuccessAction,
    removeCmsContentFailureAction,
    deleteImageCmsContentSuccessAction,
    deleteImageCmsContentFailureAction,
} from './cmsContentAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsContent/GetAll';
const API_GETALL_BY_STATUS = config.HOST + config.VERSION + '/CmsContent/GetAllByStatus';
const API_GETBYID = config.HOST + config.VERSION + '/CmsContent/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsContent/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsContent/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/CmsContent/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/CmsContent/Update';
const API_REMOVE = config.HOST + config.VERSION + '/CmsContent/Remove';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/CmsContentAttachment/DeleteImage?imagePath=';

// GETALL
const getAllCmsContentsEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsContentsSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsContentsFailureAction(error));
                    }
                })
            );
    })
);
// GETALL BY STATUS
const getAllCmsContentsByStatusEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.GETALL_BY_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_BY_STATUS + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsContentsByStatusSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsContentsByStatusFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.CREATE),
    mergeMap((action) => {
        console.log(action.payload)
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(createCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(removeCmsContentFailureAction(error));
                    }
                })
            );
    })
);

// DELETE IMAGE
const deleteImageCmsContentEpic = (action$, store) => action$.pipe(
    ofType(CMSCONTENT.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteImageCmsContentSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteImageCmsContentFailureAction(error));
                    }
                })
            );
    })
);

export { getAllCmsContentsEpic, getByIdCmsContentEpic, changeStatusCmsContentEpic, deleteCmsContentEpic, 
    createCmsContentEpic, updateCmsContentEpic, removeCmsContentEpic, deleteImageCmsContentEpic, getAllCmsContentsByStatusEpic };