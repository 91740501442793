import { handleActions } from "redux-actions";
import { UPLOAD_MULTIPLE } from "../uploadMultipleAction";
const initialState = {
    isLoading: false,
    isError: false,
    responseData: {},
};
const actions = {
    [UPLOAD_MULTIPLE.CHUNK_MULTIPLE]: () => ({
        isLoading: true,
    }),
    [UPLOAD_MULTIPLE.CHUNK_MULTIPLE_SUCCESS]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        responseData: responseData.response ? responseData.response : responseData,
    }),
    [UPLOAD_MULTIPLE.CHUNK_MULTIPLE_FAILURE]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        isError: true,
        responseData,
    })
};
export default handleActions(actions, initialState);