import { createAction } from "redux-actions";
export const FEEDBACKBYUSER = {
    GETALL: "FEEDBACKBYUSER_GETALL",
    GETALL_SUCCESS: "FEEDBACKBYUSER_GETALL_SUCCESS",
    GETALL_FAILURE: "FEEDBACKBYUSER_GETALL_FAILURE",
    GETBYID: "FEEDBACKBYUSER_GETBYID",
    GETBYID_SUCCESS: "FEEDBACKBYUSER_GETBYID_SUCCESS",
    GETBYID_FAILURE: "FEEDBACKBYUSER_GETBYID_FAILURE",
    CHANGE_STATUS: "FEEDBACKBYUSER_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "FEEDBACKBYUSER_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "FEEDBACKBYUSER_CHANGE_STATUS_FAILURE",
    DELETE: "FEEDBACKBYUSER_DELETE",
    DELETE_SUCCESS: "FEEDBACKBYUSER_DELETE_SUCCESS",
    DELETE_FAILURE: "FEEDBACKBYUSER_DELETE_FAILURE",
    ADD: "FEEDBACKBYUSER_ADD",
    ADD_SUCCESS: "FEEDBACKBYUSER_ADD_SUCCESS",
    ADD_FAILURE: "FEEDBACKBYUSER_ADD_FAILURE",
    EDIT: "FEEDBACKBYUSER_EDIT",
    EDIT_SUCCESS: "FEEDBACKBYUSER_EDIT_SUCCESS",
    EDIT_FAILURE: "FEEDBACKBYUSER_EDIT_FAILURE",
    CREATE_RESET: 'FEEDBACKBYUSER_CREATE_RESET',
};
export const getAllFeedbackByUserAction = createAction(FEEDBACKBYUSER.GETALL);
export const getAllFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.GETALL_SUCCESS);
export const getAllFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.GETALL_FAILURE);
export const getByIdFeedbackByUserAction = createAction(FEEDBACKBYUSER.GETBYID);
export const getByIdFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.GETBYID_SUCCESS);
export const getByIdFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.GETBYID_FAILURE);
export const changeStatusFeedbackByUserAction = createAction(FEEDBACKBYUSER.CHANGE_STATUS);
export const changeStatusFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.CHANGE_STATUS_SUCCESS);
export const changeStatusFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.CHANGE_STATUS_FAILURE);
export const deleteFeedbackByUserAction = createAction(FEEDBACKBYUSER.DELETE);
export const deleteFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.DELETE_SUCCESS);
export const deleteFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.DELETE_FAILURE);
export const addFeedbackByUserAction = createAction(FEEDBACKBYUSER.ADD);
export const addFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.ADD_SUCCESS);
export const addFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.ADD_FAILURE);
export const editFeedbackByUserAction = createAction(FEEDBACKBYUSER.EDIT);
export const editFeedbackByUserSuccessAction = createAction(FEEDBACKBYUSER.EDIT_SUCCESS);
export const editFeedbackByUserFailureAction = createAction(FEEDBACKBYUSER.EDIT_FAILURE);
export const createResetFeedbackByUserAction = createAction(FEEDBACKBYUSER.CREATE_RESET);