import { createAction } from "redux-actions";
export const MENU = {
    GETALL: "MENU_GETALL",
    GETALL_SUCCESS: "MENU_GETALL_SUCCESS",
    GETALL_FAILURE: "MENU_GETALL_FAILURE",
    GETBYID: "MENU_GETBYID",
    GETBYID_SUCCESS: "MENU_GETBYID_SUCCESS",
    GETBYID_FAILURE: "MENU_GETBYID_FAILURE",
    CHANGE_STATUS: "MENU_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "MENU_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "MENU_CHANGE_STATUS_FAILURE",
    DELETE: "MENU_DELETE",
    DELETE_SUCCESS: "MENU_DELETE_SUCCESS",
    DELETE_FAILURE: "MENU_DELETE_FAILURE",
    ADD: "MENU_ADD",
    ADD_SUCCESS: "MENU_ADD_SUCCESS",
    ADD_FAILURE: "MENU_ADD_FAILURE",
    EDIT: "MENU_EDIT",
    EDIT_SUCCESS: "MENU_EDIT_SUCCESS",
    EDIT_FAILURE: "MENU_EDIT_FAILURE",
    CREATE_RESET: 'MENU_CREATE_RESET'
};
export const getMenusAction = createAction(MENU.GETALL);
export const getMenusSuccessAction = createAction(MENU.GETALL_SUCCESS);
export const getMenusFailureAction = createAction(MENU.GETALL_FAILURE);
export const getMenuByIdAction = createAction(MENU.GETBYID);
export const getMenuByIdSuccessAction = createAction(MENU.GETBYID_SUCCESS);
export const getMenuByIdFailureAction = createAction(MENU.GETBYID_FAILURE);
export const changeStatusMenuAction = createAction(MENU.CHANGE_STATUS);
export const changeStatusMenuSuccessAction = createAction(MENU.CHANGE_STATUS_SUCCESS);
export const changeStatusMenuFailureAction = createAction(MENU.CHANGE_STATUS_FAILURE);
export const deleteMenuAction = createAction(MENU.DELETE);
export const deleteMenuSuccessAction = createAction(MENU.DELETE_SUCCESS);
export const deleteMenuFailureAction = createAction(MENU.DELETE_FAILURE);
export const createMenuAction = createAction(MENU.ADD);
export const createMenuSuccessAction = createAction(MENU.ADD_SUCCESS);
export const createMenuFailureAction = createAction(MENU.ADD_FAILURE);
export const updateMenuAction = createAction(MENU.EDIT);
export const updateMenuSuccessAction = createAction(MENU.EDIT_SUCCESS);
export const updateMenuFailureAction = createAction(MENU.EDIT_FAILURE);
export const createResetAction = createAction(MENU.CREATE_RESET);