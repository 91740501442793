import { createAction } from "redux-actions";
export const DEVELOPMENTTYPE = {
    GETALL: "DEVELOPMENTTYPE_GETALL",
    GETALL_SUCCESS: "DEVELOPMENTTYPE_GETALL_SUCCESS",
    GETALL_FAILURE: "DEVELOPMENTTYPE_GETALL_FAILURE",
    GETBYID: "DEVELOPMENTTYPE_GETBYID",
    GETBYID_SUCCESS: "DEVELOPMENTTYPE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "DEVELOPMENTTYPE_GETBYID_FAILURE",
    CHANGE_STATUS: "DEVELOPMENTTYPE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "DEVELOPMENTTYPE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "DEVELOPMENTTYPE_CHANGE_STATUS_FAILURE",
    DELETE: "DEVELOPMENTTYPE_DELETE",
    DELETE_SUCCESS: "DEVELOPMENTTYPE_DELETE_SUCCESS",
    DELETE_FAILURE: "DEVELOPMENTTYPE_DELETE_FAILURE",
    ADD: "DEVELOPMENTTYPE_ADD",
    ADD_SUCCESS: "DEVELOPMENTTYPE_ADD_SUCCESS",
    ADD_FAILURE: "DEVELOPMENTTYPE_ADD_FAILURE",
    EDIT: "DEVELOPMENTTYPE_EDIT",
    EDIT_SUCCESS: "DEVELOPMENTTYPE_EDIT_SUCCESS",
    EDIT_FAILURE: "DEVELOPMENTTYPE_EDIT_FAILURE",
    CREATE_RESET: 'DEVELOPMENTTYPE_CREATE_RESET',
};
export const getAllDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.GETALL);
export const getAllDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.GETALL_SUCCESS);
export const getAllDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.GETALL_FAILURE);
export const getByIdDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.GETBYID);
export const getByIdDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.GETBYID_SUCCESS);
export const getByIdDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.GETBYID_FAILURE);
export const changeStatusDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.CHANGE_STATUS);
export const changeStatusDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.CHANGE_STATUS_SUCCESS);
export const changeStatusDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.CHANGE_STATUS_FAILURE);
export const deleteDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.DELETE);
export const deleteDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.DELETE_SUCCESS);
export const deleteDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.DELETE_FAILURE);
export const addDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.ADD);
export const addDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.ADD_SUCCESS);
export const addDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.ADD_FAILURE);
export const editDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.EDIT);
export const editDevelopmentTypeSuccessAction = createAction(DEVELOPMENTTYPE.EDIT_SUCCESS);
export const editDevelopmentTypeFailureAction = createAction(DEVELOPMENTTYPE.EDIT_FAILURE);
export const createResetDevelopmentTypeAction = createAction(DEVELOPMENTTYPE.CREATE_RESET);
