import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { DASHBOARD } from './dashboardAction';
import { getNewToken } from '../../services/getNewToken';
import {
    dashboardSuccessAction,
    dashboardFailureAction,
} from './dashboardAction';
import config from 'common/config';

const API_DASHBOARD = config.HOST + config.VERSION + '/Dashboard/StatisticsInfo';

const dashboardEpic = (action$, store) => action$.pipe(
    ofType(DASHBOARD.DASHBOARD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_DASHBOARD, config.HEADERS());
        })
            .pipe(
                map((response) => dashboardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(dashboardFailureAction(error));
                    }
                })
            );
    })
);

export { dashboardEpic };