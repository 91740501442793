import { handleActions } from "redux-actions";
import { UPLOAD_CROP_MULTIPLE } from "../uploadCropMultipleAction";
const initialState = {
    isLoading: false,
    isError: false,
    responseData: {},
};
const actions = {
    [UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE]: () => ({
        isLoading: true,
    }),
    [UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE_SUCCESS]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        responseData: responseData.response ? responseData.response : responseData,
    }),
    [UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE_FAILURE]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        isError: true,
        responseData,
    })
};
export default handleActions(actions, initialState);