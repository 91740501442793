import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { MENU } from './menuAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getMenusSuccessAction,
    getMenusFailureAction,
    getMenuByIdSuccessAction,
    getMenuByIdFailureAction,
    changeStatusMenuSuccessAction,
    changeStatusMenuFailureAction,
    deleteMenuSuccessAction,
    deleteMenuFailureAction,
    createMenuSuccessAction,
    createMenuFailureAction,
    updateMenuSuccessAction,
    updateMenuFailureAction
} from './menuAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Menu/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/Menu/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Menu/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Menu/Delete/';
const API_ADD = config.HOST + config.VERSION + '/Menu/Create';
const API_EDIT = config.HOST + config.VERSION + '/Menu/Update';

// GETALL
const getMenusEpic = (action$, store) => action$.pipe(
    ofType(MENU.GETALL),
    mergeMap((action) => {
        return defer(() => {
          return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getMenusSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getMenusFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getMenuByIdEpic = (action$, store) => action$.pipe(
    ofType(MENU.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getMenuByIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getMenuByIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusMenuEpic = (action$, store) => action$.pipe(
    ofType(MENU.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusMenuSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusMenuFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteMenuEpic = (action$, store) => action$.pipe(
    ofType(MENU.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteMenuSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteMenuFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createMenuEpic = (action$, store) => action$.pipe(
    ofType(MENU.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createMenuSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(createMenuFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateMenuEpic = (action$, store) => action$.pipe(
    ofType(MENU.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateMenuSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateMenuFailureAction(error));
                    }
                })
            );
    })
);

export { getMenusEpic, changeStatusMenuEpic, deleteMenuEpic, createMenuEpic, updateMenuEpic, getMenuByIdEpic };