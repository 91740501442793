import { createAction } from "redux-actions";
export const LOGIN = {
    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGIN_RESET: "LOGIN_RESET",
    CURRENT_PROFILE: "CURRENT_PROFILE",
    CURRENT_PROFILE_SUCCESS: "CURRENT_PROFILE_SUCCESS",
    CURRENT_PROFILE_ERROR: "CURRENT_PROFILE_ERROR",
  CURRENT_PROFILE_ERROR: "CURRENT_PROFILE_ERROR",
  CURRENT_PROFILE_RESET: "CURRENT_PROFILE_RESET",
};
export const loginAction = createAction(LOGIN.LOGIN);
export const loginSuccessAction = createAction(LOGIN.LOGIN_SUCCESS);
export const loginFailureAction = createAction(LOGIN.LOGIN_FAILURE);
export const loginResetAction = createAction(LOGIN.LOGIN_RESET);
export const getCurrentProfileAction = createAction(LOGIN.CURRENT_PROFILE);
export const getCurrentProfileSuccessAction = createAction(LOGIN.CURRENT_PROFILE_SUCCESS);
export const getCurrentProfileErrorAction = createAction(LOGIN.CURRENT_PROFILE_ERROR);
export const currenProfileResetAction = createAction(LOGIN.CURRENT_PROFILE_RESET);
