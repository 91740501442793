import { handleActions } from "redux-actions";
import { TOKEN } from "../tokenAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [TOKEN.TOKEN]: () => ({
    isLoading: true,
  }),
  [TOKEN.TOKEN_SUCCESS]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    responseData: responseData.response ? responseData.response : responseData,
  }),
  [TOKEN.TOKEN_FAILURE]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  }),
  [TOKEN.TOKEN_RESET]: (state, { payload: responseData }) => {
    state = initialState;
    return state;
  }
};
export default handleActions(actions, initialState);