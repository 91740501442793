import { createAction } from "redux-actions";
export const UTILITY = {
    GETALL: "UTILITY_GETALL",
    GETALL_SUCCESS: "UTILITY_GETALL_SUCCESS",
    GETALL_FAILURE: "UTILITY_GETALL_FAILURE",
    GETBYID: "UTILITY_GETBYID",
    GETBYID_SUCCESS: "UTILITY_GETBYID_SUCCESS",
    GETBYID_FAILURE: "UTILITY_GETBYID_FAILURE",
    CHANGE_STATUS: "UTILITY_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "UTILITY_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "UTILITY_CHANGE_STATUS_FAILURE",
    DELETE: "UTILITY_DELETE",
    DELETE_SUCCESS: "UTILITY_DELETE_SUCCESS",
    DELETE_FAILURE: "UTILITY_DELETE_FAILURE",
    CREATE: "UTILITY_CREATE",
    CREATE_SUCCESS: "UTILITY_CREATE_SUCCESS",
    CREATE_FAILURE: "UTILITY_CREATE_FAILURE",
    UPDATE: "UTILITY_UPDATE",
    UPDATE_SUCCESS: "UTILITY_UPDATE_SUCCESS",
    UPDATE_FAILURE: "UTILITY_UPDATE_FAILURE",
    REMOVE: "UTILITY_REMOVE",
    REMOVE_SUCCESS: "UTILITY_REMOVE_SUCCESS",
    REMOVE_FAILURE: "UTILITY_REMOVE_FAILURE",
    CREATE_RESET: 'UTILITY_CREATE_RESET',
    UPDATE_RESET: 'UTILITY_UPDATE_RESET'
};
export const getAllUtilitiesAction = createAction(UTILITY.GETALL);
export const getAllUtilitiesSuccessAction = createAction(UTILITY.GETALL_SUCCESS);
export const getAllUtilitiesFailureAction = createAction(UTILITY.GETALL_FAILURE);
export const getByIdUtilityAction = createAction(UTILITY.GETBYID);
export const getByIdUtilitySuccessAction = createAction(UTILITY.GETBYID_SUCCESS);
export const getByIdUtilityFailureAction = createAction(UTILITY.GETBYID_FAILURE);
export const changeStatusUtilityAction = createAction(UTILITY.CHANGE_STATUS);
export const changeStatusUtilitySuccessAction = createAction(UTILITY.CHANGE_STATUS_SUCCESS);
export const changeStatusUtilityFailureAction = createAction(UTILITY.CHANGE_STATUS_FAILURE);
export const deleteUtilityAction = createAction(UTILITY.DELETE);
export const deleteUtilitySuccessAction = createAction(UTILITY.DELETE_SUCCESS);
export const deleteUtilityFailureAction = createAction(UTILITY.DELETE_FAILURE);
export const createUtilityAction = createAction(UTILITY.CREATE);
export const createUtilitySuccessAction = createAction(UTILITY.CREATE_SUCCESS);
export const createUtilityFailureAction = createAction(UTILITY.CREATE_FAILURE);
export const updateUtilityAction = createAction(UTILITY.UPDATE);
export const updateUtilitySuccessAction = createAction(UTILITY.UPDATE_SUCCESS);
export const updateUtilityFailureAction = createAction(UTILITY.UPDATE_FAILURE);
export const removeUtilityAction = createAction(UTILITY.REMOVE);
export const removeUtilitySuccessAction = createAction(UTILITY.REMOVE_SUCCESS);
export const removeUtilityFailureAction = createAction(UTILITY.REMOVE_FAILURE);
export const createResetUtilityAction = createAction(UTILITY.CREATE_RESET);
export const updateResetUtilityAction = createAction(UTILITY.UPDATE_RESET);