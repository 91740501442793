import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getByDistrictCodeWardFailureAction, getByDistrictCodeWardSuccessAction, WARD } from './wardAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllWardSuccessAction,
    getAllWardFailureAction,
    
    getAllFullWardSuccessAction,
    getAllFullWardFailureAction,

    getByIdWardSuccessAction,
    getByIdWardFailureAction,
    changeStatusWardSuccessAction,
    changeStatusWardFailureAction,
    deleteWardSuccessAction,
    deleteWardFailureAction,
    addWardSuccessAction,
    addWardFailureAction,
    editWardSuccessAction,
    editWardFailureAction,
    getByDistrictCodeSuccessAction,
    getByDistrictCodeFailureAction
} from './wardAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/LctWard/GetAll?pageSize=100&pageNumber=1';
const API_GETALL_FULL = config.HOST + config.VERSION + '/LctWard/GetAll?pageSize=-999&pageNumber=-999';
const API_GETBYID = config.HOST + config.VERSION + '/LctWard/GetById?id=';
const API_GETBY_DISTRICT_CODE = config.HOST + config.VERSION + '/LctWard/GetAllByDistrictCode?districtCode=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/LctWard/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/LctWard/Delete/';
const API_POST = config.HOST + config.VERSION + '/LctWard/Create';
const API_UPSERT = config.HOST + config.VERSION + '/LctWard/Update';
const API_GETBYDISTRICT = config.HOST + config.VERSION + '/LctWard/GetAllByDistrictCode?districtCode='

const getAllWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllWardFailureAction(error));
                    }
                })
            );
    })
);

const getAllFullWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.GETALL_FULL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_FULL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllFullWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllFullWardFailureAction(error));
                    }
                })
            );
    })
);

// GETBY DISTRICT CODE
const getByDistrictCodeWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.GETBY_DISTRICT_CODE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBY_DISTRICT_CODE + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByDistrictCodeWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByDistrictCodeWardFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdWardFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusWardFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteWardFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addWardFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editWardEpic = (action$, store) => action$.pipe(
    ofType(WARD.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editWardSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editWardFailureAction(error));
                    }
                })
            );
    })
);

// GETBYDISTRICT
const getByDistrictEpic = (action$, store) => action$.pipe(
    ofType(WARD.GETBY_DICTRICT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYDISTRICT + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByDistrictCodeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByDistrictCodeFailureAction(error));
                    }
                })
            );
    })
);

export { getAllWardEpic, getAllFullWardEpic, getByIdWardEpic, changeStatusWardEpic, deleteWardEpic, addWardEpic, editWardEpic, getByDistrictEpic, getByDistrictCodeWardEpic };