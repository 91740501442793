import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { TOKEN } from './tokenAction';
import {
    tokenSuccessAction,
    tokenFailureAction,
} from './tokenAction';
import config from 'common/config';
import { removeSessionStorage, setSessionStorage } from '../../common/utilities/storage';
import { commonConstant } from '../../common/constant';
import { toastr } from 'react-redux-toastr';

const API_TOKEN = config.HOST + config.VERSION + '/Auth/GetNewToken';

const tokenEpic = action$ => action$.pipe(
    ofType(TOKEN.TOKEN),
    mergeMap((action) => {
        return ajax.post(API_TOKEN, action.payload, config.HEADERS()).pipe(
            map(response => {
                if (response && response.response && response.response.data ) {
                    setSessionStorage(commonConstant.AUTH_ID, response.response.data.id);
                    setSessionStorage(commonConstant.AUTH_TOKEN, response.response.data.auth_token);
                    setSessionStorage(commonConstant.AUTH_EXPIRES_IN, response.response.data.expires_in);
                    setSessionStorage(commonConstant.REFRESH_TOKEN, response.response.data1.token);
                    setSessionStorage(commonConstant.REFRESH_EXPIRES_IN, response.response.data1.expireAt);
                  }
                return tokenSuccessAction(response);
            }),
            catchError(error => {
                toastr.error('Message', 'An error has occured. Please login again');
                removeSessionStorage(commonConstant.AUTH_ID);
                removeSessionStorage(commonConstant.AUTH_TOKEN);
                removeSessionStorage(commonConstant.AUTH_EXPIRES_IN);
                removeSessionStorage(commonConstant.REFRESH_TOKEN);
                removeSessionStorage(commonConstant.REFRESH_EXPIRES_IN);
                window.location.href = '/#/login';
                return of(tokenFailureAction(error));
            })
        )
    })
);

export { tokenEpic };