import { createAction } from "redux-actions";
export const CONFIG_TYPE = {
    GETALL: "CONFIG_TYPE_GETALL",
    GETALL_SUCCESS: "CONFIG_TYPE_GETALL_SUCCESS",
    GETALL_FAILURE: "CONFIG_TYPE_GETALL_FAILURE",
    GETBYID: "CONFIG_TYPE_GETBYID",
    GETBYID_SUCCESS: "CONFIG_TYPE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CONFIG_TYPE_GETBYID_FAILURE",
    CHANGE_STATUS: "CONFIG_TYPE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CONFIG_TYPE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CONFIG_TYPE_CHANGE_STATUS_FAILURE",
    DELETE: "CONFIG_TYPE_DELETE",
    DELETE_SUCCESS: "CONFIG_TYPE_DELETE_SUCCESS",
    DELETE_FAILURE: "CONFIG_TYPE_DELETE_FAILURE",
    ADD: "CONFIG_TYPE_ADD",
    ADD_SUCCESS: "CONFIG_TYPE_ADD_SUCCESS",
    ADD_FAILURE: "CONFIG_TYPE_ADD_FAILURE",
    EDIT: "CONFIG_TYPE_EDIT",
    EDIT_SUCCESS: "CONFIG_TYPE_EDIT_SUCCESS",
    EDIT_FAILURE: "CONFIG_TYPE_EDIT_FAILURE",
    CREATE_RESET: 'CONFIG_TYPE_CREATE_RESET'
};
export const getConfigTypesAction = createAction(CONFIG_TYPE.GETALL);
export const getConfigTypesSuccessAction = createAction(CONFIG_TYPE.GETALL_SUCCESS);
export const getConfigTypesFailureAction = createAction(CONFIG_TYPE.GETALL_FAILURE);
export const getConfigTypeByIdAction = createAction(CONFIG_TYPE.GETBYID);
export const getConfigTypeByIdSuccessAction = createAction(CONFIG_TYPE.GETBYID_SUCCESS);
export const getConfigTypeByIdFailureAction = createAction(CONFIG_TYPE.GETBYID_FAILURE);
export const changeStatusConfigTypeAction = createAction(CONFIG_TYPE.CHANGE_STATUS);
export const changeStatusConfigTypeSuccessAction = createAction(CONFIG_TYPE.CHANGE_STATUS_SUCCESS);
export const changeStatusConfigTypeFailureAction = createAction(CONFIG_TYPE.CHANGE_STATUS_FAILURE);
export const deleteConfigTypeAction = createAction(CONFIG_TYPE.DELETE);
export const deleteConfigTypeSuccessAction = createAction(CONFIG_TYPE.DELETE_SUCCESS);
export const deleteConfigTypeFailureAction = createAction(CONFIG_TYPE.DELETE_FAILURE);
export const createConfigTypeAction = createAction(CONFIG_TYPE.ADD);
export const createConfigTypeSuccessAction = createAction(CONFIG_TYPE.ADD_SUCCESS);
export const createConfigTypeFailureAction = createAction(CONFIG_TYPE.ADD_FAILURE);
export const updateConfigTypeAction = createAction(CONFIG_TYPE.EDIT);
export const updateConfigTypeSuccessAction = createAction(CONFIG_TYPE.EDIT_SUCCESS);
export const updateConfigTypeFailureAction = createAction(CONFIG_TYPE.EDIT_FAILURE);
export const createResetAction = createAction(CONFIG_TYPE.CREATE_RESET);