import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CURRENCY } from './currencyAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllCurrencySuccessAction,
    getAllCurrencyFailureAction,
    getByIdCurrencySuccessAction,
    getByIdCurrencyFailureAction,
    changeStatusCurrencySuccessAction,
    changeStatusCurrencyFailureAction,
    deleteCurrencySuccessAction,
    deleteCurrencyFailureAction,
    addCurrencySuccessAction,
    addCurrencyFailureAction,
    editCurrencySuccessAction,
    editCurrencyFailureAction,
} from './currencyAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Currency/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/Currency/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Currency/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Currency/Delete/';
const API_POST = config.HOST + config.VERSION + '/Currency/Create';
const API_UPSERT = config.HOST + config.VERSION + '/Currency/Update';

const getAllCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCurrencyFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdCurrencyFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusCurrencyFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteCurrencyFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addCurrencyFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editCurrencyEpic = (action$, store) => action$.pipe(
    ofType(CURRENCY.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editCurrencySuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editCurrencyFailureAction(error));
                    }
                })
            );
    })
);


export { getAllCurrencyEpic, getByIdCurrencyEpic, changeStatusCurrencyEpic, deleteCurrencyEpic, addCurrencyEpic, editCurrencyEpic };