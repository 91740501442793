import { handleActions } from "redux-actions";
import { TRANSLATION } from "../transLationAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [TRANSLATION.CHANGE_LANGUAGE]: () => ({
    isLoading: true,
  }),
  [TRANSLATION.CHANGE_LANGUAGE_SUCCESS]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    responseData
  }),
  [TRANSLATION.CHANGE_LANGUAGE_FAILURE]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  })
};
export default handleActions(actions, initialState);