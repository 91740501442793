import { createAction } from "redux-actions";
export const LANGUAGE = {
    GETALL: "LANGUAGE_GETALL",
    GETALL_SUCCESS: "LANGUAGE_GETALL_SUCCESS",
    GETALL_FAILURE: "LANGUAGE_GETALL_FAILURE",
    GETBYID: "LANGUAGE_GETBYID",
    GETBYID_SUCCESS: "LANGUAGE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "LANGUAGE_GETBYID_FAILURE",
    CHANGE_STATUS: "LANGUAGE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "LANGUAGE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "LANGUAGE_CHANGE_STATUS_FAILURE",
    DELETE: "LANGUAGE_DELETE",
    DELETE_SUCCESS: "LANGUAGE_DELETE_SUCCESS",
    DELETE_FAILURE: "LANGUAGE_DELETE_FAILURE",
    ADD: "LANGUAGE_ADD",
    ADD_SUCCESS: "LANGUAGE_ADD_SUCCESS",
    ADD_FAILURE: "LANGUAGE_ADD_FAILURE",
    EDIT: "LANGUAGE_EDIT",
    EDIT_SUCCESS: "LANGUAGE_EDIT_SUCCESS",
    EDIT_FAILURE: "LANGUAGE_EDIT_FAILURE",
    CREATE_RESET: 'LANGUAGE_CREATE_RESET'
};
export const getLanguagesAction = createAction(LANGUAGE.GETALL);
export const getLanguagesSuccessAction = createAction(LANGUAGE.GETALL_SUCCESS);
export const getLanguagesFailureAction = createAction(LANGUAGE.GETALL_FAILURE);
export const getLanguageByIdAction = createAction(LANGUAGE.GETBYID);
export const getLanguageByIdSuccessAction = createAction(LANGUAGE.GETBYID_SUCCESS);
export const getLanguageByIdFailureAction = createAction(LANGUAGE.GETBYID_FAILURE);
export const changeStatusLanguageAction = createAction(LANGUAGE.CHANGE_STATUS);
export const changeStatusLanguageSuccessAction = createAction(LANGUAGE.CHANGE_STATUS_SUCCESS);
export const changeStatusLanguageFailureAction = createAction(LANGUAGE.CHANGE_STATUS_FAILURE);
export const deleteLanguageAction = createAction(LANGUAGE.DELETE);
export const deleteLanguageSuccessAction = createAction(LANGUAGE.DELETE_SUCCESS);
export const deleteLanguageFailureAction = createAction(LANGUAGE.DELETE_FAILURE);
export const createLanguageAction = createAction(LANGUAGE.ADD);
export const createLanguageSuccessAction = createAction(LANGUAGE.ADD_SUCCESS);
export const createLanguageFailureAction = createAction(LANGUAGE.ADD_FAILURE);
export const updateLanguageAction = createAction(LANGUAGE.EDIT);
export const updateLanguageSuccessAction = createAction(LANGUAGE.EDIT_SUCCESS);
export const updateLanguageFailureAction = createAction(LANGUAGE.EDIT_FAILURE);
export const createResetAction = createAction(LANGUAGE.CREATE_RESET);