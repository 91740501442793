import { createAction } from "redux-actions";
export const RESET_PASSWORD = {
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    RESET_PASSWORD_RESET: "RESET_PASSWORD_RESET"
};
export const resetpasswordAction = createAction(RESET_PASSWORD.RESET_PASSWORD);
export const resetpasswordSuccessAction = createAction(RESET_PASSWORD.RESET_PASSWORD_SUCCESS);
export const resetpasswordFailureAction = createAction(RESET_PASSWORD.RESET_PASSWORD_FAILURE);
export const resetpasswordResetAction = createAction(RESET_PASSWORD.RESET_PASSWORD_RESET);