import { createAction } from "redux-actions";
export const DASHBOARD = {
    DASHBOARD: "DASHBOARD",
    DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
    DASHBOARD_FAILURE: "DASHBOARD_FAILURE",
    DASHBOARD_RESET: "DASHBOARD_RESET"
};
export const dashboardAction = createAction(DASHBOARD.DASHBOARD);
export const dashboardSuccessAction = createAction(DASHBOARD.DASHBOARD_SUCCESS);
export const dashboardFailureAction = createAction(DASHBOARD.DASHBOARD_FAILURE);
export const dashboardResetAction = createAction(DASHBOARD.DASHBOARD_RESET);