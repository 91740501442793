import { createAction } from "redux-actions";
export const BUILDINGCLASS = {
    GETALL: "BUILDINGCLASS_GETALL",
    GETALL_SUCCESS: "BUILDINGCLASS_GETALL_SUCCESS",
    GETALL_FAILURE: "BUILDINGCLASS_GETALL_FAILURE",
    GETBYID: "BUILDINGCLASS_GETBYID",
    GETBYID_SUCCESS: "BUILDINGCLASS_GETBYID_SUCCESS",
    GETBYID_FAILURE: "BUILDINGCLASS_GETBYID_FAILURE",
    CHANGE_STATUS: "BUILDINGCLASS_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "BUILDINGCLASS_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "BUILDINGCLASS_CHANGE_STATUS_FAILURE",
    DELETE: "BUILDINGCLASS_DELETE",
    DELETE_SUCCESS: "BUILDINGCLASS_DELETE_SUCCESS",
    DELETE_FAILURE: "BUILDINGCLASS_DELETE_FAILURE",
    ADD: "BUILDINGCLASS_ADD",
    ADD_SUCCESS: "BUILDINGCLASS_ADD_SUCCESS",
    ADD_FAILURE: "BUILDINGCLASS_ADD_FAILURE",
    EDIT: "BUILDINGCLASS_EDIT",
    EDIT_SUCCESS: "BUILDINGCLASS_EDIT_SUCCESS",
    EDIT_FAILURE: "BUILDINGCLASS_EDIT_FAILURE",
    CREATE_RESET: 'BUILDINGCLASS_CREATE_RESET',
};
export const getAllBuildingClassAction = createAction(BUILDINGCLASS.GETALL);
export const getAllBuildingClassSuccessAction = createAction(BUILDINGCLASS.GETALL_SUCCESS);
export const getAllBuildingClassFailureAction = createAction(BUILDINGCLASS.GETALL_FAILURE);
export const getByIdBuildingClassAction = createAction(BUILDINGCLASS.GETBYID);
export const getByIdBuildingClassSuccessAction = createAction(BUILDINGCLASS.GETBYID_SUCCESS);
export const getByIdBuildingClassFailureAction = createAction(BUILDINGCLASS.GETBYID_FAILURE);
export const changeStatusBuildingClassAction = createAction(BUILDINGCLASS.CHANGE_STATUS);
export const changeStatusBuildingClassSuccessAction = createAction(BUILDINGCLASS.CHANGE_STATUS_SUCCESS);
export const changeStatusBuildingClassFailureAction = createAction(BUILDINGCLASS.CHANGE_STATUS_FAILURE);
export const deleteBuildingClassAction = createAction(BUILDINGCLASS.DELETE);
export const deleteBuildingClassSuccessAction = createAction(BUILDINGCLASS.DELETE_SUCCESS);
export const deleteBuildingClassFailureAction = createAction(BUILDINGCLASS.DELETE_FAILURE);
export const addBuildingClassAction = createAction(BUILDINGCLASS.ADD);
export const addBuildingClassSuccessAction = createAction(BUILDINGCLASS.ADD_SUCCESS);
export const addBuildingClassFailureAction = createAction(BUILDINGCLASS.ADD_FAILURE);
export const editBuildingClassAction = createAction(BUILDINGCLASS.EDIT);
export const editBuildingClassSuccessAction = createAction(BUILDINGCLASS.EDIT_SUCCESS);
export const editBuildingClassFailureAction = createAction(BUILDINGCLASS.EDIT_FAILURE);
export const createResetBuildingClassAction = createAction(BUILDINGCLASS.CREATE_RESET);
