import { createAction } from "redux-actions";
export const PROFILE = {
    GETBYID: "PROFILE_GETBYID",
    GETBYID_SUCCESS: "PROFILE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PROFILE_GETBYID_FAILURE",
    UPDATE: "PROFILE_UPDATE",
    UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",
    PROFILE_RESET: "PROFILE_RESET"
};
export const getByIdProfileAction = createAction(PROFILE.GETBYID);
export const getByIdProfileSuccessAction = createAction(PROFILE.GETBYID_SUCCESS);
export const getByIdProfileFailureAction = createAction(PROFILE.GETBYID_FAILURE);
export const updateProfileAction = createAction(PROFILE.UPDATE);
export const updateProfileSuccessAction = createAction(PROFILE.UPDATE_SUCCESS);
export const updateProfileFailureAction = createAction(PROFILE.UPDATE_FAILURE);
export const resetProfileAction = createAction(PROFILE.PROFILE_RESET);
