import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { OBJECTTYPE } from './objectTypeAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllObjectTypeSuccessAction,
    getAllObjectTypeFailureAction,
    getByIdObjectTypeSuccessAction,
    getByIdObjectTypeFailureAction,
    changeStatusObjectTypeSuccessAction,
    changeStatusObjectTypeFailureAction,
    deleteObjectTypeSuccessAction,
    deleteObjectTypeFailureAction,
    addObjectTypeSuccessAction,
    addObjectTypeFailureAction,
    editObjectTypeSuccessAction,
    editObjectTypeFailureAction,
} from './objectTypeAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/ObjectType/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/ObjectType/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/ObjectType/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/ObjectType/Delete/';
const API_POST = config.HOST + config.VERSION + '/ObjectType/Create';
const API_UPSERT = config.HOST + config.VERSION + '/ObjectType/Update';

const getAllObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllObjectTypeFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdObjectTypeFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusObjectTypeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteObjectTypeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addObjectTypeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editObjectTypeEpic = (action$, store) => action$.pipe(
    ofType(OBJECTTYPE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editObjectTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editObjectTypeFailureAction(error));
                    }
                })
            );
    })
);


export { getAllObjectTypeEpic, getByIdObjectTypeEpic, changeStatusObjectTypeEpic, deleteObjectTypeEpic, addObjectTypeEpic, editObjectTypeEpic };