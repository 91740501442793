export const sygnet = ['160 160', `
  <title>coreui logo</title>
  <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px" y="0px" viewBox="0 0 219.66 51.87" overflow="visible" xml:space="preserve">
<g id="Group_1953" transform="translate(0 0)">
 <g>
   <path fill="#0063B0" d="M216.76,13.51c-0.26-0.58-0.68-1.08-1.21-1.44c0.78-0.05,1.4-0.67,1.45-1.45
     c-0.01-0.45-0.18-0.87-0.48-1.2c-0.54-0.2-1.11-0.28-1.69-0.24h-2.17v0.24l0,0h0.24c0.24,0,0.48,0,0.48,0.24v4.57
     c0,0.24,0,0.24-0.48,0.24h-0.24v0.48h2.17v-0.48h-0.24c-0.48,0-0.24-0.48-0.24-0.72v-1.44h0.24c0.72,0,0.96,0.48,1.2,1.2
     c0.24,0.48,0.24,0.96,0.48,1.2c0.29,0.17,0.63,0.25,0.97,0.24h0.48v-0.48h-0.48C216.99,14.21,216.82,13.88,216.76,13.51z
      M214.35,11.83L214.35,11.83L214.35,11.83L214.35,11.83v-1.68c0-0.72,0-0.72,0.48-0.72c0.34-0.04,0.68,0.05,0.96,0.24
     c0.24,0.24,0.24,0.48,0.24,0.96C216.04,11.83,215.32,11.83,214.35,11.83z"/>
   <path fill="#0063B0" d="M219.66,12.07c-0.19-2.59-2.45-4.54-5.04-4.35c-2.59,0.19-4.54,2.45-4.35,5.04
     c0.18,2.41,2.15,4.3,4.57,4.36c2.7-0.03,4.86-2.25,4.83-4.95C219.66,12.14,219.66,12.11,219.66,12.07z M214.84,16.65
     C214.84,16.65,214.84,16.65,214.84,16.65c-2.32-0.09-4.14-2.02-4.11-4.33c0.06-2.33,2-4.17,4.33-4.11c2.33,0.06,4.17,2,4.11,4.33
     C219.11,14.87,217.17,16.71,214.84,16.65z"/>
   <path fill="#0063B0" d="M51.72,14.68v15.4c0,2.79,2.26,5.05,5.05,5.05h19.46c2.79,0,5.05-2.26,5.05-5.05v-8.82l-6.31-1.63l0,8.03
     h-16.3V7.47h16.3v6.9l6.31-1.37V5.05c0-2.79-2.26-5.05-5.05-5.05H59.45h-2.68h-7.18L26.97,43.23L10.34,0H0l21.83,51.87h10.3l0,0
     h182.82v-2.33H33.36L51.72,14.68z"/>
   <path fill="#0063B0" d="M92.63,32.2l5.65-16.78h-4.03l-2.14,7.17c-0.57,1.92-1.09,3.91-1.52,5.92h-0.07
     c-0.4-2.09-0.92-3.98-1.49-6l-2.04-7.09h-4.16L88.2,32.2L92.63,32.2z"/>
   <rect x="99.28" y="20.03" fill="#0063B0" width="3.79" height="12.17"/>
   <path fill="#0063B0" d="M101.14,18.53c0.06,0,0.11,0.01,0.17,0.01c1.04-0.01,1.88-0.86,1.87-1.9c0-1-0.77-1.82-1.76-1.9
     c-1.05-0.08-1.96,0.71-2.04,1.75C99.31,17.54,100.1,18.45,101.14,18.53z"/>
   <path fill="#0063B0" d="M109.4,35.93c-0.01,0.97,0.77,1.76,1.74,1.77c0.02,0,0.03,0,0.05,0c0.95-0.03,1.69-0.82,1.67-1.77l0,0
     c0-0.03,0-0.06,0-0.09c-0.01-0.92-0.77-1.67-1.7-1.65C110.2,34.18,109.41,34.96,109.4,35.93z"/>
   <polygon fill="#0063B0" points="111.12,16.34 111.17,16.34 112.42,18.51 114.88,18.51 112.42,14.72 109.88,14.72 107.39,18.51
     109.86,18.51 		"/>
   <path fill="#0063B0" d="M111.9,32.45c1.51,0.03,3-0.23,4.41-0.77l-0.5-2.56c-1.08,0.36-2.22,0.54-3.36,0.52
     c-1.77,0-3.33-0.75-3.43-2.32l7.84-0.01c0.08-0.49,0.12-0.99,0.12-1.49c0-3.01-1.5-6.07-5.42-6.07c-4.23,0-6.15,3.42-6.15,6.5
     C105.4,30.06,107.77,32.45,111.9,32.45z M111.32,22.27c0.07,0,0.15,0.01,0.22,0.02c1.21,0.15,2.07,1.24,1.92,2.45h-4.48
     C109,23.42,110.02,22.35,111.32,22.27z"/>
   <path fill="#0063B0" d="M119.77,27.79c-0.13,1.29,0.25,2.59,1.05,3.61c0.78,0.71,1.81,1.08,2.86,1.05
     c0.82,0.02,1.65-0.09,2.44-0.32l-0.03-2.86c-0.37,0.08-0.74,0.11-1.12,0.1c-1.12,0-1.5-0.67-1.5-2.14v-4.41h2.71v-2.79h-2.72
     v-3.29l-3.7,1.02v2.26h-1.62v2.79h1.62V27.79z"/>
   <path fill="#0063B0" d="M141.53,32.47c1.46,0.04,2.92-0.2,4.28-0.72l-0.55-2.99c-1.02,0.37-2.1,0.56-3.19,0.55
     c-3.36,0-5.35-2.12-5.35-5.48c0-3.73,2.34-5.55,5.32-5.55c1.09-0.01,2.18,0.2,3.19,0.62l0.8-2.99c-1.3-0.55-2.72-0.81-4.13-0.74
     c-5.08,0-9.16,3.16-9.16,8.89C132.75,28.84,135.73,32.47,141.53,32.47z"/>
   <path fill="#0063B0" d="M151.91,24.98c-0.01-0.28,0.03-0.56,0.13-0.82c0.28-0.8,1.04-1.34,1.89-1.34c1.42,0,1.97,1.1,1.97,2.69
     v6.69h3.78V25.1c0-3.68-1.84-5.35-4.26-5.35c-0.68,0-1.36,0.16-1.97,0.47c-0.59,0.3-1.11,0.73-1.49,1.27h-0.05v-6.97h-3.78
     l0,17.67h3.78V24.98z"/>
   <path fill="#0063B0" d="M166.94,32.47c1.53,0.05,2.98-0.7,3.81-1.99h0.07L171,32.2h3.29c-0.05-1.02-0.1-2.31-0.1-3.91l0-8.26
     h-3.78v7.24c0.01,0.28-0.04,0.56-0.15,0.82c-0.29,0.79-1.05,1.32-1.89,1.32c-1.34,0-1.92-1.07-1.92-2.84v-6.54h-3.78v7.14
     C162.66,30.93,164.45,32.47,166.94,32.47z"/>
   <path fill="#0063B0" d="M180.48,31.8c-0.02,0.2-0.09,0.39-0.2,0.55c-0.39,0.63-0.93,1.15-1.57,1.52
     c-0.51,0.29-1.07,0.51-1.64,0.65l0.82,3.19c1.35-0.17,2.62-0.75,3.63-1.67c1.37-1.22,2.54-3.14,4.28-7.99l2.84-8.01h-4.03
     L183.09,26c-0.17,0.72-0.37,1.61-0.52,2.29h-0.1c-0.15-0.65-0.4-1.57-0.6-2.27l-1.82-6l-4.2,0l4.48,11.15
     C180.43,31.37,180.48,31.59,180.48,31.8z"/>
   <polygon fill="#0063B0" points="195.16,16.34 195.21,16.34 196.45,18.51 198.92,18.51 196.45,14.72 193.92,14.72 191.43,18.51
     193.89,18.51 		"/>
   <path fill="#0063B0" d="M195.67,32.45c1.5,0.03,3-0.23,4.4-0.77l-0.5-2.56c-1.08,0.36-2.22,0.54-3.36,0.52
     c-1.77,0-3.33-0.75-3.43-2.32l7.84-0.01c0.08-0.49,0.13-0.99,0.13-1.49c0-3.01-1.49-6.07-5.43-6.07c-4.23,0-6.15,3.42-6.15,6.5
     C189.17,30.06,191.53,32.45,195.67,32.45z M195.09,22.27c0.07,0,0.15,0.01,0.22,0.02c1.21,0.15,2.07,1.24,1.92,2.45h-4.48
     C192.76,23.42,193.78,22.35,195.09,22.27z"/>
   <path fill="#0063B0" d="M203,32.19h3.78v-7.02c-0.01-0.32,0.03-0.64,0.13-0.94c0.28-0.84,1.06-1.41,1.94-1.42
     c1.37,0,1.92,1.07,1.92,2.64v6.74h3.78V25c0-3.58-1.87-5.25-4.36-5.25c-1.51-0.04-2.93,0.71-3.76,1.96h-0.08l-0.17-1.69h-3.28
     c0.05,1.1,0.1,2.36,0.1,3.88V32.19z"/>
 </g>
 <g>
   <path fill="#E50019" d="M45.5,40.23c0.44-0.01,0.87,0.08,1.27,0.26l0.21-0.67c-0.47-0.21-0.99-0.31-1.51-0.28
     c-0.12-0.01-0.23-0.01-0.35,0c-1.74,0.1-3.07,1.59-2.96,3.33c-0.01,0.15-0.02,0.3-0.01,0.44c0.1,1.63,1.5,2.88,3.13,2.78
     c0.57,0.02,1.15-0.08,1.68-0.3l-0.16-0.66c-0.42,0.18-0.88,0.27-1.34,0.26c-0.13,0.01-0.27,0.01-0.4-0.01
     c-1.26-0.14-2.17-1.28-2.03-2.54c-0.01-0.13-0.02-0.26-0.01-0.39C43.08,41.15,44.2,40.16,45.5,40.23z"/>
   <path fill="#E50019" d="M52.14,41.34c-0.3,0-0.59,0.08-0.85,0.23c-0.25,0.14-0.45,0.35-0.59,0.59h-0.02v-2.85h-0.83l0,6.69h0.83
     v-2.75c-0.01-0.14,0.01-0.27,0.06-0.4c0.16-0.48,0.61-0.81,1.11-0.82c0.78,0,1.05,0.61,1.05,1.35v2.62h0.83v-2.71
     C53.74,41.72,52.76,41.34,52.14,41.34z"/>
   <path fill="#E50019" d="M58.9,41.34c-0.07,0-0.15,0-0.22,0.01c-1.23,0.1-2.14,1.18-2.04,2.4c-0.01,0.06-0.01,0.13-0.01,0.19
     c0.01,1.2,0.99,2.16,2.19,2.15c0.08,0,0.16,0,0.24-0.01c1.23-0.1,2.14-1.18,2.03-2.41c0.01-0.07,0.01-0.15,0.01-0.22
     C61.07,42.26,60.09,41.31,58.9,41.34z M58.86,45.47c-0.81,0-1.37-0.75-1.37-1.75l0,0c0-0.86,0.41-1.76,1.39-1.76
     c0.98,0,1.36,0.97,1.36,1.74C60.24,44.73,59.65,45.47,58.86,45.47z"/>
   <path fill="#E50019" d="M70.9,40.23c0.44-0.01,0.87,0.08,1.27,0.26l0.21-0.67c-0.47-0.21-0.99-0.31-1.51-0.28
     c-0.12-0.01-0.23-0.01-0.35,0c-1.74,0.1-3.06,1.59-2.96,3.33c-0.01,0.15-0.02,0.3-0.01,0.44c0.1,1.63,1.5,2.88,3.13,2.78
     c0.57,0.02,1.15-0.08,1.68-0.3l-0.16-0.66c-0.43,0.18-0.89,0.27-1.35,0.26c-0.13,0.01-0.27,0.01-0.4-0.01
     c-1.26-0.14-2.17-1.28-2.03-2.54c-0.01-0.13-0.02-0.26-0.01-0.39C68.48,41.15,69.59,40.16,70.9,40.23z"/>
   <polygon fill="#E50019" points="77.02,39.93 77.03,39.93 77.59,40.81 78.22,40.81 77.32,39.47 76.75,39.47 75.86,40.81
     76.48,40.81 		"/>
   <path fill="#E50019" d="M77.07,41.34c-0.07,0-0.15,0-0.22,0.01c-1.23,0.1-2.14,1.18-2.04,2.4c-0.01,0.06-0.01,0.13-0.01,0.19
     c0.01,1.2,0.99,2.16,2.19,2.15c0.08,0,0.16,0,0.24-0.01c1.23-0.11,2.14-1.18,2.03-2.41c0.01-0.07,0.01-0.15,0.01-0.22
     C79.25,42.26,78.26,41.31,77.07,41.34z M77.04,45.47c-0.81,0-1.37-0.75-1.37-1.75h0c0-0.86,0.42-1.76,1.4-1.76
     c0.98,0,1.37,0.97,1.37,1.74C78.42,44.73,77.83,45.47,77.04,45.47z"/>
   <path fill="#E50019" d="M84.46,41.34c-0.62-0.02-1.2,0.31-1.51,0.85h-0.02l-0.05-0.75h-0.73c0.03,0.38,0.04,0.75,0.04,1.23l0,3.32
     h0.83v-2.74c0-0.13,0.02-0.26,0.06-0.39c0.15-0.49,0.6-0.83,1.11-0.85c0.78,0,1.05,0.61,1.05,1.35v2.63h0.83v-2.71
     C86.08,41.71,85.1,41.34,84.46,41.34z"/>
   <path fill="#E50019" d="M93.24,41.43h-0.74l-0.03,0.69h-0.02c-0.28-0.51-0.83-0.82-1.42-0.78c-0.04,0-0.09,0.01-0.13,0.01
     c-1.2,0.13-2.06,1.2-1.94,2.4c0,0.03-0.01,0.07-0.01,0.1c-0.05,1.12,0.82,2.07,1.95,2.12c0.59,0.03,1.14-0.26,1.45-0.76h0.02v0.5
     c0,1.16-0.62,1.6-1.47,1.6c-0.47,0.01-0.94-0.12-1.34-0.37l-0.21,0.64c0.46,0.26,0.99,0.39,1.52,0.38
     c0.6,0.03,1.19-0.17,1.67-0.54c0.5-0.57,0.74-1.33,0.65-2.09v-2.65C93.2,42.14,93.21,41.76,93.24,41.43z M92.38,44.05
     c0,0.15-0.02,0.3-0.07,0.44c-0.15,0.5-0.62,0.85-1.15,0.85c-0.89,0-1.35-0.74-1.35-1.64c0-1.05,0.57-1.72,1.36-1.72
     c0.54-0.01,1.02,0.36,1.16,0.88c0.04,0.12,0.05,0.24,0.05,0.37V44.05z"/>
   <path fill="#E50019" d="M104.35,42.3c0,0.99,0.02,1.76,0.09,2.61l-0.03,0.01c-0.33-0.71-0.71-1.4-1.13-2.06l-2.03-3.21h-0.9v6.35
     h0.77v-2.71c0-1.05-0.01-1.82-0.06-2.62l0.03-0.01c0.35,0.73,0.74,1.44,1.18,2.12l2.02,3.22h0.83v-6.35h-0.77V42.3z"/>
   <path fill="#E50019" d="M111.62,41.43l-0.03,0.69h-0.02c-0.28-0.51-0.84-0.82-1.42-0.78c-0.04,0-0.09,0.01-0.13,0.01
     c-1.2,0.13-2.06,1.2-1.93,2.4c0,0.03-0.01,0.07-0.01,0.1c-0.05,1.12,0.82,2.07,1.95,2.12c0.59,0.03,1.14-0.26,1.45-0.76h0.02v0.5
     c0,1.16-0.62,1.6-1.47,1.6c-0.47,0.01-0.94-0.12-1.34-0.37l-0.21,0.64c0.46,0.26,0.99,0.39,1.52,0.38
     c0.6,0.02,1.19-0.17,1.67-0.54c0.5-0.57,0.74-1.33,0.65-2.09l0-2.65c0-0.54,0.01-0.92,0.04-1.24H111.62z M111.49,44.05
     c0,0.15-0.02,0.3-0.07,0.44c-0.15,0.51-0.62,0.85-1.15,0.85c-0.89,0-1.35-0.74-1.35-1.64c0-1.05,0.57-1.73,1.36-1.73
     c0.54-0.01,1.02,0.36,1.16,0.88c0.04,0.12,0.05,0.24,0.05,0.37V44.05z"/>
   <path fill="#E50019" d="M117.85,41.34c-0.3,0-0.59,0.08-0.85,0.23c-0.25,0.14-0.45,0.35-0.59,0.59h-0.02v-2.85h-0.83l0,6.69h0.83
     v-2.75c-0.01-0.14,0.01-0.27,0.05-0.4c0.16-0.48,0.6-0.81,1.11-0.82c0.78,0,1.06,0.61,1.06,1.35v2.62h0.83v-2.71
     C119.44,41.72,118.46,41.34,117.85,41.34z"/>
   <path fill="#E50019" d="M124.48,41.34c-0.06,0-0.13,0.01-0.19,0.01c-1.21,0.13-2.08,1.22-1.95,2.44c-0.01,0.1-0.01,0.21,0,0.31
     c0.07,1.17,1.07,2.06,2.25,2c0.52,0.01,1.04-0.09,1.52-0.29l-0.15-0.59c-0.4,0.17-0.83,0.25-1.26,0.24
     c-0.08,0.01-0.16,0.01-0.24,0c-0.8-0.07-1.4-0.78-1.32-1.58l3.2,0c0.02-0.13,0.03-0.26,0.03-0.38c0.01-0.07,0.01-0.13,0.01-0.2
     C126.39,42.22,125.54,41.35,124.48,41.34z M123.15,43.27c0.01-0.71,0.56-1.29,1.27-1.34c0.05,0,0.1,0,0.14,0.01
     c0.64,0.09,1.09,0.68,1,1.33H123.15z"/>
   <path fill="#E50019" d="M124.34,46.75c-0.27,0.01-0.48,0.22-0.48,0.49c0,0.01,0,0.01,0,0.02c0,0.26,0.21,0.47,0.47,0.46
     c0.01,0,0.02,0,0.03,0c0.25-0.01,0.45-0.22,0.44-0.48v-0.01c0,0,0,0,0-0.01C124.81,46.97,124.6,46.75,124.34,46.75z"/>
   <polygon fill="#E50019" points="124.33,39.93 124.35,39.93 124.89,40.82 125.52,40.82 124.62,39.47 124.05,39.47 123.16,40.82
     123.78,40.82 		"/>
   <polygon fill="#E50019" points="132.07,40.34 134,40.34 134.01,45.99 134.83,45.99 134.83,40.34 136.77,40.34 136.77,39.65
     132.07,39.65 		"/>
   <path fill="#E50019" d="M141.56,41.34c-0.3,0-0.59,0.08-0.85,0.23c-0.25,0.14-0.45,0.34-0.6,0.59h-0.02v-2.85h-0.83V46h0.83v-2.75
     c-0.01-0.14,0.01-0.27,0.05-0.4c0.16-0.48,0.61-0.81,1.11-0.82c0.78,0,1.05,0.61,1.05,1.35v2.62h0.83v-2.71
     C143.15,41.72,142.17,41.34,141.56,41.34z"/>
   <path fill="#E50019" d="M148.19,41.34c-0.06,0-0.13,0.01-0.19,0.01c-1.21,0.13-2.08,1.22-1.95,2.44c-0.01,0.1-0.01,0.21,0,0.31
     c0.07,1.17,1.08,2.06,2.25,1.99c0.52,0.01,1.04-0.09,1.53-0.29l-0.15-0.59c-0.4,0.17-0.83,0.25-1.26,0.24
     c-0.08,0.01-0.16,0.01-0.24,0c-0.8-0.07-1.39-0.78-1.32-1.58l3.2,0c0.02-0.13,0.03-0.26,0.03-0.38c0.01-0.07,0.01-0.13,0.01-0.2
     C150.1,42.22,149.25,41.35,148.19,41.34z M146.85,43.27c0.01-0.71,0.56-1.29,1.27-1.34c0.05,0,0.1,0,0.15,0.01
     c0.64,0.09,1.09,0.68,1,1.33H146.85z"/>
   <polygon fill="#E50019" points="148.15,39.93 148.17,39.93 148.71,40.82 149.34,40.82 148.44,39.47 147.87,39.47 146.98,40.82
     147.6,40.82 		"/>
   <path fill="#E50019" d="M157.99,41.34c-0.36-0.01-0.72,0.1-1,0.32c-0.21,0.16-0.39,0.36-0.52,0.59h-0.02
     c-0.19-0.55-0.7-0.92-1.28-0.91c-0.6-0.02-1.16,0.31-1.44,0.84h-0.03l-0.04-0.74h-0.72c0.02,0.38,0.04,0.75,0.04,1.23l0,3.32h0.81
     v-2.74c0-0.14,0.02-0.27,0.06-0.4c0.13-0.47,0.55-0.8,1.03-0.83c0.63,0,0.95,0.52,0.95,1.24v2.73h0.81v-2.82
     c0-0.14,0.02-0.28,0.07-0.41c0.14-0.43,0.54-0.73,0.99-0.75c0.66,0,0.99,0.52,0.99,1.41v2.57h0.81v-2.67
     C159.5,41.74,158.6,41.34,157.99,41.34z"/>
   <path fill="#E50019" d="M168.91,42.78c-0.29,0.83-0.54,1.63-0.72,2.36h-0.02c-0.18-0.74-0.41-1.52-0.68-2.37l-0.99-3.13h-0.89
     l2.08,6.35h0.9l2.27-6.35h-0.88L168.91,42.78z"/>
   <path fill="#E50019" d="M177.15,40.52c0.1,0.16,0.16,0.35,0.17,0.54c0,0.24-0.12,0.38-0.44,0.38h-0.66v2.79
     c0,0.14-0.02,0.29-0.08,0.42c-0.18,0.45-0.61,0.75-1.09,0.76c-0.75,0-1.02-0.59-1.02-1.45v-2.52h-0.83v2.66c0,1.6,0.86,2,1.57,2
     c0.62,0.01,1.2-0.31,1.51-0.85h0.02l0.05,0.74h0.73c-0.03-0.36-0.04-0.77-0.04-1.24v-2.86h0.1c0.01,0,0.03,0,0.04,0
     c0.43-0.01,0.77-0.37,0.76-0.8c0-0.24-0.06-0.47-0.17-0.68L177.15,40.52z"/>
   <path fill="#E50019" d="M184.04,40.78c0.1,0.16,0.16,0.35,0.16,0.55c0,0.02,0,0.04,0,0.07c-0.02,0.19-0.19,0.33-0.39,0.31
     c-0.28-0.04-0.55-0.12-0.8-0.25c-0.25-0.08-0.52-0.12-0.78-0.12c-0.07,0-0.15,0-0.22,0.01c-1.23,0.1-2.14,1.18-2.04,2.4
     c-0.01,0.06-0.01,0.13-0.01,0.2c0.01,1.2,0.99,2.16,2.19,2.15c0.08,0,0.16,0,0.24-0.01c1.23-0.1,2.14-1.18,2.03-2.41
     c0.02-0.54-0.15-1.07-0.47-1.51c0.06,0.01,0.12,0.01,0.18,0.01c0.42-0.04,0.73-0.4,0.69-0.82c0-0.24-0.05-0.47-0.16-0.69
     L184.04,40.78z M182.2,45.47c-0.81,0-1.37-0.75-1.37-1.75c0-0.86,0.42-1.76,1.39-1.76c0.99-0.01,1.38,0.96,1.38,1.73
     C183.58,44.73,182.99,45.47,182.2,45.47z"/>
   <path fill="#E50019" d="M182.21,46.74C182.21,46.74,182.21,46.74,182.21,46.74c-0.27,0-0.48,0.22-0.48,0.49c0,0,0,0,0,0
     c0,0.26,0.21,0.48,0.47,0.48c0,0,0.01,0,0.01,0c0.26-0.01,0.46-0.22,0.46-0.48c0-0.01,0-0.01,0-0.02
     C182.68,46.96,182.47,46.75,182.21,46.74z"/>
   <path fill="#E50019" d="M188.39,40.34l-0.81,0.25v0.85h-0.71v0.63h0.71v2.49c-0.04,0.43,0.07,0.85,0.32,1.2
     c0.24,0.23,0.56,0.36,0.9,0.35c0.25,0,0.5-0.03,0.73-0.11l-0.04-0.63c-0.16,0.04-0.32,0.06-0.48,0.06c-0.46,0-0.62-0.32-0.62-0.89
     v-2.46h1.18v-0.63h-1.18V40.34z"/>
   <polygon fill="#E50019" points="195.19,40.34 197.12,40.34 197.13,45.99 197.95,45.99 197.95,40.34 199.89,40.34 199.89,39.65
     195.19,39.65 		"/>
   <path fill="#E50019" d="M202.79,42.33h-0.03l-0.04-0.9h-0.72c0.03,0.42,0.04,0.88,0.04,1.42l0,3.13h0.83v-2.43
     c0-0.13,0.01-0.26,0.03-0.39c0.05-0.58,0.53-1.03,1.11-1.05c0.1,0,0.19,0,0.28,0.02v-0.78c-0.08-0.01-0.15-0.02-0.23-0.02
     C203.46,41.36,202.95,41.76,202.79,42.33z"/>
   <polygon fill="#E50019" points="208.77,39.93 208.79,39.93 209.34,40.81 209.97,40.81 209.07,39.47 208.49,39.47 207.6,40.81
     208.22,40.81 		"/>
   <path fill="#E50019" d="M209.25,47.22c0.01-0.26-0.2-0.47-0.46-0.48c-0.27,0.01-0.48,0.22-0.48,0.49c0,0,0,0.01,0,0.01
     c0,0.26,0.21,0.47,0.47,0.47c0.01,0,0.02,0,0.02,0c0.26-0.01,0.46-0.22,0.45-0.48l0,0C209.25,47.23,209.25,47.23,209.25,47.22z"/>
   <path fill="#E50019" d="M208.83,41.33c-0.08,0-0.15,0-0.23,0.01c-1.23,0.1-2.14,1.18-2.04,2.41c-0.01,0.06-0.01,0.13-0.01,0.19
     c0.01,1.2,0.99,2.16,2.19,2.15c0.08,0,0.16,0,0.24-0.01c1.23-0.1,2.14-1.18,2.03-2.41c0.01-0.08,0.01-0.16,0.01-0.23
     C211.01,42.25,210.02,41.3,208.83,41.33z M208.79,45.47c-0.81,0-1.38-0.75-1.38-1.75h0c0-0.87,0.42-1.77,1.39-1.77
     s1.36,0.98,1.36,1.75C210.18,44.73,209.58,45.47,208.79,45.47z"/>
   <path fill="#E50019" d="M214.37,39.63c-0.01,0-0.02,0-0.03,0c-0.28,0.01-0.5,0.24-0.49,0.52c0,0.01,0,0.02,0,0.03
     c0.01,0.27,0.23,0.48,0.5,0.48c0.01,0,0.03,0,0.04,0c0.28-0.01,0.49-0.23,0.49-0.51c0-0.01,0-0.02,0-0.03
     C214.87,39.85,214.64,39.63,214.37,39.63z"/>
   <rect x="213.95" y="41.43" fill="#E50019" width="0.83" height="4.56"/>
 </g>
</g>
</svg>
`]
