import { createAction } from "redux-actions";
export const RECYCLEBIN = {
    GETALL: "RECYCLEBIN_GETALL",
    GETALL_SUCCESS: "RECYCLEBIN_GETALL_SUCCESS",
    GETALL_FAILURE: "RECYCLEBIN_GETALL_FAILURE",
    DELETE: "RECYCLEBIN_DELETE",
    DELETE_SUCCESS: "RECYCLEBIN_DELETE_SUCCESS",
    DELETE_FAILURE: "RECYCLEBIN_DELETE_FAILURE",
    RESTORE_RECORD: "RECYCLEBIN_RESTORE_RECORD",
    RESTORE_RECORD_SUCCESS: "RECYCLEBIN_RESTORE_RECORD_SUCCESS",
    RESTORE_RECORD_FAILURE: "RECYCLEBIN_RESTORE_RECORD_FAILURE",
};
export const getAllRecycleBinAction = createAction(RECYCLEBIN.GETALL);
export const getAllRecycleBinSuccessAction = createAction(RECYCLEBIN.GETALL_SUCCESS);
export const getAllRecycleBinFailureAction = createAction(RECYCLEBIN.GETALL_FAILURE);
export const deleteRecycleBinAction = createAction(RECYCLEBIN.DELETE);
export const deleteRecycleBinSuccessAction = createAction(RECYCLEBIN.DELETE_SUCCESS);
export const deleteRecycleBinFailureAction = createAction(RECYCLEBIN.DELETE_FAILURE);
export const restoreRecordRecycleBinAction = createAction(RECYCLEBIN.RESTORE_RECORD);
export const restoreRecordRecycleBinSuccessAction = createAction(
  RECYCLEBIN.RESTORE_RECORD_SUCCESS
);
export const restoreRecordRecycleBinFailureAction = createAction(RECYCLEBIN.RESTORE_RECORD_FAILURE);
