import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { BUILDINGCLASS } from './buildingClassAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllBuildingClassSuccessAction,
    getAllBuildingClassFailureAction,
    getByIdBuildingClassSuccessAction,
    getByIdBuildingClassFailureAction,
    changeStatusBuildingClassSuccessAction,
    changeStatusBuildingClassFailureAction,
    deleteBuildingClassSuccessAction,
    deleteBuildingClassFailureAction,
    addBuildingClassSuccessAction,
    addBuildingClassFailureAction,
    editBuildingClassSuccessAction,
    editBuildingClassFailureAction,
} from './buildingClassAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/BuildingClass/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/BuildingClass/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/BuildingClass/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/BuildingClass/Delete/';
const API_POST = config.HOST + config.VERSION + '/BuildingClass/Create';
const API_UPSERT = config.HOST + config.VERSION + '/BuildingClass/Update';

const getAllBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllBuildingClassFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdBuildingClassFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusBuildingClassFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteBuildingClassFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addBuildingClassFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editBuildingClassEpic = (action$, store) => action$.pipe(
    ofType(BUILDINGCLASS.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editBuildingClassSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editBuildingClassFailureAction(error));
                    }
                })
            );
    })
);


export { getAllBuildingClassEpic, getByIdBuildingClassEpic, changeStatusBuildingClassEpic, deleteBuildingClassEpic, addBuildingClassEpic, editBuildingClassEpic };