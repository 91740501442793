import { createAction } from "redux-actions";
export const DISTRICT = {
    GETALL: "DISTRICT_GETALL",
    GETALL_SUCCESS: "DISTRICT_GETALL_SUCCESS",
    GETALL_FAILURE: "DISTRICT_GETALL_FAILURE",
    
    GETALL_FULL: "DISTRICT_GETALL_FULL",
    GETALL_FULL_SUCCESS: "DISTRICT_GETALL_FULL_SUCCESS",
    GETALL_FULL_FAILURE: "DISTRICT_GETALL_FULL_FAILURE",

    GETBYID: "DISTRICT_GETBYID",
    GETBYID_SUCCESS: "DISTRICT_GETBYID_SUCCESS",
    GETBYID_FAILURE: "DISTRICT_GETBYID_FAILURE",
    CHANGE_STATUS: "DISTRICT_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "DISTRICT_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "DISTRICT_CHANGE_STATUS_FAILURE",
    DELETE: "DISTRICT_DELETE",
    DELETE_SUCCESS: "DISTRICT_DELETE_SUCCESS",
    DELETE_FAILURE: "DISTRICT_DELETE_FAILURE",
    ADD: "DISTRICT_ADD",
    ADD_SUCCESS: "DISTRICT_ADD_SUCCESS",
    ADD_FAILURE: "DISTRICT_ADD_FAILURE",
    EDIT: "DISTRICT_EDIT",
    EDIT_SUCCESS: "DISTRICT_EDIT_SUCCESS",
    EDIT_FAILURE: "DISTRICT_EDIT_FAILURE",
    CREATE_RESET: 'DISTRICT_CREATE_RESET',
    GETBY_PROVINCE:'DISTRICT_GETBY_PROVINCE_CODE',
    GETBY_PROVINCE_SUCCESS:'DISTRICT_GETBY_PROVINCE_SUCCESS',
    GETBY_PROVINCE_FAILURE:'DISTRICT_GETBY_PROVINCE_FAILURE'
};
export const getAllDistrictAction = createAction(DISTRICT.GETALL);
export const getAllDistrictSuccessAction = createAction(DISTRICT.GETALL_SUCCESS);
export const getAllDistrictFailureAction = createAction(DISTRICT.GETALL_FAILURE);

export const getAllFullDistrictAction = createAction(DISTRICT.GETALL_FULL);
export const getAllFullDistrictSuccessAction = createAction(DISTRICT.GETALL_FULL_SUCCESS);
export const getAllFullDistrictFailureAction = createAction(DISTRICT.GETALL_FULL_FAILURE);

export const getByIdDistrictAction = createAction(DISTRICT.GETBYID);
export const getByIdDistrictSuccessAction = createAction(DISTRICT.GETBYID_SUCCESS);
export const getByIdDistrictFailureAction = createAction(DISTRICT.GETBYID_FAILURE);
export const changeStatusDistrictAction = createAction(DISTRICT.CHANGE_STATUS);
export const changeStatusDistrictSuccessAction = createAction(DISTRICT.CHANGE_STATUS_SUCCESS);
export const changeStatusDistrictFailureAction = createAction(DISTRICT.CHANGE_STATUS_FAILURE);
export const deleteDistrictAction = createAction(DISTRICT.DELETE);
export const deleteDistrictSuccessAction = createAction(DISTRICT.DELETE_SUCCESS);
export const deleteDistrictFailureAction = createAction(DISTRICT.DELETE_FAILURE);
export const addDistrictAction = createAction(DISTRICT.ADD);
export const addDistrictSuccessAction = createAction(DISTRICT.ADD_SUCCESS);
export const addDistrictFailureAction = createAction(DISTRICT.ADD_FAILURE);
export const editDistrictAction = createAction(DISTRICT.EDIT);
export const editDistrictSuccessAction = createAction(DISTRICT.EDIT_SUCCESS);
export const editDistrictFailureAction = createAction(DISTRICT.EDIT_FAILURE);
export const createResetDistrictAction = createAction(DISTRICT.CREATE_RESET);
export const getByProvinceCodeAction = createAction(DISTRICT.GETBY_PROVINCE)
export const getByProvinceCodeSuccessAction = createAction(DISTRICT.GETBY_PROVINCE_SUCCESS)
export const getByProvinceCodeFailureAction = createAction(DISTRICT.GETBY_PROVINCE_FAILURE)