import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FEEDBACK } from './feedbackAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllFeedbackSuccessAction,
    getAllFeedbackFailureAction,
    getByIdFeedbackSuccessAction,
    getByIdFeedbackFailureAction,
    changeStatusFeedbackSuccessAction,
    changeStatusFeedbackFailureAction,
    deleteFeedbackSuccessAction,
    deleteFeedbackFailureAction,
    addFeedbackSuccessAction,
    addFeedbackFailureAction,
    editFeedbackSuccessAction,
    editFeedbackFailureAction,
} from './feedbackAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsFeedback/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/CmsFeedback/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsFeedback/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsFeedback/Delete/';
const API_POST = config.HOST + config.VERSION + '/CmsFeedback/Create';
const API_UPSERT = config.HOST + config.VERSION + '/CmsFeedback/Update';

const getAllFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllFeedbackFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdFeedbackFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusFeedbackFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteFeedbackFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addFeedbackFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editFeedbackEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACK.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editFeedbackSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editFeedbackFailureAction(error));
                    }
                })
            );
    })
);

export { getAllFeedbackEpic, getByIdFeedbackEpic, changeStatusFeedbackEpic, deleteFeedbackEpic, addFeedbackEpic, editFeedbackEpic };