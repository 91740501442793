import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROVINCIAL } from './provincialAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllProvincialSuccessAction,
    getAllProvincialFailureAction,
    getByIdProvincialSuccessAction,
    getByIdProvincialFailureAction,
    changeStatusProvincialSuccessAction,
    changeStatusProvincialFailureAction,
    deleteProvincialSuccessAction,
    deleteProvincialFailureAction,
    addProvincialSuccessAction,
    addProvincialFailureAction,
    editProvincialSuccessAction,
    editProvincialFailureAction,
} from './provincialAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/LctProvince/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/LctProvince/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/LctProvince/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/LctProvince/Delete/';
const API_POST = config.HOST + config.VERSION + '/LctProvince/Create';
const API_UPSERT = config.HOST + config.VERSION + '/LctProvince/Update';
// GETALL
const getAllProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllProvincialFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdProvincialFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusProvincialFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteProvincialFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addProvincialFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editProvincialEpic = (action$, store) => action$.pipe(
    ofType(PROVINCIAL.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editProvincialSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editProvincialFailureAction(error));
                    }
                })
            );
    })
);

export { getAllProvincialEpic, getByIdProvincialEpic, changeStatusProvincialEpic, deleteProvincialEpic, addProvincialEpic, editProvincialEpic };