import { handleActions } from "redux-actions";
import { UPLOAD_THUMBNAIL_MULTIPLE } from "../uploadThumbnailMultipleAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [UPLOAD_THUMBNAIL_MULTIPLE.RESET_THUMBNAIL_MULTIPLE]: (state, { payload: responseData }) => {
    state = initialState;
    state.isReset = responseData;
    return state;
  } 
};
export default handleActions(actions, initialState);