import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SIGNUP_SEE_HOUSE } from './signupseehouseAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllSignUpSeeHouseSuccessAction,
    getAllSignUpSeeHouseFailureAction,
    changeStatusSignUpSeeHouseSuccessAction,
    changeStatusSignUpSeeHouseFailureAction,
    deleteSignUpSeeHouseSuccessAction,
    deleteSignUpSeeHouseFailureAction,
} from './signupseehouseAction';
import config from 'common/config';
const API_GETALL = config.HOST + config.VERSION + '/PropertySignupSeeHouse/GetAll?pageSize=100&pageNumber=1';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/PropertySignupSeeHouse/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/PropertySignupSeeHouse/Delete/';

const getAllSignUpSeeHouseEpic = (action$) => action$.pipe(
    ofType(SIGNUP_SEE_HOUSE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllSignUpSeeHouseSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllSignUpSeeHouseFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusSignUpSeeHouseEpic = (action$) => action$.pipe(
    ofType(SIGNUP_SEE_HOUSE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusSignUpSeeHouseSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusSignUpSeeHouseFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteSignUpSeeHouseEpic = (action$) => action$.pipe(
    ofType(SIGNUP_SEE_HOUSE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteSignUpSeeHouseSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteSignUpSeeHouseFailureAction(error));
                    }
                })
            );
    })
);

export { getAllSignUpSeeHouseEpic, changeStatusSignUpSeeHouseEpic, deleteSignUpSeeHouseEpic};