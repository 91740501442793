import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GLOBAL_CONFIG } from './globalConfigAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getConfigsSuccessAction,
    getConfigsFailureAction,
    getConfigByIdSuccessAction,
    getConfigByIdFailureAction,
    changeStatusConfigSuccessAction,
    changeStatusConfigFailureAction,
    deleteConfigSuccessAction,
    deleteConfigFailureAction,
    createConfigSuccessAction,
    createConfigFailureAction,
    updateConfigSuccessAction,
    updateConfigFailureAction
} from './globalConfigAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Config/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/Config/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Config/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Config/Delete/';
const API_ADD = config.HOST + config.VERSION + '/Config/Create';
const API_EDIT = config.HOST + config.VERSION + '/Config/Update';

// GETALL
const getConfigsEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getConfigsSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getConfigsFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getConfigByIdEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getConfigByIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getConfigByIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusConfigEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusConfigSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusConfigFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteConfigEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteConfigSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteConfigFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createConfigEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createConfigSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(createConfigFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateConfigEpic = (action$, store) => action$.pipe(
    ofType(GLOBAL_CONFIG.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateConfigSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateConfigFailureAction(error));
                    }
                })
            );
    })
);

export { getConfigsEpic, changeStatusConfigEpic, deleteConfigEpic, createConfigEpic, updateConfigEpic, getConfigByIdEpic };