import { createAction } from "redux-actions";
export const CMSCAROUSEL = {
    GETALL: "CMSCAROUSEL_GETALL",
    GETALL_SUCCESS: "CMSCAROUSEL_GETALL_SUCCESS",
    GETALL_FAILURE: "CMSCAROUSEL_GETALL_FAILURE",
    GETBYID: "CMSCAROUSEL_GETBYID",
    GETBYID_SUCCESS: "CMSCAROUSEL_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CMSCAROUSEL_GETBYID_FAILURE",
    CHANGE_STATUS: "CMSCAROUSEL_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CMSCAROUSEL_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CMSCAROUSEL_CHANGE_STATUS_FAILURE",
    DELETE: "CMSCAROUSEL_DELETE",
    DELETE_SUCCESS: "CMSCAROUSEL_DELETE_SUCCESS",
    DELETE_FAILURE: "CMSCAROUSEL_DELETE_FAILURE",
    CREATE: "CMSCAROUSEL_CREATE",
    CREATE_SUCCESS: "CMSCAROUSEL_CREATE_SUCCESS",
    CREATE_FAILURE: "CMSCAROUSEL_CREATE_FAILURE",
    UPDATE: "CMSCAROUSEL_UPDATE",
    UPDATE_SUCCESS: "CMSCAROUSEL_UPDATE_SUCCESS",
    UPDATE_FAILURE: "CMSCAROUSEL_UPDATE_FAILURE",
    REMOVE: "CMSCAROUSEL_REMOVE",
    REMOVE_SUCCESS: "CMSCAROUSEL_REMOVE_SUCCESS",
    REMOVE_FAILURE: "CMSCAROUSEL_REMOVE_FAILURE",
    CREATE_RESET: 'CMSCAROUSEL_CREATE_RESET',
    DELETE_IMAGE: 'CMSCAROUSEL_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'CMSCAROUSEL_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'CMSCAROUSEL_DELETE_IMAGE_FAILURE',
    CKEDITOR: 'CKEDITOR',
    CKEDITOR_NEW: 'CKEDITOR_NEW',
};
export const getAllCmsCarouselsAction = createAction(CMSCAROUSEL.GETALL);
export const getAllCmsCarouselsSuccessAction = createAction(CMSCAROUSEL.GETALL_SUCCESS);
export const getAllCmsCarouselsFailureAction = createAction(CMSCAROUSEL.GETALL_FAILURE);
export const getByIdCmsCarouselAction = createAction(CMSCAROUSEL.GETBYID);
export const getByIdCmsCarouselSuccessAction = createAction(CMSCAROUSEL.GETBYID_SUCCESS);
export const getByIdCmsCarouselFailureAction = createAction(CMSCAROUSEL.GETBYID_FAILURE);
export const changeStatusCmsCarouselAction = createAction(CMSCAROUSEL.CHANGE_STATUS);
export const changeStatusCmsCarouselSuccessAction = createAction(CMSCAROUSEL.CHANGE_STATUS_SUCCESS);
export const changeStatusCmsCarouselFailureAction = createAction(CMSCAROUSEL.CHANGE_STATUS_FAILURE);
export const deleteCmsCarouselAction = createAction(CMSCAROUSEL.DELETE);
export const deleteCmsCarouselSuccessAction = createAction(CMSCAROUSEL.DELETE_SUCCESS);
export const deleteCmsCarouselFailureAction = createAction(CMSCAROUSEL.DELETE_FAILURE);
export const createCmsCarouselAction = createAction(CMSCAROUSEL.CREATE);
export const createCmsCarouselSuccessAction = createAction(CMSCAROUSEL.CREATE_SUCCESS);
export const createCmsCarouselFailureAction = createAction(CMSCAROUSEL.CREATE_FAILURE);
export const updateCmsCarouselAction = createAction(CMSCAROUSEL.UPDATE);
export const updateCmsCarouselSuccessAction = createAction(CMSCAROUSEL.UPDATE_SUCCESS);
export const updateCmsCarouselFailureAction = createAction(CMSCAROUSEL.UPDATE_FAILURE);
export const removeCmsCarouselAction = createAction(CMSCAROUSEL.REMOVE);
export const removeCmsCarouselSuccessAction = createAction(CMSCAROUSEL.REMOVE_SUCCESS);
export const removeCmsCarouselFailureAction = createAction(CMSCAROUSEL.REMOVE_FAILURE);
export const createResetCmsCarouselAction = createAction(CMSCAROUSEL.CREATE_RESET);
export const deleteImageCmsCarouselAction = createAction(CMSCAROUSEL.DELETE_IMAGE);
export const deleteImageCmsCarouselSuccessAction = createAction(CMSCAROUSEL.DELETE_IMAGE_SUCCESS);
export const deleteImageCmsCarouselFailureAction = createAction(CMSCAROUSEL.DELETE_IMAGE_FAILURE);
export const ckEditorCmsCarouselAction = createAction(CMSCAROUSEL.CKEDITOR);
export const ckEditorNewCmsCarouselAction = createAction(CMSCAROUSEL.CKEDITOR_NEW);

