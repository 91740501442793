import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LANGUAGE } from './languageAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getLanguagesSuccessAction,
    getLanguagesFailureAction,
    getLanguageByIdSuccessAction,
    getLanguageByIdFailureAction,
    changeStatusLanguageSuccessAction,
    changeStatusLanguageFailureAction,
    deleteLanguageSuccessAction,
    deleteLanguageFailureAction,
    createLanguageSuccessAction,
    createLanguageFailureAction,
    updateLanguageSuccessAction,
    updateLanguageFailureAction
} from './languageAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Languages';
const API_GETBYID = config.HOST + config.VERSION + '/Language/';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Language/{id}/Status';
const API_DELETE = config.HOST + config.VERSION + '/Language/';
const API_ADD = config.HOST + config.VERSION + '/Language';
const API_EDIT = config.HOST + config.VERSION + '/Language';

// GETALL
const getLanguagesEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getLanguagesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getLanguagesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getLanguageByIdEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getLanguageByIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getLanguageByIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusLanguageEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS.replace('{id}', action.payload), null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusLanguageSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusLanguageFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteLanguageEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.DELETE),
    mergeMap((action) => {
        return defer(() => {
          return ajax.delete(API_DELETE + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => deleteLanguageSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteLanguageFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createLanguageEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createLanguageSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(createLanguageFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateLanguageEpic = (action$, store) => action$.pipe(
    ofType(LANGUAGE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateLanguageSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateLanguageFailureAction(error));
                    }
                })
            );
    })
);

export { getLanguagesEpic, changeStatusLanguageEpic, deleteLanguageEpic, createLanguageEpic, updateLanguageEpic, getLanguageByIdEpic };