import { createAction } from "redux-actions";
export const CHART = {
    GETBY_PROVINCEL_YEAR: "GETBY_PROVINCEL_YEAR",
    GETBY_PROVINCEL_YEAR_SUCCESS: "GETBY_PROVINCEL_YEAR_SUCCESS",
    GETBY_PROVINCEL_YEAR_FAILURE: "GETBY_PROVINCEL_YEAR_FAILURE",
};
export const getByYearProvincelAccessOfChartAction = createAction(CHART.GETBY_PROVINCEL_YEAR);
export const getByYearProvincelAccessOfChartSuccessAction = createAction(CHART.GETBY_PROVINCEL_YEAR_SUCCESS);
export const getByYearProvincelAccessOfChartFailureAction = createAction(CHART.GETBY_PROVINCEL_YEAR_FAILURE);

