import { createAction } from "redux-actions";
export const SYSTEM_API = {
    GETALL: "SYSTEM_API_GETALL",
    GETALL_SUCCESS: "SYSTEM_API_GETALL_SUCCESS",
    GETALL_FAILURE: "SYSTEM_API_GETALL_FAILURE",
    GETBYID: "SYSTEM_API_GETBYID",
    GETBYID_SUCCESS: "SYSTEM_API_GETBYID_SUCCESS",
    GETBYID_FAILURE: "SYSTEM_API_GETBYID_FAILURE",
    CHANGE_STATUS: "SYSTEM_API_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "SYSTEM_API_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "SYSTEM_API_CHANGE_STATUS_FAILURE",
    DELETE: "SYSTEM_API_DELETE",
    DELETE_SUCCESS: "SYSTEM_API_DELETE_SUCCESS",
    DELETE_FAILURE: "SYSTEM_API_DELETE_FAILURE",
    ADD: "SYSTEM_API_ADD",
    ADD_SUCCESS: "SYSTEM_API_ADD_SUCCESS",
    ADD_FAILURE: "SYSTEM_API_ADD_FAILURE",
    EDIT: "SYSTEM_API_EDIT",
    EDIT_SUCCESS: "SYSTEM_API_EDIT_SUCCESS",
    EDIT_FAILURE: "SYSTEM_API_EDIT_FAILURE",
    CREATE_RESET: 'SYSTEM_API_CREATE_RESET'
};
export const getSystemApiAction = createAction(SYSTEM_API.GETALL);
export const getSystemApiSuccessAction = createAction(SYSTEM_API.GETALL_SUCCESS);
export const getSystemApiFailureAction = createAction(SYSTEM_API.GETALL_FAILURE);
export const getSystemApiByIdAction = createAction(SYSTEM_API.GETBYID);
export const getSystemApiByIdSuccessAction = createAction(SYSTEM_API.GETBYID_SUCCESS);
export const getSystemApiByIdFailureAction = createAction(SYSTEM_API.GETBYID_FAILURE);
export const changeStatusSystemApiAction = createAction(SYSTEM_API.CHANGE_STATUS);
export const changeStatusSystemApiSuccessAction = createAction(SYSTEM_API.CHANGE_STATUS_SUCCESS);
export const changeStatusSystemApiFailureAction = createAction(SYSTEM_API.CHANGE_STATUS_FAILURE);
export const deleteSystemApiAction = createAction(SYSTEM_API.DELETE);
export const deleteSystemApiSuccessAction = createAction(SYSTEM_API.DELETE_SUCCESS);
export const deleteSystemApiFailureAction = createAction(SYSTEM_API.DELETE_FAILURE);
export const createSystemApiAction = createAction(SYSTEM_API.ADD);
export const createSystemApiSuccessAction = createAction(SYSTEM_API.ADD_SUCCESS);
export const createSystemApiFailureAction = createAction(SYSTEM_API.ADD_FAILURE);
export const updateSystemApiAction = createAction(SYSTEM_API.EDIT);
export const updateSystemApiSuccessAction = createAction(SYSTEM_API.EDIT_SUCCESS);
export const updateSystemApiFailureAction = createAction(SYSTEM_API.EDIT_FAILURE);
export const createResetAction = createAction(SYSTEM_API.CREATE_RESET);