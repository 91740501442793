import { createAction } from "redux-actions";
export const UPLOAD_VIDEO = {
    CHUNK_VIDEO: "CHUNK_VIDEO_UPLOAD",
    CHUNK_VIDEO_SUCCESS: "CHUNK_VIDEO_UPLOAD_SUCCESS",
    CHUNK_VIDEO_FAILURE: "CHUNK_VIDEO_UPLOAD_FAILURE",
    
    CREATE_VIDEO: "CREATE_VIDEO_UPLOAD",
    CREATE_VIDEO_SUCCESS: "CREATE_VIDEO_UPLOAD_SUCCESS",
    CREATE_VIDEO_FAILURE: "CREATE_VIDEO_UPLOAD_FAILURE",    

    RESET_VIDEO: "RESET",    


};
export const chunkVideoUploadAction = createAction(UPLOAD_VIDEO.CHUNK_VIDEO);
export const chunkVideoUploadSuccessAction = createAction(UPLOAD_VIDEO.CHUNK_VIDEO_SUCCESS);
export const chunkVideoUploadFailureAction = createAction(UPLOAD_VIDEO.CHUNK_VIDEO_FAILURE);

export const createVideoUploadAction = createAction(UPLOAD_VIDEO.CREATE_VIDEO);
export const createVideoUploadSuccessAction = createAction(UPLOAD_VIDEO.CREATE_VIDEO_SUCCESS);
export const createVideoUploadFailureAction = createAction(UPLOAD_VIDEO.CREATE_VIDEO_FAILURE);
export const resetVideoUploadAction = createAction(UPLOAD_VIDEO.RESET_VIDEO);