import { createAction } from "redux-actions";
export const TOKEN = {
    TOKEN: "TOKEN",
    TOKEN_SUCCESS: "TOKEN_SUCCESS",
    TOKEN_FAILURE: "TOKEN_FAILURE",
    TOKEN_RESET: "TOKEN_RESET"
};
export const tokenAction = createAction(TOKEN.TOKEN);
export const tokenSuccessAction = createAction(TOKEN.TOKEN_SUCCESS);
export const tokenFailureAction = createAction(TOKEN.TOKEN_FAILURE);
export const tokenResetAction = createAction(TOKEN.TOKEN_RESET);