import { createAction } from "redux-actions";
export const IMAGETYPE = {
    GETALL: "IMAGETYPE_GETALL",
    GETALL_SUCCESS: "IMAGETYPE_GETALL_SUCCESS",
    GETALL_FAILURE: "IMAGETYPE_GETALL_FAILURE",
    GETBYID: "IMAGETYPE_GETBYID",
    GETBYID_SUCCESS: "IMAGETYPE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "IMAGETYPE_GETBYID_FAILURE",
    CHANGE_STATUS: "IMAGETYPE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "IMAGETYPE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "IMAGETYPE_CHANGE_STATUS_FAILURE",
    DELETE: "IMAGETYPE_DELETE",
    DELETE_SUCCESS: "IMAGETYPE_DELETE_SUCCESS",
    DELETE_FAILURE: "IMAGETYPE_DELETE_FAILURE",
    ADD: "IMAGETYPE_ADD",
    ADD_SUCCESS: "IMAGETYPE_ADD_SUCCESS",
    ADD_FAILURE: "IMAGETYPE_ADD_FAILURE",
    EDIT: "IMAGETYPE_EDIT",
    EDIT_SUCCESS: "IMAGETYPE_EDIT_SUCCESS",
    EDIT_FAILURE: "IMAGETYPE_EDIT_FAILURE",
    CREATE_RESET: 'IMAGETYPE_CREATE_RESET'
};
export const getAllImageTypeAction = createAction(IMAGETYPE.GETALL);
export const getAllImageTypeSuccessAction = createAction(IMAGETYPE.GETALL_SUCCESS);
export const getAllImageTypeFailureAction = createAction(IMAGETYPE.GETALL_FAILURE);
export const getByIdImageTypeAction = createAction(IMAGETYPE.GETBYID);
export const getByIdImageTypeSuccessAction = createAction(IMAGETYPE.GETBYID_SUCCESS);
export const getByIdImageTypeFailureAction = createAction(IMAGETYPE.GETBYID_FAILURE);
export const changeStatusImageTypeAction = createAction(IMAGETYPE.CHANGE_STATUS);
export const changeStatusImageTypeSuccessAction = createAction(IMAGETYPE.CHANGE_STATUS_SUCCESS);
export const changeStatusImageTypeFailureAction = createAction(IMAGETYPE.CHANGE_STATUS_FAILURE);
export const deleteImageTypeAction = createAction(IMAGETYPE.DELETE);
export const deleteImageTypeSuccessAction = createAction(IMAGETYPE.DELETE_SUCCESS);
export const deleteImageTypeFailureAction = createAction(IMAGETYPE.DELETE_FAILURE);
export const addImageTypeAction = createAction(IMAGETYPE.ADD);
export const addImageTypeSuccessAction = createAction(IMAGETYPE.ADD_SUCCESS);
export const addImageTypeFailureAction = createAction(IMAGETYPE.ADD_FAILURE);
export const editImageTypeAction = createAction(IMAGETYPE.EDIT);
export const editImageTypeSuccessAction = createAction(IMAGETYPE.EDIT_SUCCESS);
export const editImageTypeFailureAction = createAction(IMAGETYPE.EDIT_FAILURE);
export const createResetImageTypeAction = createAction(IMAGETYPE.CREATE_RESET);
