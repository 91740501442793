import { of, defer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getCurrentProfileErrorAction, getCurrentProfileSuccessAction, LOGIN } from './loginAction';
import {
    loginSuccessAction,
    loginFailureAction,
} from './loginAction';
import config from 'common/config';
import { getNewToken } from 'services/getNewToken';

const API_LOGIN = config.HOST + config.VERSION + '/Auth/Login';
const API_ME = config.HOST + config.VERSION + '/Auth/Me';

const loginEpic = action$ => action$.pipe(
    ofType(LOGIN.LOGIN),
    mergeMap((action) => {
        return ajax.post(API_LOGIN, action.payload, config.HEADERS()).pipe(
            map(response => loginSuccessAction(response)),
            catchError(error => of(loginFailureAction({
                message: error.xhr.response, status: error.xhr.status
            })))
        )
    })
);

// GET CURRENT PROFILE
const getCurrentProfileEpic = (action$, store) => action$.pipe(
  ofType(LOGIN.CURRENT_PROFILE),
  mergeMap((action) => {
      return defer(() => {
          return ajax.getJSON(API_ME, config.HEADERS());
      })
          .pipe(
              map((response) => getCurrentProfileSuccessAction(response)),
              catchError((error, source) => {
                  if (error.status === 401) {
                      return getNewToken(action$, source);
                  } else {
                      return of(getCurrentProfileErrorAction(error));
                  }
              })
          );
  })
);

export { loginEpic, getCurrentProfileEpic };