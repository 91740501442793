import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FILE } from './fileAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllFilesSuccessAction,
    getAllFilesFailureAction,
    getByIdFileSuccessAction,
    getByIdFileFailureAction,
    changeStatusFileSuccessAction,
    changeStatusFileFailureAction,
    deleteFileSuccessAction,
    deleteFileFailureAction,
    addFileSuccessAction,
    addFileFailureAction,
    editFileSuccessAction,
    editFileFailureAction
} from './fileAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/File/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/File/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/File/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/File/Remove/';
const API_ADD = config.HOST + config.VERSION + '/File/Create';
const API_EDIT = config.HOST + config.VERSION + '/File/Update';

// GETALL
const getAllFileEpic = (action$, store) => action$.pipe(
    ofType(FILE.GETALL),
    mergeMap((action) => {
        return defer(() => {
          return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllFilesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllFilesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdFileEpic = (action$, store) => action$.pipe(
    ofType(FILE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdFileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdFileFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusFileEpic = (action$, store) => action$.pipe(
    ofType(FILE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusFileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusFileFailureAction(error));
                    }
                })
            );
    })
);

const deleteFileEpic = (action$, store) => action$.pipe(
  ofType(FILE.DELETE),
  mergeMap((action) => {
      return defer(() => {
        return ajax.delete(API_DELETE + action.payload, config.HEADERS());
      })
          .pipe(
              map((response) => deleteFileSuccessAction(response)),
              catchError((error, source) => {
                  if (error.status === 401) {
                      return getNewToken(action$, source);
                  } else {
                      return of(deleteFileFailureAction(error));
                  }
              })
          );
  })
);

// ADD
const addFileEpic = (action$, store) => action$.pipe(
    ofType(FILE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addFileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addFileFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editFileEpic = (action$, store) => action$.pipe(
    ofType(FILE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editFileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editFileFailureAction(error));
                    }
                })
            );
    })
);

export { getAllFileEpic, changeStatusFileEpic, deleteFileEpic, addFileEpic, editFileEpic, getByIdFileEpic };