import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FEEDBACKBYUSER } from './feedbackByUserAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllFeedbackByUserSuccessAction,
    getAllFeedbackByUserFailureAction,
    getByIdFeedbackByUserSuccessAction,
    getByIdFeedbackByUserFailureAction,
    changeStatusFeedbackByUserSuccessAction,
    changeStatusFeedbackByUserFailureAction,
    deleteFeedbackByUserSuccessAction,
    deleteFeedbackByUserFailureAction,
    addFeedbackByUserSuccessAction,
    addFeedbackByUserFailureAction,
    editFeedbackByUserSuccessAction,
    editFeedbackByUserFailureAction,
} from './feedbackByUserAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsFeedbackByUser/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/CmsFeedbackByUser/GetAllByFeedbackId?feedbackId=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsFeedbackByUser/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsFeedbackByUser/Delete/';
const API_POST = config.HOST + config.VERSION + '/CmsFeedbackByUser/Create';
const API_UPSERT = config.HOST + config.VERSION + '/CmsFeedbackByUser/Update';
    
const getAllFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editFeedbackByUserEpic = (action$, store) => action$.pipe(
    ofType(FEEDBACKBYUSER.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editFeedbackByUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editFeedbackByUserFailureAction(error));
                    }
                })
            );
    })
);


export { getAllFeedbackByUserEpic, getByIdFeedbackByUserEpic, changeStatusFeedbackByUserEpic, deleteFeedbackByUserEpic, addFeedbackByUserEpic, editFeedbackByUserEpic };