import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ACCESS } from './accessAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllAccessSuccessAction,
    getAllAccessFailureAction,
    getByIdAccessSuccessAction,
    getByIdAccessFailureAction,
    changeStatusAccessSuccessAction,
    changeStatusAccessFailureAction,
    deleteAccessSuccessAction,
    deleteAccessFailureAction,
    addAccessSuccessAction,
    addAccessFailureAction,
    editAccessSuccessAction,
    editAccessFailureAction,
    getByPageNumberAccessSuccessAction,
    getByPageNumberAccessFailureAction
} from './accessAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsStatisticalAccess/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/CmsStatisticalAccess/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsStatisticalAccess/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsStatisticalAccess/Delete/';
const API_POST = config.HOST + config.VERSION + '/CmsStatisticalAccess/Create';
const API_UPSERT = config.HOST + config.VERSION + '/CmsStatisticalAccess/Update';
const API_GET_BY_PAGE_NUMBER = config.HOST + config.VERSION + '/CmsStatisticalAccess/GetAll?pageSize=10&pageNumber=';
const getAllAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllAccessFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdAccessFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusAccessFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteAccessFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addAccessFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editAccessFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const getByPageNumberAccessEpic = (action$, store) => action$.pipe(
    ofType(ACCESS.GET_BY_PAGE_NUMBER),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GET_BY_PAGE_NUMBER + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByPageNumberAccessSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByPageNumberAccessFailureAction(error));
                    }
                })
            );
    })
);


export { getAllAccessEpic, getByIdAccessEpic, changeStatusAccessEpic, deleteAccessEpic, addAccessEpic, editAccessEpic, getByPageNumberAccessEpic};