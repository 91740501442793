import { createAction } from "redux-actions";

export const UPLOAD = {
    UPLOAD_IMAGE_BASE64: "UPLOAD_IMAGE_BASE64",
    UPLOAD_IMAGE_BASE64_SUCCESS: "UPLOAD_IMAGE_BASE64_SUCCESS",
    UPLOAD_IMAGE_BASE64_FAILURE: "UPLOAD_IMAGE_BASE64_FAILURE",
    RESET: "RESET",

    DELETE_IMAGE_BASE64: "DELETE_IMAGE_BASE64",
    DELETE_IMAGE_BASE64_SUCCESS: "DELETE_IMAGE_BASE64_SUCCESS",
    DELETE_IMAGE_BASE64_FAILURE: "DELETE_IMAGE_BASE64_FAILURE",
    
};

export const uploadImageBase64Action = createAction(UPLOAD.UPLOAD_IMAGE_BASE64);
export const uploadImageBase64SuccessAction = createAction(UPLOAD.UPLOAD_IMAGE_BASE64_SUCCESS);
export const uploadImageBase64FailureAction = createAction(UPLOAD.UPLOAD_IMAGE_BASE64_FAILURE);
export const uploadResetAction = createAction(UPLOAD.RESET);

export const deleteImageBase64Action = createAction(UPLOAD.DELETE_IMAGE_BASE64);
export const deleteImageBase64SuccessAction = createAction(UPLOAD.DELETE_IMAGE_BASE64_SUCCESS);
export const deleteImageBase64FailureAction = createAction(UPLOAD.DELETE_IMAGE_BASE64_FAILURE);


