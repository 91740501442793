import { createAction } from "redux-actions";
export const CMSCATEGORY = {
    GETALL: "CMSCATEGORY_GETALL",
    GETALL_SUCCESS: "CMSCATEGORY_GETALL_SUCCESS",
    GETALL_FAILURE: "CMSCATEGORY_GETALL_FAILURE",
    GETBYID: "CMSCATEGORY_GETBYID",
    GETBYID_SUCCESS: "CMSCATEGORY_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CMSCATEGORY_GETBYID_FAILURE",
    CHANGE_STATUS: "CMSCATEGORY_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CMSCATEGORY_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CMSCATEGORY_CHANGE_STATUS_FAILURE",
    DELETE: "CMSCATEGORY_DELETE",
    DELETE_SUCCESS: "CMSCATEGORY_DELETE_SUCCESS",
    DELETE_FAILURE: "CMSCATEGORY_DELETE_FAILURE",
    CREATE: "CMSCATEGORY_CREATE",
    CREATE_SUCCESS: "CMSCATEGORY_CREATE_SUCCESS",
    CREATE_FAILURE: "CMSCATEGORY_CREATE_FAILURE",
    UPDATE: "CMSCATEGORY_UPDATE",
    UPDATE_SUCCESS: "CMSCATEGORY_UPDATE_SUCCESS",
    UPDATE_FAILURE: "CMSCATEGORY_UPDATE_FAILURE",
    REMOVE: "CMSCATEGORY_REMOVE",
    REMOVE_SUCCESS: "CMSCATEGORY_REMOVE_SUCCESS",
    REMOVE_FAILURE: "CMSCATEGORY_REMOVE_FAILURE",
    CREATE_RESET: 'CMSCATEGORY_CREATE_RESET',
    DELETE_IMAGE: 'CMSCATEGORY_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'CMSCATEGORY_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'CMSCATEGORY_DELETE_IMAGE_FAILURE',
    GETALL_CONTENT: "CMSCATEGORY_GETALL_CONTENT",
    GETALL_CONTENT_SUCCESS: "CMSCATEGORY_GETALL_CONTENT_SUCCESS",
    GETALL_CONTENT_FAILURE: "CMSCATEGORY_GETALL_CONTENT_FAILURE",
    GETALL_BY_CONTENT_ID: "CMSCATEGORY_GETALL_BY_CONTENT_ID",
    GETALL_BY_CONTENT_ID_SUCCESS: "CMSCATEGORY_GETALL_BY_CONTENT_ID_SUCCESS",
    GETALL_BY_CONTENT_ID_FAILURE: "CMSCATEGORY_GETALL_BY_CONTENT_ID_FAILURE",
};
export const getAllCmsCategoriesAction = createAction(CMSCATEGORY.GETALL);
export const getAllCmsCategoriesSuccessAction = createAction(CMSCATEGORY.GETALL_SUCCESS);
export const getAllCmsCategoriesFailureAction = createAction(CMSCATEGORY.GETALL_FAILURE);
export const getByIdCmsCategoryAction = createAction(CMSCATEGORY.GETBYID);
export const getByIdCmsCategorySuccessAction = createAction(CMSCATEGORY.GETBYID_SUCCESS);
export const getByIdCmsCategoryFailureAction = createAction(CMSCATEGORY.GETBYID_FAILURE);
export const changeStatusCmsCategoryAction = createAction(CMSCATEGORY.CHANGE_STATUS);
export const changeStatusCmsCategorySuccessAction = createAction(CMSCATEGORY.CHANGE_STATUS_SUCCESS);
export const changeStatusCmsCategoryFailureAction = createAction(CMSCATEGORY.CHANGE_STATUS_FAILURE);
export const deleteCmsCategoryAction = createAction(CMSCATEGORY.DELETE);
export const deleteCmsCategorySuccessAction = createAction(CMSCATEGORY.DELETE_SUCCESS);
export const deleteCmsCategoryFailureAction = createAction(CMSCATEGORY.DELETE_FAILURE);
export const createCmsCategoryAction = createAction(CMSCATEGORY.CREATE);
export const createCmsCategorySuccessAction = createAction(CMSCATEGORY.CREATE_SUCCESS);
export const createCmsCategoryFailureAction = createAction(CMSCATEGORY.CREATE_FAILURE);
export const updateCmsCategoryAction = createAction(CMSCATEGORY.UPDATE);
export const updateCmsCategorySuccessAction = createAction(CMSCATEGORY.UPDATE_SUCCESS);
export const updateCmsCategoryFailureAction = createAction(CMSCATEGORY.UPDATE_FAILURE);
export const removeCmsCategoryAction = createAction(CMSCATEGORY.REMOVE);
export const removeCmsCategorySuccessAction = createAction(CMSCATEGORY.REMOVE_SUCCESS);
export const removeCmsCategoryFailureAction = createAction(CMSCATEGORY.REMOVE_FAILURE);
export const createResetCmsCategoryAction = createAction(CMSCATEGORY.CREATE_RESET);
export const deleteImageCmsCategoryAction = createAction(CMSCATEGORY.DELETE_IMAGE);
export const deleteImageCmsCategorySuccessAction = createAction(CMSCATEGORY.DELETE_IMAGE_SUCCESS);
export const deleteImageCmsCategoryFailureAction = createAction(CMSCATEGORY.DELETE_IMAGE_FAILURE);
export const getAllCmsCategoriesContentAction = createAction(CMSCATEGORY.GETALL_CONTENT);
export const getAllCmsCategoriesContentSuccessAction = createAction(CMSCATEGORY.GETALL_CONTENT_SUCCESS);
export const getAllCmsCategoriesContentFailureAction = createAction(CMSCATEGORY.GETALL_CONTENT_FAILURE);
export const getAllCmsCategoriesByContentIdAction = createAction(CMSCATEGORY.GETALL_BY_CONTENT_ID);
export const getAllCmsCategoriesByContentIdSuccessAction = createAction(CMSCATEGORY.GETALL_BY_CONTENT_ID_SUCCESS);
export const getAllCmsCategoriesByContentIdFailureAction = createAction(CMSCATEGORY.GETALL_BY_CONTENT_ID_FAILURE);
