import { handleActions } from "redux-actions";
import { CMSCONTENT } from "../cmsContentAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [CMSCONTENT.CREATE]: () => ({
    isLoading: true,
  }),
  [CMSCONTENT.CREATE_SUCCESS]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    responseData: responseData.response ? responseData.response : responseData,
  }),
  [CMSCONTENT.CREATE_FAILURE]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  }),
  [CMSCONTENT.CREATE_RESET]: (state, { payload: responseData }) => {
    state = initialState;
    return state;
  },
  [CMSCONTENT.CKEDITOR_NEW]: (state, { payload: valueCk }) => {
    if (state.responseData && state.responseData.data) {
      state.responseData.data.description = valueCk;
    }

    return state;
  }
};
export default handleActions(actions, initialState);