import { createAction } from "redux-actions";
export const LOGOUT = {
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",
    LOGOUT_RESET: "LOGOUT_RESET",
};
export const logoutAction = createAction(LOGOUT.LOGOUT);
export const logoutSuccessAction = createAction(LOGOUT.LOGOUT_SUCCESS);
export const logoutFailureAction = createAction(LOGOUT.LOGOUT_FAILURE);
export const logoutResetAction = createAction(LOGOUT.LOGOUT_RESET);