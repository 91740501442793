import { createAction } from "redux-actions";
export const PROJECT = {
    GETALL: "PROJECT_GETALL",
    GETALL_SUCCESS: "PROJECT_GETALL_SUCCESS",
    GETALL_FAILURE: "PROJECT_GETALL_FAILURE",

    GETBYID: "PROJECT_GETBYID",
    GETBYID_SUCCESS: "PROJECT_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PROJECT_GETBYID_FAILURE",

    CHANGE_STATUS: "PROJECT_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "PROJECT_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "PROJECT_CHANGE_STATUS_FAILURE",

    DELETE: "PROJECT_DELETE",
    DELETE_SUCCESS: "PROJECT_DELETE_SUCCESS",
    DELETE_FAILURE: "PROJECT_DELETE_FAILURE",

    CREATE: "PROJECT_CREATE",
    CREATE_SUCCESS: "PROJECT_CREATE_SUCCESS",
    CREATE_FAILURE: "PROJECT_CREATE_FAILURE",

    UPDATE: "PROJECT_UPDATE",
    UPDATE_SUCCESS: "PROJECT_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PROJECT_UPDATE_FAILURE",

    REMOVE: "PROJECT_REMOVE",
    REMOVE_SUCCESS: "PROJECT_REMOVE_SUCCESS",
    REMOVE_FAILURE: "PROJECT_REMOVE_FAILURE",
    
    CREATE_RESET: 'PROJECT_CREATE_RESET',
    UPDATE_RESET: 'PROJECT_UPDATE_RESET',
    
    LOAD_PROVINCE: 'LOAD_PROVINCE',
    LOAD_PROVINCE_SUCCESS: 'LOAD_PROVINCE_SUCCESS',
    LOAD_PROVINCE_FAILURE: 'LOAD_PROVINCE_FAILURE',
    
    LOAD_DISTRICT: 'LOAD_DISTRICT',
    LOAD_DISTRICT_SUCCESS: 'LOAD_DISTRICT_SUCCESS',
    LOAD_DISTRICT_FAILURE: 'LOAD_DISTRICT_FAILURE',

    LOAD_WARD: 'LOAD_WARD',
    LOAD_WARD_SUCCESS: 'LOAD_WARD_SUCCESS',
    LOAD_WARD_FAILURE: 'LOAD_WARD_FAILURE',

    DELETE_IMAGE: "DELETE_IMAGE",
    DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
    DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",

    UPDATE_FIELD: "UPDATE_FIELD",
    UPDATE_FIELD_SUCCESS: "UPDATE_FIELD_SUCCESS",
    UPDATE_FIELD_FAILURE: "UPDATE_FIELD_FAILURE"
};

export const getAllProjectsAction = createAction(PROJECT.GETALL);
export const getAllProjectsSuccessAction = createAction(PROJECT.GETALL_SUCCESS);
export const getAllProjectsFailureAction = createAction(PROJECT.GETALL_FAILURE);

export const getByIdProjectAction = createAction(PROJECT.GETBYID);
export const getByIdProjectSuccessAction = createAction(PROJECT.GETBYID_SUCCESS);
export const getByIdProjectFailureAction = createAction(PROJECT.GETBYID_FAILURE);

export const changeStatusProjectAction = createAction(PROJECT.CHANGE_STATUS);
export const changeStatusProjectSuccessAction = createAction(PROJECT.CHANGE_STATUS_SUCCESS);
export const changeStatusProjectFailureAction = createAction(PROJECT.CHANGE_STATUS_FAILURE);

export const deleteProjectAction = createAction(PROJECT.DELETE);
export const deleteProjectSuccessAction = createAction(PROJECT.DELETE_SUCCESS);
export const deleteProjectFailureAction = createAction(PROJECT.DELETE_FAILURE);

export const createProjectAction = createAction(PROJECT.CREATE);
export const createProjectSuccessAction = createAction(PROJECT.CREATE_SUCCESS);
export const createProjectFailureAction = createAction(PROJECT.CREATE_FAILURE);

export const updateProjectAction = createAction(PROJECT.UPDATE);
export const updateProjectSuccessAction = createAction(PROJECT.UPDATE_SUCCESS);
export const updateProjectFailureAction = createAction(PROJECT.UPDATE_FAILURE);

export const removeProjectAction = createAction(PROJECT.REMOVE);
export const removeProjectSuccessAction = createAction(PROJECT.REMOVE_SUCCESS);
export const removeProjectFailureAction = createAction(PROJECT.REMOVE_FAILURE);

export const createResetProjectAction = createAction(PROJECT.CREATE_RESET);
export const updateResetProjectAction = createAction(PROJECT.UPDATE_RESET);

export const loadWardProjectAction = createAction(PROJECT.LOAD_WARD);
export const loadWardProjectSuccessAction = createAction(PROJECT.LOAD_WARD_SUCCESS);
export const loadWardProjectFailureAction = createAction(PROJECT.LOAD_WARD_FAILURE);

export const loadProvinceProjectAction = createAction(PROJECT.LOAD_PROVINCE);
export const loadProvinceProjectSuccessAction = createAction(PROJECT.LOAD_PROVINCE_SUCCESS);
export const loadProvinceProjectFailureAction = createAction(PROJECT.LOAD_PROVINCE_FAILURE);

export const loadDistrictProjectAction = createAction(PROJECT.LOAD_DISTRICT);
export const loadDistrictProjectSuccessAction = createAction(PROJECT.LOAD_DISTRICT_SUCCESS);
export const loadDistrictProjectFailureAction = createAction(PROJECT.LOAD_DISTRICT_FAILURE);

export const deleteProjectImageTypeAction = createAction(PROJECT.DELETE_IMAGE);
export const deleteProjectImageTypeSuccessAction = createAction(PROJECT.DELETE_IMAGE_SUCCESS);
export const deleteProjectImageTypeFailureAction = createAction(PROJECT.DELETE_IMAGE_FAILURE);

export const updateFieldProjectAction = createAction(PROJECT.UPDATE_FIELD);
export const updateFieldProjectSuccessAction = createAction(PROJECT.UPDATE_FIELD_SUCCESS);
export const updateFieldProjectFailureAction = createAction(PROJECT.UPDATE_FIELD_FAILURE);