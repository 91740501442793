import { createAction } from "redux-actions";
export const OBJECTTYPE = {
    GETALL: "OBJECTTYPE_GETALL",
    GETALL_SUCCESS: "OBJECTTYPE_GETALL_SUCCESS",
    GETALL_FAILURE: "OBJECTTYPE_GETALL_FAILURE",
    GETBYID: "OBJECTTYPE_GETBYID",
    GETBYID_SUCCESS: "OBJECTTYPE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "OBJECTTYPE_GETBYID_FAILURE",
    CHANGE_STATUS: "OBJECTTYPE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "OBJECTTYPE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "OBJECTTYPE_CHANGE_STATUS_FAILURE",
    DELETE: "OBJECTTYPE_DELETE",
    DELETE_SUCCESS: "OBJECTTYPE_DELETE_SUCCESS",
    DELETE_FAILURE: "OBJECTTYPE_DELETE_FAILURE",
    ADD: "OBJECTTYPE_ADD",
    ADD_SUCCESS: "OBJECTTYPE_ADD_SUCCESS",
    ADD_FAILURE: "OBJECTTYPE_ADD_FAILURE",
    EDIT: "OBJECTTYPE_EDIT",
    EDIT_SUCCESS: "OBJECTTYPE_EDIT_SUCCESS",
    EDIT_FAILURE: "OBJECTTYPE_EDIT_FAILURE",
    CREATE_RESET: 'OBJECTTYPE_CREATE_RESET',
    GETBY_PROVINCE:'OBJECTTYPE_GETBY_PROVINCE_CODE',
    GETBY_PROVINCE_SUCCESS:'OBJECTTYPE_GETBY_PROVINCE_SUCCESS',
    GETBY_PROVINCE_FAILURE:'OBJECTTYPE_GETBY_PROVINCE_FAILURE'
};
export const getAllObjectTypeAction = createAction(OBJECTTYPE.GETALL);
export const getAllObjectTypeSuccessAction = createAction(OBJECTTYPE.GETALL_SUCCESS);
export const getAllObjectTypeFailureAction = createAction(OBJECTTYPE.GETALL_FAILURE);
export const getByIdObjectTypeAction = createAction(OBJECTTYPE.GETBYID);
export const getByIdObjectTypeSuccessAction = createAction(OBJECTTYPE.GETBYID_SUCCESS);
export const getByIdObjectTypeFailureAction = createAction(OBJECTTYPE.GETBYID_FAILURE);
export const changeStatusObjectTypeAction = createAction(OBJECTTYPE.CHANGE_STATUS);
export const changeStatusObjectTypeSuccessAction = createAction(OBJECTTYPE.CHANGE_STATUS_SUCCESS);
export const changeStatusObjectTypeFailureAction = createAction(OBJECTTYPE.CHANGE_STATUS_FAILURE);
export const deleteObjectTypeAction = createAction(OBJECTTYPE.DELETE);
export const deleteObjectTypeSuccessAction = createAction(OBJECTTYPE.DELETE_SUCCESS);
export const deleteObjectTypeFailureAction = createAction(OBJECTTYPE.DELETE_FAILURE);
export const addObjectTypeAction = createAction(OBJECTTYPE.ADD);
export const addObjectTypeSuccessAction = createAction(OBJECTTYPE.ADD_SUCCESS);
export const addObjectTypeFailureAction = createAction(OBJECTTYPE.ADD_FAILURE);
export const editObjectTypeAction = createAction(OBJECTTYPE.EDIT);
export const editObjectTypeSuccessAction = createAction(OBJECTTYPE.EDIT_SUCCESS);
export const editObjectTypeFailureAction = createAction(OBJECTTYPE.EDIT_FAILURE);
export const createResetObjectTypeAction = createAction(OBJECTTYPE.CREATE_RESET);
