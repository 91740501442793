import { createAction } from "redux-actions";
export const UPLOAD_CROP_MULTIPLE = {
    CHUNK_CROP_MULTIPLE: "CHUNK_CROP_MULTIPLE_UPLOAD",
    CHUNK_CROP_MULTIPLE_SUCCESS: "CHUNK_CROP_MULTIPLE_UPLOAD_SUCCESS",
    CHUNK_CROP_MULTIPLE_FAILURE: "CHUNK_CROP_MULTIPLE_UPLOAD_FAILURE",
    
    CREATE_CROP_MULTIPLE: "CREATE_CROP_MULTIPLE_UPLOAD",
    CREATE_CROP_MULTIPLE_SUCCESS: "CREATE_CROP_MULTIPLE_UPLOAD_SUCCESS",
    CREATE_CROP_MULTIPLE_FAILURE: "CREATE_CROP_MULTIPLE_UPLOAD_FAILURE",    

    RESET_CROP_MULTIPLE: "RESET_CROP_MULTIPLE",    


};
export const chunkCropMultipleUploadAction = createAction(UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE);
export const chunkCropMultipleUploadSuccessAction = createAction(UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE_SUCCESS);
export const chunkCropMultipleUploadFailureAction = createAction(UPLOAD_CROP_MULTIPLE.CHUNK_CROP_MULTIPLE_FAILURE);

export const createCropMultipleUploadAction = createAction(UPLOAD_CROP_MULTIPLE.CREATE_CROP_MULTIPLE);
export const createCropMultipleUploadSuccessAction = createAction(UPLOAD_CROP_MULTIPLE.CREATE_CROP_MULTIPLE_SUCCESS);
export const createCropMultipleUploadFailureAction = createAction(UPLOAD_CROP_MULTIPLE.CREATE_CROP_MULTIPLE_FAILURE);
export const resetCropMultipleUploadAction = createAction(UPLOAD_CROP_MULTIPLE.RESET_CROP_MULTIPLE);