import { createAction } from 'redux-actions';
export const PROPERTY = {
  GETALL: 'PROPERTY_GETALL',
  GETALL_SUCCESS: 'PROPERTY_GETALL_SUCCESS',
  GETALL_FAILURE: 'PROPERTY_GETALL_FAILURE',
  GETALL_RESET: 'PROPERTY_GETALL_RESET',
  GETBYID: 'PROPERTY_GETBYID',
  GETBYID_SUCCESS: 'PROPERTY_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'PROPERTY_GETBYID_FAILURE',
  CHANGE_STATUS: 'PROPERTY_CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS: 'PROPERTY_CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILURE: 'PROPERTY_CHANGE_STATUS_FAILURE',
  CHANGE_STATUS_RESET: 'PROPERTY_CHANGE_STATUS_RESET',
  CHANGE_OUTSTANDING: 'PROPERTY_CHANGE_OUTSTANDING',
  CHANGE_OUTSTANDING_SUCCESS: 'PROPERTY_CHANGE_OUTSTANDING_SUCCESS',
  CHANGE_OUTSTANDING_FAILURE: 'PROPERTY_CHANGE_OUTSTANDING_FAILURE',
  DELETE: 'PROPERTY_DELETE',
  DELETE_SUCCESS: 'PROPERTY_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROPERTY_DELETE_FAILURE',
  DELETE_MULTIPLE: 'PROPERTY_DELETE_MULTIPLE',
  DELETE_MULTIPLE_SUCCESS: 'PROPERTY_DELETE_MULTIPLE_SUCCESS',
  DELETE_MULTIPLE_FAILURE: 'PROPERTY_DELETE_MULTIPLE_FAILURE',
  DELETE_MULTIPLE_RESET: 'PROPERTY_DELETE_MULTIPLE_RESET',
  DELETE_RESET: 'PROPERTY_DELETE_RESET',
  CREATE: 'PROPERTY_CREATE',
  CREATE_SUCCESS: 'PROPERTY_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROPERTY_CREATE_FAILURE',
  UPDATE: 'PROPERTY_UPDATE',
  UPDATE_SUCCESS: 'PROPERTY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROPERTY_UPDATE_FAILURE',
  REMOVE: 'PROPERTY_REMOVE',
  REMOVE_SUCCESS: 'PROPERTY_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'PROPERTY_REMOVE_FAILURE',
  CREATE_RESET: 'PROPERTY_CREATE_RESET',
  UPDATE_RESET: 'PROPERTY_UPDATE_RESET',
  GETBYID_RESET: 'PROPERTY_GETBYID_RESET',
  DELETE_IMAGE: 'PROPERTY_DELETE_IMAGE',
  DELETE_IMAGE_SUCCESS: 'PROPERTY_DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'PROPERTY_DELETE_IMAGE_FAILURE',
  LOAD_PROPERTYTYPE: 'LOAD_PROPERTYTYPE',
  LOAD_PROPERTYTYPE_SUCCESS: 'LOAD_PROPERTYTYPE_SUCCESS',
  LOAD_PROPERTYTYPE_FAILURE: 'LOAD_PROPERTYTYPE_FAILURE',
  LOAD_DISTRICT: 'LOAD_DISTRICT',
  LOAD_DISTRICT_SUCCESS: 'LOAD_DISTRICT_SUCCESS',
  LOAD_DISTRICT_FAILURE: 'LOAD_DISTRICT_FAILURE',
  LOAD_WARD: 'LOAD_WARD',
  LOAD_WARD_SUCCESS: 'LOAD_WARD_SUCCESS',
  LOAD_WARD_FAILURE: 'LOAD_WARD_FAILURE',
  CKEDITOR: 'CKEDITOR',
  CKEDITOR_NEW: 'CKEDITOR_NEW',
};
export const getAllPropertiesAction = createAction(PROPERTY.GETALL);
export const getAllPropertiesSuccessAction = createAction(
  PROPERTY.GETALL_SUCCESS
);
export const getAllPropertiesFailureAction = createAction(
  PROPERTY.GETALL_FAILURE
);
export const getAllPropertiesResetAction = createAction(PROPERTY.GETALL_RESET);
export const getByIdPropertyAction = createAction(PROPERTY.GETBYID);
export const getByIdPropertySuccessAction = createAction(
  PROPERTY.GETBYID_SUCCESS
);
export const getByIdPropertyFailureAction = createAction(
  PROPERTY.GETBYID_FAILURE
);
export const changeStatusPropertyAction = createAction(PROPERTY.CHANGE_STATUS);
export const changeStatusPropertySuccessAction = createAction(
  PROPERTY.CHANGE_STATUS_SUCCESS
);
export const changeStatusPropertyFailureAction = createAction(
  PROPERTY.CHANGE_STATUS_FAILURE
);
export const changeStatusPropertyResetAction = createAction(
  PROPERTY.CHANGE_STATUS_RESET
);
export const changeOutStandingPropertyAction = createAction(
  PROPERTY.CHANGE_OUTSTANDING
);
export const changeOutStandingPropertySuccessAction = createAction(
  PROPERTY.CHANGE_OUTSTANDING_SUCCESS
);
export const changeOutStandingPropertyFailureAction = createAction(
  PROPERTY.CHANGE_OUTSTANDING_FAILURE
);
export const deletePropertyAction = createAction(PROPERTY.DELETE);
export const deletePropertySuccessAction = createAction(
  PROPERTY.DELETE_SUCCESS
);
export const deletePropertyFailureAction = createAction(
  PROPERTY.DELETE_FAILURE
);
export const deletePropertyResetAction = createAction(PROPERTY.DELETE_RESET);
export const deletePropertyMultipleAction = createAction(
  PROPERTY.DELETE_MULTIPLE
);
export const deletePropertyMultipleSuccessAction = createAction(
  PROPERTY.DELETE_MULTIPLE_SUCCESS
);
export const deletePropertyMultipleFailureAction = createAction(
  PROPERTY.DELETE_MULTIPLE_FAILURE
);
export const deletePropertyMultipleResetAction = createAction(
  PROPERTY.DELETE_MULTIPLE_RESET
);
export const createPropertyAction = createAction(PROPERTY.CREATE);
export const createPropertySuccessAction = createAction(
  PROPERTY.CREATE_SUCCESS
);
export const createPropertyFailureAction = createAction(
  PROPERTY.CREATE_FAILURE
);
export const updatePropertyAction = createAction(PROPERTY.UPDATE);
export const updatePropertySuccessAction = createAction(
  PROPERTY.UPDATE_SUCCESS
);
export const updatePropertyFailureAction = createAction(
  PROPERTY.UPDATE_FAILURE
);
export const removePropertyAction = createAction(PROPERTY.REMOVE);
export const removePropertySuccessAction = createAction(
  PROPERTY.REMOVE_SUCCESS
);
export const removePropertyFailureAction = createAction(
  PROPERTY.REMOVE_FAILURE
);
export const createResetPropertyAction = createAction(PROPERTY.CREATE_RESET);
export const updateResetPropertyAction = createAction(PROPERTY.UPDATE_RESET);
export const getByIdResetPropertyAction = createAction(PROPERTY.GETBYID_RESET);
export const deleteImagePropertyAction = createAction(PROPERTY.DELETE_IMAGE);
export const deleteImagePropertySuccessAction = createAction(
  PROPERTY.DELETE_IMAGE_SUCCESS
);
export const deleteImagePropertyFailureAction = createAction(
  PROPERTY.DELETE_IMAGE_FAILURE
);
export const loadPropertyTypeAction = createAction(PROPERTY.LOAD_PROPERTYTYPE);
export const loadPropertyTypeSuccessAction = createAction(
  PROPERTY.LOAD_PROPERTYTYPE_SUCCESS
);
export const loadPropertyTypeFailureAction = createAction(
  PROPERTY.LOAD_PROPERTYTYPE_FAILURE
);
export const loadDistrictAction = createAction(PROPERTY.LOAD_DISTRICT);
export const loadDistrictSuccessAction = createAction(
  PROPERTY.LOAD_DISTRICT_SUCCESS
);
export const loadDistrictFailureAction = createAction(
  PROPERTY.LOAD_DISTRICT_FAILURE
);
export const loadWardAction = createAction(PROPERTY.LOAD_WARD);
export const loadWardSuccessAction = createAction(PROPERTY.LOAD_WARD_SUCCESS);
export const loadWardFailureAction = createAction(PROPERTY.LOAD_WARD_FAILURE);
export const ckEditorPropertyAction = createAction(PROPERTY.CKEDITOR);
export const ckEditorNewPropertyAction = createAction(PROPERTY.CKEDITOR_NEW);
