import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./features/login/pages/login'));
const ForgotPassword = React.lazy(() => import('./features/forgotpassword/pages/forgotpassword'));
const ResetPassword = React.lazy(() => import('./features/resetpassword/pages/resetpassword'));
const Page404 = React.lazy(() => import('./features/page404/pages/page404'));
const Page500 = React.lazy(() => import('./features/page500/pages/page500'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => {
                return <Login {...props}/>}} />
              <Route exact path="/forgotpassword" name="Forgot password Page" render={props => <ForgotPassword {...props}/>} />
              <Route path="/resetpassword" name="Reset password Page" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
