import { createAction } from "redux-actions";
export const OFFICE = {
    GETALL: "OFFICE_GETALL",
    GETALL_SUCCESS: "OFFICE_GETALL_SUCCESS",
    GETALL_FAILURE: "OFFICE_GETALL_FAILURE",

    GETBYID: "OFFICE_GETBYID",
    GETBYID_SUCCESS: "OFFICE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "OFFICE_GETBYID_FAILURE",

    CHANGE_STATUS: "OFFICE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "OFFICE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "OFFICE_CHANGE_STATUS_FAILURE",

    DELETE: "OFFICE_DELETE",
    DELETE_SUCCESS: "OFFICE_DELETE_SUCCESS",
    DELETE_FAILURE: "OFFICE_DELETE_FAILURE",

    CREATE: "OFFICE_CREATE",
    CREATE_SUCCESS: "OFFICE_CREATE_SUCCESS",
    CREATE_FAILURE: "OFFICE_CREATE_FAILURE",

    UPDATE: "OFFICE_UPDATE",
    UPDATE_SUCCESS: "OFFICE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "OFFICE_UPDATE_FAILURE",

    REMOVE: "OFFICE_REMOVE",
    REMOVE_SUCCESS: "OFFICE_REMOVE_SUCCESS",
    REMOVE_FAILURE: "OFFICE_REMOVE_FAILURE",
    
    CREATE_RESET: 'OFFICE_CREATE_RESET',
    UPDATE_RESET: 'OFFICE_UPDATE_RESET',
    
    LOAD_PROVINCE: 'LOAD_PROVINCE',
    LOAD_PROVINCE_SUCCESS: 'LOAD_PROVINCE_SUCCESS',
    LOAD_PROVINCE_FAILURE: 'LOAD_PROVINCE_FAILURE',
    
    LOAD_DISTRICT: 'LOAD_DISTRICT',
    LOAD_DISTRICT_SUCCESS: 'LOAD_DISTRICT_SUCCESS',
    LOAD_DISTRICT_FAILURE: 'LOAD_DISTRICT_FAILURE',

    LOAD_WARD: 'LOAD_WARD',
    LOAD_WARD_SUCCESS: 'LOAD_WARD_SUCCESS',
    LOAD_WARD_FAILURE: 'LOAD_WARD_FAILURE',

    DELETE_IMAGE: "DELETE_IMAGE",
    DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
    DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",

    UPDATE_FIELD: "UPDATE_FIELD",
    UPDATE_FIELD_SUCCESS: "UPDATE_FIELD_SUCCESS",
    UPDATE_FIELD_FAILURE: "UPDATE_FIELD_FAILURE"
};

export const getAllOfficesAction = createAction(OFFICE.GETALL);
export const getAllOfficesSuccessAction = createAction(OFFICE.GETALL_SUCCESS);
export const getAllOfficesFailureAction = createAction(OFFICE.GETALL_FAILURE);

export const getByIdOfficeAction = createAction(OFFICE.GETBYID);
export const getByIdOfficeSuccessAction = createAction(OFFICE.GETBYID_SUCCESS);
export const getByIdOfficeFailureAction = createAction(OFFICE.GETBYID_FAILURE);

export const changeStatusOfficeAction = createAction(OFFICE.CHANGE_STATUS);
export const changeStatusOfficeSuccessAction = createAction(OFFICE.CHANGE_STATUS_SUCCESS);
export const changeStatusOfficeFailureAction = createAction(OFFICE.CHANGE_STATUS_FAILURE);

export const deleteOfficeAction = createAction(OFFICE.DELETE);
export const deleteOfficeSuccessAction = createAction(OFFICE.DELETE_SUCCESS);
export const deleteOfficeFailureAction = createAction(OFFICE.DELETE_FAILURE);

export const createOfficeAction = createAction(OFFICE.CREATE);
export const createOfficeSuccessAction = createAction(OFFICE.CREATE_SUCCESS);
export const createOfficeFailureAction = createAction(OFFICE.CREATE_FAILURE);

export const updateOfficeAction = createAction(OFFICE.UPDATE);
export const updateOfficeSuccessAction = createAction(OFFICE.UPDATE_SUCCESS);
export const updateOfficeFailureAction = createAction(OFFICE.UPDATE_FAILURE);

export const removeOfficeAction = createAction(OFFICE.REMOVE);
export const removeOfficeSuccessAction = createAction(OFFICE.REMOVE_SUCCESS);
export const removeOfficeFailureAction = createAction(OFFICE.REMOVE_FAILURE);

export const createResetOfficeAction = createAction(OFFICE.CREATE_RESET);
export const updateResetOfficeAction = createAction(OFFICE.UPDATE_RESET);

export const loadWardOfficeAction = createAction(OFFICE.LOAD_WARD);
export const loadWardOfficeSuccessAction = createAction(OFFICE.LOAD_WARD_SUCCESS);
export const loadWardOfficeFailureAction = createAction(OFFICE.LOAD_WARD_FAILURE);

export const loadProvinceOfficeAction = createAction(OFFICE.LOAD_PROVINCE);
export const loadProvinceOfficeSuccessAction = createAction(OFFICE.LOAD_PROVINCE_SUCCESS);
export const loadProvinceOfficeFailureAction = createAction(OFFICE.LOAD_PROVINCE_FAILURE);

export const loadDistrictOfficeAction = createAction(OFFICE.LOAD_DISTRICT);
export const loadDistrictOfficeSuccessAction = createAction(OFFICE.LOAD_DISTRICT_SUCCESS);
export const loadDistrictOfficeFailureAction = createAction(OFFICE.LOAD_DISTRICT_FAILURE);

export const deleteOfficeImageTypeAction = createAction(OFFICE.DELETE_IMAGE);
export const deleteOfficeImageTypeSuccessAction = createAction(OFFICE.DELETE_IMAGE_SUCCESS);
export const deleteOfficeImageTypeFailureAction = createAction(OFFICE.DELETE_IMAGE_FAILURE);

export const updateFieldOfficeAction = createAction(OFFICE.UPDATE_FIELD);
export const updateFieldOfficeSuccessAction = createAction(OFFICE.UPDATE_FIELD_SUCCESS);
export const updateFieldOfficeFailureAction = createAction(OFFICE.UPDATE_FIELD_FAILURE);