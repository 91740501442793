import { createAction } from "redux-actions";
export const CURRENCY = {
    GETALL: "CURRENCY_GETALL",
    GETALL_SUCCESS: "CURRENCY_GETALL_SUCCESS",
    GETALL_FAILURE: "CURRENCY_GETALL_FAILURE",
    GETBYID: "CURRENCY_GETBYID",
    GETBYID_SUCCESS: "CURRENCY_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CURRENCY_GETBYID_FAILURE",
    CHANGE_STATUS: "CURRENCY_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CURRENCY_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CURRENCY_CHANGE_STATUS_FAILURE",
    DELETE: "CURRENCY_DELETE",
    DELETE_SUCCESS: "CURRENCY_DELETE_SUCCESS",
    DELETE_FAILURE: "CURRENCY_DELETE_FAILURE",
    ADD: "CURRENCY_ADD",
    ADD_SUCCESS: "CURRENCY_ADD_SUCCESS",
    ADD_FAILURE: "CURRENCY_ADD_FAILURE",
    EDIT: "CURRENCY_EDIT",
    EDIT_SUCCESS: "CURRENCY_EDIT_SUCCESS",
    EDIT_FAILURE: "CURRENCY_EDIT_FAILURE",
    CREATE_RESET: 'CURRENCY_CREATE_RESET',
};
export const getAllCurrencyAction = createAction(CURRENCY.GETALL);
export const getAllCurrencySuccessAction = createAction(CURRENCY.GETALL_SUCCESS);
export const getAllCurrencyFailureAction = createAction(CURRENCY.GETALL_FAILURE);
export const getByIdCurrencyAction = createAction(CURRENCY.GETBYID);
export const getByIdCurrencySuccessAction = createAction(CURRENCY.GETBYID_SUCCESS);
export const getByIdCurrencyFailureAction = createAction(CURRENCY.GETBYID_FAILURE);
export const changeStatusCurrencyAction = createAction(CURRENCY.CHANGE_STATUS);
export const changeStatusCurrencySuccessAction = createAction(CURRENCY.CHANGE_STATUS_SUCCESS);
export const changeStatusCurrencyFailureAction = createAction(CURRENCY.CHANGE_STATUS_FAILURE);
export const deleteCurrencyAction = createAction(CURRENCY.DELETE);
export const deleteCurrencySuccessAction = createAction(CURRENCY.DELETE_SUCCESS);
export const deleteCurrencyFailureAction = createAction(CURRENCY.DELETE_FAILURE);
export const addCurrencyAction = createAction(CURRENCY.ADD);
export const addCurrencySuccessAction = createAction(CURRENCY.ADD_SUCCESS);
export const addCurrencyFailureAction = createAction(CURRENCY.ADD_FAILURE);
export const editCurrencyAction = createAction(CURRENCY.EDIT);
export const editCurrencySuccessAction = createAction(CURRENCY.EDIT_SUCCESS);
export const editCurrencyFailureAction = createAction(CURRENCY.EDIT_FAILURE);
export const createResetCurrencyAction = createAction(CURRENCY.CREATE_RESET);
