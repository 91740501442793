import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { SYSTEM_API } from './systemApiAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getSystemApiSuccessAction,
    getSystemApiFailureAction,
    getSystemApiByIdSuccessAction,
    getSystemApiByIdFailureAction,
    changeStatusSystemApiSuccessAction,
    changeStatusSystemApiFailureAction,
    deleteSystemApiSuccessAction,
    deleteSystemApiFailureAction,
    createSystemApiSuccessAction,
    createSystemApiFailureAction,
    updateSystemApiSuccessAction,
    updateSystemApiFailureAction
} from './systemApiAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/SystemApi/GetAll?pageSize=1000&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/SystemApi/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/SystemApi/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/SystemApi/Delete/';
const API_ADD = config.HOST + config.VERSION + '/SystemApi/Create';
const API_EDIT = config.HOST + config.VERSION + '/SystemApi/Update';

// GETALL
const getSystemApiEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getSystemApiSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getSystemApiFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getSystemApiByIdEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getSystemApiByIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getSystemApiByIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusSystemApiEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusSystemApiSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusSystemApiFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteSystemApiEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteSystemApiSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteSystemApiFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createSystemApiEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createSystemApiSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(createSystemApiFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateSystemApiEpic = (action$, store) => action$.pipe(
    ofType(SYSTEM_API.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateSystemApiSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateSystemApiFailureAction(error));
                    }
                })
            );
    })
);

export { getSystemApiEpic, changeStatusSystemApiEpic, deleteSystemApiEpic, createSystemApiEpic, updateSystemApiEpic, getSystemApiByIdEpic };