import { createAction } from "redux-actions";
export const BUILDING = {
    GETALL: "BUILDING_GETALL",
    GETALL_SUCCESS: "BUILDING_GETALL_SUCCESS",
    GETALL_FAILURE: "BUILDING_GETALL_FAILURE",
    GETBYID: "BUILDING_GETBYID",
    GETBYID_SUCCESS: "BUILDING_GETBYID_SUCCESS",
    GETBYID_FAILURE: "BUILDING_GETBYID_FAILURE",
    CHANGE_STATUS: "BUILDING_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "BUILDING_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "BUILDING_CHANGE_STATUS_FAILURE",
    DELETE: "BUILDING_DELETE",
    DELETE_SUCCESS: "BUILDING_DELETE_SUCCESS",
    DELETE_FAILURE: "BUILDING_DELETE_FAILURE",
    ADD: "BUILDING_ADD",
    ADD_SUCCESS: "BUILDING_ADD_SUCCESS",
    ADD_FAILURE: "BUILDING_ADD_FAILURE",
    EDIT: "BUILDING_EDIT",
    EDIT_SUCCESS: "BUILDING_EDIT_SUCCESS",
    EDIT_FAILURE: "BUILDING_EDIT_FAILURE",
};
export const getAllBuildingAction = createAction(BUILDING.GETALL);
export const getAllBuildingSuccessAction = createAction(BUILDING.GETALL_SUCCESS);
export const getAllBuildingFailureAction = createAction(BUILDING.GETALL_FAILURE);
export const getByIdBuildingAction = createAction(BUILDING.GETBYID);
export const getByIdBuildingSuccessAction = createAction(BUILDING.GETBYID_SUCCESS);
export const getByIdBuildingFailureAction = createAction(BUILDING.GETBYID_FAILURE);
export const changeStatusBuildingAction = createAction(BUILDING.CHANGE_STATUS);
export const changeStatusBuildingSuccessAction = createAction(BUILDING.CHANGE_STATUS_SUCCESS);
export const changeStatusBuildingFailureAction = createAction(BUILDING.CHANGE_STATUS_FAILURE);
export const deleteBuildingAction = createAction(BUILDING.DELETE);
export const deleteBuildingSuccessAction = createAction(BUILDING.DELETE_SUCCESS);
export const deleteBuildingFailureAction = createAction(BUILDING.DELETE_FAILURE);
export const addBuildingAction = createAction(BUILDING.ADD);
export const addBuildingSuccessAction = createAction(BUILDING.ADD_SUCCESS);
export const addBuildingFailureAction = createAction(BUILDING.ADD_FAILURE);
export const editBuildingAction = createAction(BUILDING.EDIT);
export const editBuildingSuccessAction = createAction(BUILDING.EDIT_SUCCESS);
export const editBuildingFailureAction = createAction(BUILDING.EDIT_FAILURE);
