import { createAction } from "redux-actions";
export const FORGOTPASSWORD = {
    FORGOTPASSWORD: "FORGOTPASSWORD",
    FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
    FORGOTPASSWORD_FAILURE: "FORGOTPASSWORD_FAILURE",
    FORGOTPASSWORD_RESET: "FORGOTPASSWORD_RESET"
};
export const forgotpasswordAction = createAction(FORGOTPASSWORD.FORGOTPASSWORD);
export const forgotpasswordSuccessAction = createAction(FORGOTPASSWORD.FORGOTPASSWORD_SUCCESS);
export const forgotpasswordFailureAction = createAction(FORGOTPASSWORD.FORGOTPASSWORD_FAILURE);
export const forgotpasswordResetAction = createAction(FORGOTPASSWORD.FORGOTPASSWORD_RESET);