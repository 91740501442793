import { createAction } from "redux-actions";
export const CMSCONTENT = {
    GETALL: "CMSCONTENT_GETALL",
    GETALL_SUCCESS: "CMSCONTENT_GETALL_SUCCESS",
    GETALL_FAILURE: "CMSCONTENT_GETALL_FAILURE",
    GETALL_BY_STATUS: "CMSCONTENT_GETALL_BY_STATUS",
    GETALL_BY_STATUS_SUCCESS: "CMSCONTENT_GETALL_BY_STATUS_SUCCESS",
    GETALL_BY_STATUS_FAILURE: "CMSCONTENT_GETALL_BY_STATUS_FAILURE",
    GETBYID: "CMSCONTENT_GETBYID",
    GETBYID_SUCCESS: "CMSCONTENT_GETBYID_SUCCESS",
    GETBYID_FAILURE: "CMSCONTENT_GETBYID_FAILURE",
    CHANGE_STATUS: "CMSCONTENT_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "CMSCONTENT_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "CMSCONTENT_CHANGE_STATUS_FAILURE",
    DELETE: "CMSCONTENT_DELETE",
    DELETE_SUCCESS: "CMSCONTENT_DELETE_SUCCESS",
    DELETE_FAILURE: "CMSCONTENT_DELETE_FAILURE",
    CREATE: "CMSCONTENT_CREATE",
    CREATE_SUCCESS: "CMSCONTENT_CREATE_SUCCESS",
    CREATE_FAILURE: "CMSCONTENT_CREATE_FAILURE",
    UPDATE: "CMSCONTENT_UPDATE",
    UPDATE_SUCCESS: "CMSCONTENT_UPDATE_SUCCESS",
    UPDATE_FAILURE: "CMSCONTENT_UPDATE_FAILURE",
    REMOVE: "CMSCONTENT_REMOVE",
    REMOVE_SUCCESS: "CMSCONTENT_REMOVE_SUCCESS",
    REMOVE_FAILURE: "CMSCONTENT_REMOVE_FAILURE",
    CREATE_RESET: 'CMSCONTENT_CREATE_RESET',
    DELETE_IMAGE: 'CMSCONTENT_DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'CMSCONTENT_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'CMSCONTENT_DELETE_IMAGE_FAILURE',
    GETALL_CONTENT: "CMSCONTENT_GETALL_CONTENT",
};
export const getAllCmsContentsAction = createAction(CMSCONTENT.GETALL);
export const getAllCmsContentsSuccessAction = createAction(CMSCONTENT.GETALL_SUCCESS);
export const getAllCmsContentsFailureAction = createAction(CMSCONTENT.GETALL_FAILURE);
export const getAllCmsContentsByStatusAction = createAction(CMSCONTENT.GETALL_BY_STATUS);
export const getAllCmsContentsByStatusSuccessAction = createAction(CMSCONTENT.GETALL_BY_STATUS_SUCCESS);
export const getAllCmsContentsByStatusFailureAction = createAction(CMSCONTENT.GETALL_BY_STATUS_FAILURE);
export const getByIdCmsContentAction = createAction(CMSCONTENT.GETBYID);
export const getByIdCmsContentSuccessAction = createAction(CMSCONTENT.GETBYID_SUCCESS);
export const getByIdCmsContentFailureAction = createAction(CMSCONTENT.GETBYID_FAILURE);
export const changeStatusCmsContentAction = createAction(CMSCONTENT.CHANGE_STATUS);
export const changeStatusCmsContentSuccessAction = createAction(CMSCONTENT.CHANGE_STATUS_SUCCESS);
export const changeStatusCmsContentFailureAction = createAction(CMSCONTENT.CHANGE_STATUS_FAILURE);
export const deleteCmsContentAction = createAction(CMSCONTENT.DELETE);
export const deleteCmsContentSuccessAction = createAction(CMSCONTENT.DELETE_SUCCESS);
export const deleteCmsContentFailureAction = createAction(CMSCONTENT.DELETE_FAILURE);
export const createCmsContentAction = createAction(CMSCONTENT.CREATE);
export const createCmsContentSuccessAction = createAction(CMSCONTENT.CREATE_SUCCESS);
export const createCmsContentFailureAction = createAction(CMSCONTENT.CREATE_FAILURE);
export const updateCmsContentAction = createAction(CMSCONTENT.UPDATE);
export const updateCmsContentSuccessAction = createAction(CMSCONTENT.UPDATE_SUCCESS);
export const updateCmsContentFailureAction = createAction(CMSCONTENT.UPDATE_FAILURE);
export const removeCmsContentAction = createAction(CMSCONTENT.REMOVE);
export const removeCmsContentSuccessAction = createAction(CMSCONTENT.REMOVE_SUCCESS);
export const removeCmsContentFailureAction = createAction(CMSCONTENT.REMOVE_FAILURE);
export const createResetCmsContentAction = createAction(CMSCONTENT.CREATE_RESET);
export const deleteImageCmsContentAction = createAction(CMSCONTENT.DELETE_IMAGE);
export const deleteImageCmsContentSuccessAction = createAction(CMSCONTENT.DELETE_IMAGE_SUCCESS);
export const deleteImageCmsContentFailureAction = createAction(CMSCONTENT.DELETE_IMAGE_FAILURE);
