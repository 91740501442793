import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CMSCAROUSEL } from './cmsCarouselAction';
import { getNewToken } from '../../../services/getNewToken';
import {
    getAllCmsCarouselsSuccessAction,
    getAllCmsCarouselsFailureAction,
    getByIdCmsCarouselSuccessAction,
    getByIdCmsCarouselFailureAction,
    changeStatusCmsCarouselSuccessAction,
    changeStatusCmsCarouselFailureAction,
    deleteCmsCarouselSuccessAction,
    deleteCmsCarouselFailureAction,
    createCmsCarouselSuccessAction,
    createCmsCarouselFailureAction,
    updateCmsCarouselSuccessAction,
    updateCmsCarouselFailureAction,
    removeCmsCarouselSuccessAction,
    removeCmsCarouselFailureAction,
    deleteImageCmsCarouselSuccessAction,
    deleteImageCmsCarouselFailureAction
} from './cmsCarouselAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/CmsCarousel/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/CmsCarousel/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/CmsCarousel/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/CmsCarousel/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/CmsCarousel/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/CmsCarousel/Update';
const API_REMOVE = config.HOST + config.VERSION + '/CmsCarousel/Remove';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/CmsCarouselAttachment/DeleteImage?imagePath=';

// GETALL
const getAllCmsCarouselsEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + (action.payload ? action.payload : ''), config.HEADERS());
        })
            .pipe(
                map((response) => getAllCmsCarouselsSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllCmsCarouselsFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.CREATE),
    mergeMap((action) => {
        console.log(action.payload)
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(createCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.REMOVE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.remove(API_REMOVE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => removeCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(removeCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

// DELETE IMAGE
const deleteImageCmsCarouselEpic = (action$, store) => action$.pipe(
    ofType(CMSCAROUSEL.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteImageCmsCarouselSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteImageCmsCarouselFailureAction(error));
                    }
                })
            );
    })
);

export { getAllCmsCarouselsEpic, getByIdCmsCarouselEpic, changeStatusCmsCarouselEpic, deleteCmsCarouselEpic, 
    createCmsCarouselEpic, updateCmsCarouselEpic, removeCmsCarouselEpic, deleteImageCmsCarouselEpic };