import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROFILE } from './profileAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getByIdProfileSuccessAction,
    getByIdProfileFailureAction,
    updateProfileSuccessAction,
    updateProfileFailureAction
} from './profileAction';
import config from 'common/config';

const API_GETBYID = config.HOST + config.VERSION + '/Auth/GetProfileById?id=';
const API_UPDATE = config.HOST + config.VERSION + '/admin/User';

// GETBYID
const getByIdProfileEpic = (action$, store) => action$.pipe(
    ofType(PROFILE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdProfileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdProfileFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateProfileEpic = (action$, store) => action$.pipe(
    ofType(PROFILE.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateProfileSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateProfileFailureAction(error));
                    }
                })
            );
    })
);

export { getByIdProfileEpic, updateProfileEpic };