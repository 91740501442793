import { createAction } from "redux-actions";
export const DIRECTION = {
    GETALL: "DIRECTION_GETALL",
    GETALL_SUCCESS: "DIRECTION_GETALL_SUCCESS",
    GETALL_FAILURE: "DIRECTION_GETALL_FAILURE",
    GETBYID: "DIRECTION_GETBYID",
    GETBYID_SUCCESS: "DIRECTION_GETBYID_SUCCESS",
    GETBYID_FAILURE: "DIRECTION_GETBYID_FAILURE",
    CHANGE_STATUS: "DIRECTION_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "DIRECTION_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "DIRECTION_CHANGE_STATUS_FAILURE",
    DELETE: "DIRECTION_DELETE",
    DELETE_SUCCESS: "DIRECTION_DELETE_SUCCESS",
    DELETE_FAILURE: "DIRECTION_DELETE_FAILURE",
    ADD: "DIRECTION_ADD",
    ADD_SUCCESS: "DIRECTION_ADD_SUCCESS",
    ADD_FAILURE: "DIRECTION_ADD_FAILURE",
    EDIT: "DIRECTION_EDIT",
    EDIT_SUCCESS: "DIRECTION_EDIT_SUCCESS",
    EDIT_FAILURE: "DIRECTION_EDIT_FAILURE",
    CREATE_RESET: 'DIRECTION_CREATE_RESET',
};
export const getAllDirectionAction = createAction(DIRECTION.GETALL);
export const getAllDirectionSuccessAction = createAction(DIRECTION.GETALL_SUCCESS);
export const getAllDirectionFailureAction = createAction(DIRECTION.GETALL_FAILURE);
export const getByIdDirectionAction = createAction(DIRECTION.GETBYID);
export const getByIdDirectionSuccessAction = createAction(DIRECTION.GETBYID_SUCCESS);
export const getByIdDirectionFailureAction = createAction(DIRECTION.GETBYID_FAILURE);
export const changeStatusDirectionAction = createAction(DIRECTION.CHANGE_STATUS);
export const changeStatusDirectionSuccessAction = createAction(DIRECTION.CHANGE_STATUS_SUCCESS);
export const changeStatusDirectionFailureAction = createAction(DIRECTION.CHANGE_STATUS_FAILURE);
export const deleteDirectionAction = createAction(DIRECTION.DELETE);
export const deleteDirectionSuccessAction = createAction(DIRECTION.DELETE_SUCCESS);
export const deleteDirectionFailureAction = createAction(DIRECTION.DELETE_FAILURE);
export const addDirectionAction = createAction(DIRECTION.ADD);
export const addDirectionSuccessAction = createAction(DIRECTION.ADD_SUCCESS);
export const addDirectionFailureAction = createAction(DIRECTION.ADD_FAILURE);
export const editDirectionAction = createAction(DIRECTION.EDIT);
export const editDirectionSuccessAction = createAction(DIRECTION.EDIT_SUCCESS);
export const editDirectionFailureAction = createAction(DIRECTION.EDIT_FAILURE);
export const createResetDirectionAction = createAction(DIRECTION.CREATE_RESET);
