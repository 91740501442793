import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { DEVELOPMENTTYPE } from './developmentTypeAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllDevelopmentTypeSuccessAction,
    getAllDevelopmentTypeFailureAction,
    getByIdDevelopmentTypeSuccessAction,
    getByIdDevelopmentTypeFailureAction,
    changeStatusDevelopmentTypeSuccessAction,
    changeStatusDevelopmentTypeFailureAction,
    deleteDevelopmentTypeSuccessAction,
    deleteDevelopmentTypeFailureAction,
    addDevelopmentTypeSuccessAction,
    addDevelopmentTypeFailureAction,
    editDevelopmentTypeSuccessAction,
    editDevelopmentTypeFailureAction,
} from './developmentTypeAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/DevelopmentType/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/DevelopmentType/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/DevelopmentType/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/DevelopmentType/Delete/';
const API_POST = config.HOST + config.VERSION + '/DevelopmentType/Create';
const API_UPSERT = config.HOST + config.VERSION + '/DevelopmentType/Update';

const getAllDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editDevelopmentTypeEpic = (action$, store) => action$.pipe(
    ofType(DEVELOPMENTTYPE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editDevelopmentTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editDevelopmentTypeFailureAction(error));
                    }
                })
            );
    })
);


export { getAllDevelopmentTypeEpic, getByIdDevelopmentTypeEpic, changeStatusDevelopmentTypeEpic, deleteDevelopmentTypeEpic, addDevelopmentTypeEpic, editDevelopmentTypeEpic };