import config from 'common/config';
import { ofType } from 'redux-observable';
import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { RECYCLEBIN, restoreRecordRecycleBinSuccessAction } from './recyclebinAction';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getNewToken } from '../../services/getNewToken';
import { getAllRecycleBinSuccessAction, getAllRecycleBinFailureAction, deleteRecycleBinSuccessAction, deleteRecycleBinFailureAction } from './recyclebinAction';

const API_GETALL = config.HOST + config.VERSION + '/RecycleBinManagement/GetAllRecordsByStatusDelete';
const API_DELETE = config.HOST + config.VERSION + '/RecycleBinManagement/DeleteRecordsByStatusDelete';
const API_RESTORE =
  config.HOST +
  config.VERSION +
  "/RecycleBinManagement/RestoreRecordById";

const getAllRecycleBinEpic = (action$, store) => action$.pipe(
    ofType(RECYCLEBIN.GETALL),
    mergeMap(() => defer(() => ajax.getJSON(API_GETALL, config.HEADERS()))
        .pipe(map((response) => getAllRecycleBinSuccessAction(response)),
            catchError((error, source) => {
                if (error.status === 401) return getNewToken(action$, source);
                else return of(getAllRecycleBinFailureAction(error));
            })
        ))
);

// DELETE
const deleteAllRecycleBinEpic = (action$, store) => action$.pipe(
    ofType(RECYCLEBIN.DELETE),
    mergeMap(() => defer(() => ajax.delete(API_DELETE, config.HEADERS())
            .pipe(map((response) => deleteRecycleBinSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) return getNewToken(action$, source);
                    else return of(deleteRecycleBinFailureAction(error));
                })
            ))
    )
);
// RESTORE
const restoreRecordRecycleBinEpic = (action$, store) => action$.pipe(
    ofType(RECYCLEBIN.RESTORE_RECORD),
    mergeMap((action) => defer(() => ajax.put(API_RESTORE,action.payload,config.HEADERS())
            .pipe(map((response) => restoreRecordRecycleBinSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) return getNewToken(action$, source);
                    else return of(restoreRecordRecycleBinSuccessAction(error));
                })
            ))
    )
);

export {
  getAllRecycleBinEpic,
  deleteAllRecycleBinEpic,
  restoreRecordRecycleBinEpic,
};
