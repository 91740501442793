import config from 'common/config';
import { ofType } from 'redux-observable';
import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getNewToken } from '../../services/getNewToken';
import { addBuildingFailureAction, addBuildingSuccessAction, BUILDING, changeStatusBuildingFailureAction, changeStatusBuildingSuccessAction, deleteBuildingFailureAction, deleteBuildingSuccessAction, editBuildingFailureAction, editBuildingSuccessAction, getAllBuildingFailureAction, getAllBuildingSuccessAction, getByIdBuildingFailureAction, getByIdBuildingSuccessAction } from './buildingAction';

const API_GETALL = config.HOST + config.VERSION + '/Building/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/Building/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Building/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Building/Delete/';
const API_POST = config.HOST + config.VERSION + '/Building/Create';
const API_UPSERT = config.HOST + config.VERSION + '/Building/Update';

const getAllBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.GETALL),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllBuildingFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdBuildingFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusBuildingFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteBuildingFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addBuildingFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editBuildingEpic = (action$) => action$.pipe(
    ofType(BUILDING.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editBuildingSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editBuildingFailureAction(error));
                    }
                })
            );
    })
);


export { getAllBuildingEpic, getByIdBuildingEpic, changeStatusBuildingEpic, deleteBuildingEpic, addBuildingEpic, editBuildingEpic };
