import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { CONFIG_TYPE } from './configTypeAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getConfigTypesSuccessAction,
    getConfigTypesFailureAction,
    getConfigTypeByIdSuccessAction,
    getConfigTypeByIdFailureAction,
    changeStatusConfigTypeSuccessAction,
    changeStatusConfigTypeFailureAction,
    deleteConfigTypeSuccessAction,
    deleteConfigTypeFailureAction,
    createConfigTypeSuccessAction,
    createConfigTypeFailureAction,
    updateConfigTypeSuccessAction,
    updateConfigTypeFailureAction
} from './configTypeAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/ConfigType/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/ConfigType/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/ConfigType/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/ConfigType/Delete/';
const API_ADD = config.HOST + config.VERSION + '/ConfigType/Create';
const API_EDIT = config.HOST + config.VERSION + '/ConfigType/Update';

// GETALL
const getConfigTypesEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getConfigTypesSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getConfigTypesFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getConfigTypeByIdEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getConfigTypeByIdSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getConfigTypeByIdFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusConfigTypeEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusConfigTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusConfigTypeFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteConfigTypeEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteConfigTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteConfigTypeFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createConfigTypeEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_ADD, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createConfigTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        console.log("Test error: " + error);
                        return of(createConfigTypeFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateConfigTypeEpic = (action$, store) => action$.pipe(
    ofType(CONFIG_TYPE.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_EDIT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateConfigTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateConfigTypeFailureAction(error));
                    }
                })
            );
    })
);

export { getConfigTypesEpic, changeStatusConfigTypeEpic, deleteConfigTypeEpic, createConfigTypeEpic, updateConfigTypeEpic, getConfigTypeByIdEpic };