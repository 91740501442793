import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PROJECT } from './projectAction';
//import { getNewToken } from '../../services/getNewToken';
import {
    getAllProjectsSuccessAction,
    getAllProjectsFailureAction,
    getByIdProjectSuccessAction,
    getByIdProjectFailureAction,
    changeStatusProjectSuccessAction,
    changeStatusProjectFailureAction,
    deleteProjectSuccessAction,
    deleteProjectFailureAction,
    createProjectSuccessAction,
    createProjectFailureAction,
    updateProjectSuccessAction,
    updateProjectFailureAction,
    removeProjectSuccessAction,
    removeProjectFailureAction,
    loadWardProjectSuccessAction,
    loadWardProjectFailureAction,

    loadProvinceProjectSuccessAction,
    loadProvinceProjectFailureAction,

    deleteProjectImageTypeSuccessAction,
    deleteProjectImageTypeFailureAction,

    updateFieldProjectSuccessAction,
    updateFieldProjectFailureAction
} from './projectAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/Project/GetAll';
// const API_GETBYID = config.HOST + config.VERSION + '/Project/GetById?id=';
const API_GETBYID = config.HOST + config.VERSION + '/Project/GetProjectById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Project/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Project/Delete/';
const API_CREATE = config.HOST + config.VERSION + '/Project/Create/';
const API_UPDATE = config.HOST + config.VERSION + '/Project/Update';
const API_REMOVE = config.HOST + config.VERSION + '/Project/Remove/';
const API_GETWARD = config.HOST + config.VERSION + '/LctWard/GetAll';
const API_GET_PROVINCE = config.HOST + config.VERSION + '/LctProvince/GetAll';
// const API_GET_DISTRICT = config.HOST + config.VERSION + '/LctProvince/GetAll';
const API_DELETE_IMAGE = config.HOST + config.VERSION + '/ProjectImageType/DeleteImage?imagePath=';
const API_UPDATE_FIELD = config.HOST + config.VERSION + '/Project/UpdateField';

const deleteProjectImageTypeEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.DELETE_IMAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE_IMAGE + action.payload.path, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteProjectImageTypeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteProjectImageTypeFailureAction(error));
                    }
                })
            );
    })
);

const loadProvinceProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.LOAD_PROVINCE),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GET_PROVINCE, config.HEADERS());
        })
            .pipe(
                map((response) => loadProvinceProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(loadProvinceProjectFailureAction(error));
                    }
                })
            );
    })
);

// const loadDistrictProjectEpic = (action$, store) => action$.pipe(
//     ofType(PROJECT.LOAD_PROVINCE),
//     mergeMap(() => {
//         return defer(() => {
//             return ajax.getJSON(API_GET_PROVINCE, config.HEADERS());
//         })
//             .pipe(
//                 map((response) => loadProvinceProjectSuccessAction(response)),
//                 catchError((error, source) => {
//                     if (error.status === 401) {
//                         //return getNewToken(action$, source);
//                     } else {
//                         return of(loadProvinceProjectFailureAction(error));
//                     }
//                 })
//             );
//     })
// );

const loadWardProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.LOAD_WARD),
    mergeMap(() => {
        return defer(() => {
            return ajax.getJSON(API_GETWARD, config.HEADERS());
        })
            .pipe(
                map((response) => loadWardProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(loadWardProjectFailureAction(error));
                    }
                })
            );
    })
);


// GETALL
const getAllProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getAllProjectsSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getAllProjectsFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(getByIdProjectFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(changeStatusProjectFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(deleteProjectFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const createProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.CREATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CREATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => createProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(createProjectFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const updateProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.UPDATE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPDATE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateProjectFailureAction(error));
                    }
                })
            );
    })
);

// updateField
const updateFieldProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.UPDATE_FIELD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_UPDATE_FIELD + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => updateFieldProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(updateFieldProjectFailureAction(error));
                    }
                })
            );
    })
);

// REMOVE
const removeProjectEpic = (action$, store) => action$.pipe(
    ofType(PROJECT.REMOVE),
    mergeMap((action) => {
        return defer(() => {
          return ajax.delete(API_REMOVE, config.HEADERS());
        })
            .pipe(
                map((response) => removeProjectSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        //return getNewToken(action$, source);
                    } else {
                        return of(removeProjectFailureAction(error));
                    }
                })
            );
    })
);

export { getAllProjectEpic, getByIdProjectEpic, changeStatusProjectEpic, deleteProjectEpic,
    createProjectEpic, updateProjectEpic, removeProjectEpic, loadWardProjectEpic, loadProvinceProjectEpic, deleteProjectImageTypeEpic, updateFieldProjectEpic };