import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { DISTRICT } from './districtAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllDistrictSuccessAction,
    getAllDistrictFailureAction,
    getAllFullDistrictSuccessAction,
    getAllFullDistrictFailureAction,
    getByIdDistrictSuccessAction,
    getByIdDistrictFailureAction,
    changeStatusDistrictSuccessAction,
    changeStatusDistrictFailureAction,
    deleteDistrictSuccessAction,
    deleteDistrictFailureAction,
    addDistrictSuccessAction,
    addDistrictFailureAction,
    editDistrictSuccessAction,
    editDistrictFailureAction,
    getByProvinceCodeSuccessAction,
    getByProvinceCodeFailureAction,
} from './districtAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/LctDistrict/GetAll?pageSize=100&pageNumber=1';
const API_GETALL_FULL = config.HOST + config.VERSION + '/LctDistrict/GetAll?pageSize=-999&pageNumber=-999';
const API_GETBYID = config.HOST + config.VERSION + '/LctDistrict/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/LctDistrict/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/LctDistrict/Delete/';
const API_POST = config.HOST + config.VERSION + '/LctDistrict/Create';
const API_UPSERT = config.HOST + config.VERSION + '/LctDistrict/Update';
const API_GETPROVINCIAL = config.HOST + config.VERSION + '/LctDistrict/GetByProvinceCode?provinceCode=';
const getAllDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllDistrictFailureAction(error));
                    }
                })
            );
    })
);

const getAllFullDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.GETALL_FULL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL_FULL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllFullDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllFullDistrictFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdDistrictFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusDistrictFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteDistrictFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addDistrictFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editDistrictEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editDistrictSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editDistrictFailureAction(error));
                    }
                })
            );
    })
);

const getByProvinceCodeEpic = (action$, store) => action$.pipe(
    ofType(DISTRICT.GETBY_PROVINCE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETPROVINCIAL + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByProvinceCodeSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByProvinceCodeFailureAction(error));
                    }
                })
            );
    })
);


export { getAllDistrictEpic, getAllFullDistrictEpic, getByIdDistrictEpic, changeStatusDistrictEpic, deleteDistrictEpic, addDistrictEpic, editDistrictEpic, getByProvinceCodeEpic };