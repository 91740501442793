import { handleActions } from "redux-actions";
import { SIGNUP_SEE_HOUSE } from "../signupseehouseAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [SIGNUP_SEE_HOUSE.GETALL]: () => ({
    isLoading: true,
  }),
  [SIGNUP_SEE_HOUSE.GETALL_SUCCESS]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    responseData,
  }),
  [SIGNUP_SEE_HOUSE.GETALL_FAILURE]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  })
};
export default handleActions(actions, initialState);