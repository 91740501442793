import { handleActions } from "redux-actions";
import { UPLOAD } from "../uploadAction";
const initialState = {
  isLoading: false,
  isError: false,
  responseData: {},
};
const actions = {
  [UPLOAD.RESET]: (state, { payload: responseData }) => {
    state = initialState;
    state.isReset = responseData;
    return state;
  } 
};
export default handleActions(actions, initialState);