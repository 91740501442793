import { createAction } from "redux-actions";
export const UPLOAD_THUMBNAIL_MULTIPLE = {
    CHUNK_THUMBNAIL_MULTIPLE: "CHUNK_THUMBNAIL_MULTIPLE_UPLOAD",
    CHUNK_THUMBNAIL_MULTIPLE_SUCCESS: "CHUNK_THUMBNAIL_MULTIPLE_UPLOAD_SUCCESS",
    CHUNK_THUMBNAIL_MULTIPLE_FAILURE: "CHUNK_THUMBNAIL_MULTIPLE_UPLOAD_FAILURE",

    THUMBNAIL_THUMBNAIL_MULTIPLE: "THUMBNAIL_THUMBNAIL_MULTIPLE_UPLOAD",
    THUMBNAIL_THUMBNAIL_MULTIPLE_SUCCESS: "THUMBNAIL_THUMBNAIL_MULTIPLE_UPLOAD_SUCCESS",
    THUMBNAIL_THUMBNAIL_MULTIPLE_FAILURE: "THUMBNAIL_THUMBNAIL_MULTIPLE_UPLOAD_FAILURE",
    THUMBNAIL_RESET_THUMBNAIL_MULTIPLE: "THUMBNAIL_RESET_THUMBNAIL_MULTIPLE",
    
    CREATE_THUMBNAIL_MULTIPLE: "CREATE_THUMBNAIL_MULTIPLE_UPLOAD",
    CREATE_THUMBNAIL_MULTIPLE_SUCCESS: "CREATE_THUMBNAIL_MULTIPLE_UPLOAD_SUCCESS",
    CREATE_THUMBNAIL_MULTIPLE_FAILURE: "CREATE_THUMBNAIL_MULTIPLE_UPLOAD_FAILURE",    

    RESET_THUMBNAIL_MULTIPLE: "RESET_THUMBNAIL_MULTIPLE",    


};
export const chunkThumbnailMultipleUploadAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CHUNK_THUMBNAIL_MULTIPLE);
export const chunkThumbnailMultipleUploadSuccessAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CHUNK_THUMBNAIL_MULTIPLE_SUCCESS);
export const chunkThumbnailMultipleUploadFailureAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CHUNK_THUMBNAIL_MULTIPLE_FAILURE);

export const thumbnailMultipleUploadAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.THUMBNAIL_THUMBNAIL_MULTIPLE);
export const thumbnailMultipleUploadSuccessAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.THUMBNAIL_THUMBNAIL_MULTIPLE_SUCCESS);
export const thumbnailMultipleUploadFailureAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.THUMBNAIL_THUMBNAIL_MULTIPLE_FAILURE);
export const thumbnailMultipleUploadResetAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.THUMBNAIL_RESET_THUMBNAIL_MULTIPLE);


export const createThumbnailMultipleUploadAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CREATE_THUMBNAIL_MULTIPLE);
export const createThumbnailMultipleUploadSuccessAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CREATE_THUMBNAIL_MULTIPLE_SUCCESS);
export const createThumbnailMultipleUploadFailureAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.CREATE_THUMBNAIL_MULTIPLE_FAILURE);
export const resetThumbnailMultipleUploadAction = createAction(UPLOAD_THUMBNAIL_MULTIPLE.RESET_THUMBNAIL_MULTIPLE);