export const commonConstant = {
    TRANSLATION_KEY: 'translation-language',
    ADD: 'ADD',
    EDIT: 'EDIT',
    EDIT_ROLE: 'EDIT_ROLE',
    DELETE: 'DELETE',
    CHANGE_STATUS: 'CHANGE_STATUS',
    CHANGE_ISDEFAULT: 'CHANGE_ISDEFAULT',
    GET_BY_ID: 'GET_BY_ID',
    GET_ALL: 'GET_ALL',
    RECORD_COUNT: {
        CONTENT: 'RECORD_COUNT_CONTENT',
        CATEGORY: 'RECORD_COUNT_CATEGORY',
        ARTICLE: 'RECORD_COUNT_ARTICLE',
        CAROUSEL: 'RECORD_COUNT_CAROUSEL',
        PURPOSE: 'RECORD_COUNT_PURPOSE',
        PROPERTY_TYPE: 'RECORD_COUNT_PROPERTY_TYPE',
        UTILITY: 'RECORD_COUNT_UTILITY',
        PROGRESS: 'RECORD_COUNT_PROGRESS',
        PROPERTY: 'RECORD_COUNT_PROPERTY'
    },
    LOADING_FORM: 'LOADING_FORM',
    CHECK_DUPLICATE: 'CHECK_DUPLICATE',
    CHECK_DUPLICATE_USERNAME: 'CHECK_DUPLICATE_USERNAME',
    CHECK_DUPLICATE_EMAIL: 'CHECK_DUPLICATE_EMAIL',
    // AUTHORIZE
    AUTH_ID: 'AUTH_ID',
    AUTH_TOKEN: 'AUTH_TOKEN',
    AUTH_EXPIRES_IN: 'AUTH_EXPIRES_IN',
    // REFRESH_TOKEN
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_EXPIRES_IN: 'REFRESH_EXPIRES_IN',
    // ROLE
    FILTER_ROLE: 'FILTER_ROLE',
    ROLE_ERROR_MSG_EXISTED: 'RoleIsExisted',
    ROLE_KEY_EDIT: 'ROLE_KEY_EDIT',
    // USER
    FIRSTNAME: 'FIRSTNAME',
    LASTNAME: 'LASTNAME',
    PICTUREURL: 'PICTUREURL',
    FILTER_USER: 'FILTER_USER',
    USER_ERROR_MSG_EXISTED: 'UserIsExisted',
    USER_KEY_EDIT: 'USER_KEY_EDIT',
    USER_ACCOUNT_INACTIVE_MSG: 'Account is inactive!',
    REGEX_PHONE_NUMBER: /(09|0[2|3|4|5|6|7|8|9])+([0-9]{8})\b/,

    //FILTER
    FILTER: 'FILTER_',

    //PAGINATION
    PAGE_SIZE: 10,
    //Parameter
    ParamAdd: 'add',

    //PROPERTY DATA
    FITLERED_PROPERTY_DATA: 'FITLERED_PROPERTY_DATA',
    SELECTED_PROPERTY_IDS: 'SELECTED_PROPERTY_IDS',

    LANG: "LANG",
    CROP_DIMENSION_WIDTH: "CROP_DIMENSION_WIDTH",
    CROP_DIMENSION_HEIGHT: "CROP_DIMENSION_HEIGHT",

    VIDEO_SIZE: "VIDEO_SIZE"
};
export const userTypes = [{ value: 'owner', label: 'Chủ nhà' }, { value: 'agency', label: 'Môi giới' }];
export const statusModels = [{ value: 0, label: 'Chưa kích hoạt' }, { value: 1, label: 'Kích hoạt' }];
export const statusPropertyModels = [{ value: null, label: 'Vui lòng chọn...' }, { value: 0, label: 'Chưa kích hoạt' }, { value: 1, label: 'Kích hoạt' }];
export const statusEnglishModels = [{ value: 0, label: 'Not Activated' }, { value: 1, label: 'Activated' }];
export const statusPropertyEnglishModels = [{ value: null, label: 'Please select...' }, { value: 0, label: 'Not Activated' }, { value: 1, label: 'Activated' }];
export const languageModels = [{ value: '', label: 'Vui lòng chọn ngôn ngữ' }, { value: 1, label: 'Tiếng Việt' }, { value: 2, label: 'English' }];
export const languageModels2 = [{ value: 'vi', label: 'Tiếng Việt' }, { value: 'en', label: 'English' }];
export const userTypeModels = [{ value: '', label: 'Vui lòng chọn loại người dùng' }, { value: 'NV', label: 'Nhân viên' }, { value: 'KH', label: 'Khách hàng' }];
export const acceptTypes = 
{
    image: "image/*", 
    video: "video/*", 
    audio: "audio/*",
    media_type: "media_type",
    pdf: ".pdf",
    document: ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
};
export const listPrice = [
    { value: -1, label: 'Tất cả' }, 
    { value: 0, label: '0 - 100.000.000' }, 
    { value: 100000000, label: '100.000.000 - 1.000.000.000' },
    { value: 1000000000, label: '1.000.000.000 - 2.000.000.000' },
    { value: 2000000000, label: '2.000.000.000 - 5.000.000.000' },
    { value: 5000000000, label: '5.000.000.000 - 10.000.000.000' },
    { value: 10000000000, label: '10.000.000.000 - 20.000.000.000' },
    { value: 20000000000, label: '20.000.000.000 - 50.000.000.000' },
    { value: 50000000000, label: '50.000.000.000 - 100.000.000.000' },
    { value: 100000000000, label: '> 100.000.000.000' },
];
export const listArea = [
    { value: -1, label: 'Tất cả' }, 
    { value: 0, label: '0 - 100m2' }, 
    { value: 100, label: '100m2 - 200m2' },
    { value: 200, label: '200m2 - 300m2' },
    { value: 300, label: '300m2 - 400m2' },
    { value: 400, label: '400m2 - 500m2' },
    { value: 500, label: '>500m2' },
];
export const bathroomList = [
    { value: -1, label: 'Tất cả' }, 
    { value: 1, label: '1' }, 
    { value: 2, label: '2' }, 
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];
export const bedroomList = [
    { value: -1, label: 'Tất cả' }, 
    { value: 1, label: '1' }, 
    { value: 2, label: '2' }, 
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];

export const comparisonOperator =
{
    equal: "eq",
    notEqual: "ne",
    greaterThan: "gt",
    greaterThanOrEqual: "ge",
    lessThan: "lt",
    lessThanOrEqual: "le",
    like: "lk",
    beginsWith: "bw",
    doesNotBeginWith: "nbw",
    isIn: "ii",
    isNotIn: "ini",
    endsWith: "ew",
    doesNotEndWith: "new",
    contains: "co",
    doesNotContain: "nco",
    isNull: "in",
    isNotNull: "inn"

}

export const logicalOperator =
{
    and: "and",
    or: "or"
}

//export const isDefaultModels = [{ Id: false, KeyName: 'No' }, { Id: true, KeyName: 'Yes' }];
export const getBadge = status => {
    // eslint-disable-next-line default-case
    switch (status) {
        case 1: return 'success'
        case 0: return 'secondary'
        // case 'Pending': return 'warning'
        // case 'Banned': return 'primary'
        default: return 'warning'
    }
}

export const getStatusName = status => {
    // eslint-disable-next-line default-case
    switch (status) {
        case 0: return 'Chưa kích hoạt'
        case 1: return 'Kích hoạt'
        case 2: return 'Đã xóa'
        default: return ''
    }
}

export const getLanguageName = status => {
    // eslint-disable-next-line default-case
    switch (status) {
        case 1: return 'Tiếng Việt'
        case 2: return 'English'
        default: return ''
    }
}

export const trimText = (text, length) => {
    if (!text || text == null) return ""; 
    if (text.length >= length) return text.substr(0, length) + "...";
    return text;
}

export const aggregateText = (text) => {
    let allMessages = Object.keys(text).map(item => {
        return text[item][0];
        //return item;
    });
    return allMessages.join(". ");
}

export const errorCode = {
    UnAuthorized: 401,
    BadRequest: 400,
    NotFound: 404,
    Forbidden: 403,
    InternalServerEror: 500
};

export const languageCodeModels =
{
    Vietnamese: "vi",
    English: "en"
}

export default commonConstant;