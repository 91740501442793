import { createAction } from "redux-actions";
export const ACCESS = {
    GETALL: "ACCESS_GETALL",
    GETALL_SUCCESS: "ACCESS_GETALL_SUCCESS",
    GETALL_FAILURE: "ACCESS_GETALL_FAILURE",
    GETBYID: "ACCESS_GETBYID",
    GETBYID_SUCCESS: "ACCESS_GETBYID_SUCCESS",
    GETBYID_FAILURE: "ACCESS_GETBYID_FAILURE",
    CHANGE_STATUS: "ACCESS_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "ACCESS_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "ACCESS_CHANGE_STATUS_FAILURE",
    DELETE: "ACCESS_DELETE",
    DELETE_SUCCESS: "ACCESS_DELETE_SUCCESS",
    DELETE_FAILURE: "ACCESS_DELETE_FAILURE",
    ADD: "ACCESS_ADD",
    ADD_SUCCESS: "ACCESS_ADD_SUCCESS",
    ADD_FAILURE: "ACCESS_ADD_FAILURE",
    EDIT: "ACCESS_EDIT",
    EDIT_SUCCESS: "ACCESS_EDIT_SUCCESS",
    EDIT_FAILURE: "ACCESS_EDIT_FAILURE",
    CREATE_RESET: 'ACCESS_CREATE_RESET',
    GETBY_PROVINCE:'ACCESS_GETBY_PROVINCE_CODE',
    GETBY_PROVINCE_SUCCESS:'ACCESS_GETBY_PROVINCE_SUCCESS',
    GETBY_PROVINCE_FAILURE:'ACCESS_GETBY_PROVINCE_FAILURE',
    
    GET_BY_PAGE_NUMBER:'GET_BY_PAGE_NUMBER',
    GET_BY_PAGE_NUMBER_SUCCESS:'GET_BY_PAGE_NUMBER_SUCCESS',
    GET_BY_PAGE_NUMBER_FAILURE:'GET_BY_PAGE_NUMBER_FAILURE',
};
export const getAllAccessAction = createAction(ACCESS.GETALL);
export const getAllAccessSuccessAction = createAction(ACCESS.GETALL_SUCCESS);
export const getAllAccessFailureAction = createAction(ACCESS.GETALL_FAILURE);
export const getByIdAccessAction = createAction(ACCESS.GETBYID);
export const getByIdAccessSuccessAction = createAction(ACCESS.GETBYID_SUCCESS);
export const getByIdAccessFailureAction = createAction(ACCESS.GETBYID_FAILURE);
export const changeStatusAccessAction = createAction(ACCESS.CHANGE_STATUS);
export const changeStatusAccessSuccessAction = createAction(ACCESS.CHANGE_STATUS_SUCCESS);
export const changeStatusAccessFailureAction = createAction(ACCESS.CHANGE_STATUS_FAILURE);
export const deleteAccessAction = createAction(ACCESS.DELETE);
export const deleteAccessSuccessAction = createAction(ACCESS.DELETE_SUCCESS);
export const deleteAccessFailureAction = createAction(ACCESS.DELETE_FAILURE);
export const addAccessAction = createAction(ACCESS.ADD);
export const addAccessSuccessAction = createAction(ACCESS.ADD_SUCCESS);
export const addAccessFailureAction = createAction(ACCESS.ADD_FAILURE);
export const editAccessAction = createAction(ACCESS.EDIT);
export const editAccessSuccessAction = createAction(ACCESS.EDIT_SUCCESS);
export const editAccessFailureAction = createAction(ACCESS.EDIT_FAILURE);
export const createResetAccessAction = createAction(ACCESS.CREATE_RESET);

export const getByPageNumberAccessAction = createAction(ACCESS.GET_BY_PAGE_NUMBER);
export const getByPageNumberAccessSuccessAction = createAction(ACCESS.GET_BY_PAGE_NUMBER_SUCCESS);
export const getByPageNumberAccessFailureAction = createAction(ACCESS.GET_BY_PAGE_NUMBER_FAILURE);