import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { TRANSLATION } from './transLationAction';
import { getNewToken } from '../../../services/getNewToken';
import {
    changeTransLationSuccessAction,
    changeTransLationFailureAction,
} from './transLationAction';
import config from 'common/config';

const API_CHANGE_LANGUAGE = config.HOST + config.VERSION + '/Translation/TextTranslation';

const changeTransLationEpic = (action$, store) => action$.pipe(
    ofType(TRANSLATION.CHANGE_LANGUAGE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_CHANGE_LANGUAGE, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => changeTransLationSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeTransLationFailureAction(error));
                    }
                })
            );
    })
);



export { changeTransLationEpic};