import { handleActions } from "redux-actions";
import { UPLOAD } from "../uploadImageCropAction";

const initialState = {
  isLoading: false,
  isError: false,
  responseData: {}
};
const actions = {
  [UPLOAD.UPLOAD_IMAGE_BASE64]: () => ({
    isLoading: true,
  }),
  [UPLOAD.UPLOAD_IMAGE_BASE64_SUCCESS]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    responseData,
  }),
  [UPLOAD.UPLOAD_IMAGE_BASE64_FAILURE]: (state, { payload: responseData }) => ({
    ...state,
    isLoading: false,
    isError: true,
    responseData,
  }),
  [UPLOAD.RESET]: (state) => ({
    isLoading: false,
    isError: false,
    responseData: {},
  }),
};
export default handleActions(actions, initialState);