import { handleActions } from "redux-actions";
import { GLOBAL_CONFIG } from "../globalConfigAction";
const initialState = {
    isLoading: false,
    isError: false,
    responseData: {},
};
const actions = {
    [GLOBAL_CONFIG.ADD]: () => ({
        isLoading: true,
    }),
    [GLOBAL_CONFIG.ADD_SUCCESS]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        responseData: responseData.response ? responseData.response : responseData,
    }),
    [GLOBAL_CONFIG.ADD_FAILURE]: (state, { payload: responseData }) => ({
        ...state,
        isLoading: false,
        isError: true,
        responseData,
    }),
    [GLOBAL_CONFIG.CREATE_RESET]: (state, { payload: responseData }) => {
        state = initialState;
        return state;
    },
};
export default handleActions(actions, initialState);