import { createAction } from "redux-actions";
export const PROPERTY_TYPE = {
    GETALL: "PROPERTY_TYPE_GETALL",
    GETALL_SUCCESS: "PROPERTY_TYPE_GETALL_SUCCESS",
    GETALL_FAILURE: "PROPERTY_TYPE_GETALL_FAILURE",
    GETBYID: "PROPERTY_TYPE_GETBYID",
    GETBYID_SUCCESS: "PROPERTY_TYPE_GETBYID_SUCCESS",
    GETBYID_FAILURE: "PROPERTY_TYPE_GETBYID_FAILURE",
    GETALL_BY_PURPOSE_ID: "PROPERTY_TYPE_GETALL_BY_PURPOSE_ID",
    GETALL_BY_PURPOSE_ID_SUCCESS: "PROPERTY_TYPE_GETALL_BY_PURPOSE_ID_SUCCESS",
    GETALL_BY_PURPOSE_ID_FAILURE: "PROPERTY_TYPE_GETALL_BY_PURPOSE_ID_FAILURE",
    CHANGE_STATUS: "PROPERTY_TYPE_CHANGE_STATUS",
    CHANGE_STATUS_SUCCESS: "PROPERTY_TYPE_CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "PROPERTY_TYPE_CHANGE_STATUS_FAILURE",
    DELETE: "PROPERTY_TYPE_DELETE",
    DELETE_SUCCESS: "PROPERTY_TYPE_DELETE_SUCCESS",
    DELETE_FAILURE: "PROPERTY_TYPE_DELETE_FAILURE",
    CREATE: "PROPERTY_TYPE_CREATE",
    CREATE_SUCCESS: "PROPERTY_TYPE_CREATE_SUCCESS",
    CREATE_FAILURE: "PROPERTY_TYPE_CREATE_FAILURE",
    UPDATE: "PROPERTY_TYPE_UPDATE",
    UPDATE_SUCCESS: "PROPERTY_TYPE_UPDATE_SUCCESS",
    UPDATE_FAILURE: "PROPERTY_TYPE_UPDATE_FAILURE",
    REMOVE: "PROPERTY_TYPE_REMOVE",
    REMOVE_SUCCESS: "PROPERTY_TYPE_REMOVE_SUCCESS",
    REMOVE_FAILURE: "PROPERTY_TYPE_REMOVE_FAILURE",
    CREATE_RESET: 'PROPERTY_TYPE_CREATE_RESET',
    UPDATE_RESET: 'PROPERTY_TYPE_UPDATE_RESET'
};
export const getAllPropertyTypesAction = createAction(PROPERTY_TYPE.GETALL);
export const getAllPropertyTypesSuccessAction = createAction(PROPERTY_TYPE.GETALL_SUCCESS);
export const getAllPropertyTypesFailureAction = createAction(PROPERTY_TYPE.GETALL_FAILURE);
export const getAllPropertyTypesByPurposeIdAction = createAction(PROPERTY_TYPE.GETALL_BY_PURPOSE_ID);
export const getAllPropertyTypesByPurposeIdSuccessAction = createAction(PROPERTY_TYPE.GETALL_BY_PURPOSE_ID_SUCCESS);
export const getAllPropertyTypesByPurposeIdFailureAction = createAction(PROPERTY_TYPE.GETALL_BY_PURPOSE_ID_FAILURE);
export const getByIdPropertyTypeAction = createAction(PROPERTY_TYPE.GETBYID);
export const getByIdPropertyTypeSuccessAction = createAction(PROPERTY_TYPE.GETBYID_SUCCESS);
export const getByIdPropertyTypeFailureAction = createAction(PROPERTY_TYPE.GETBYID_FAILURE);
export const changeStatusPropertyTypeAction = createAction(PROPERTY_TYPE.CHANGE_STATUS);
export const changeStatusPropertyTypeSuccessAction = createAction(PROPERTY_TYPE.CHANGE_STATUS_SUCCESS);
export const changeStatusPropertyTypeFailureAction = createAction(PROPERTY_TYPE.CHANGE_STATUS_FAILURE);
export const deletePropertyTypeAction = createAction(PROPERTY_TYPE.DELETE);
export const deletePropertyTypeSuccessAction = createAction(PROPERTY_TYPE.DELETE_SUCCESS);
export const deletePropertyTypeFailureAction = createAction(PROPERTY_TYPE.DELETE_FAILURE);
export const createPropertyTypeAction = createAction(PROPERTY_TYPE.CREATE);
export const createPropertyTypeSuccessAction = createAction(PROPERTY_TYPE.CREATE_SUCCESS);
export const createPropertyTypeFailureAction = createAction(PROPERTY_TYPE.CREATE_FAILURE);
export const updatePropertyTypeAction = createAction(PROPERTY_TYPE.UPDATE);
export const updatePropertyTypeSuccessAction = createAction(PROPERTY_TYPE.UPDATE_SUCCESS);
export const updatePropertyTypeFailureAction = createAction(PROPERTY_TYPE.UPDATE_FAILURE);
export const removePropertyTypeAction = createAction(PROPERTY_TYPE.REMOVE);
export const removePropertyTypeSuccessAction = createAction(PROPERTY_TYPE.REMOVE_SUCCESS);
export const removePropertyTypeFailureAction = createAction(PROPERTY_TYPE.REMOVE_FAILURE);
export const createResetPropertyTypeAction = createAction(PROPERTY_TYPE.CREATE_RESET);
export const updateResetPropertyTypeAction = createAction(PROPERTY_TYPE.UPDATE_RESET);