import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { USER } from './userAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllUsersSuccessAction,
    getAllUsersFailureAction,
    getByIdUserSuccessAction,
    getByIdUserFailureAction,
    changeStatusUserSuccessAction,
    changeStatusUserFailureAction,
    deleteUserSuccessAction,
    deleteUserFailureAction,
    addUserSuccessAction,
    addUserFailureAction,
    editUserSuccessAction,
    editUserFailureAction,
    checkValidUserNameSuccessAction,
    checkValidUserNameFailureAction,
    checkValidEmailSuccessAction,
    checkValidEmailFailureAction,
    updateRoleForUserSuccessAction,
    updateRoleForUserFailureAction
} from './userAction';
import config from 'common/config';

const API_GETALL = config.HOST + config.VERSION + '/User/GetAll';
const API_GETBYID = config.HOST + config.VERSION + '/User/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/User/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/User/Delete/';
const API_UPSERT = config.HOST + config.VERSION + '/admin/User';
const API_CHECKVALIDUSERNAME = config.HOST + config.VERSION + '/User/CheckValidUserName';
const API_CHECKVALIDEMAIL = config.HOST + config.VERSION + '/User/CheckValidEmail';
const API_UPDATEROLEFORUSER = config.HOST + config.VERSION + '/User/UpdateRoleForUser';

// GETALL
const getAllUserEpic = (action$, store) => action$.pipe(
    ofType(USER.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllUsersSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllUsersFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdUserEpic = (action$, store) => action$.pipe(
    ofType(USER.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdUserFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusUserEpic = (action$, store) => action$.pipe(
    ofType(USER.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusUserFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteUserEpic = (action$, store) => action$.pipe(
    ofType(USER.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteUserFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addUserEpic = (action$, store) => action$.pipe(
    ofType(USER.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addUserFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editUserEpic = (action$, store) => action$.pipe(
    ofType(USER.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editUserFailureAction(error));
                    }
                })
            );
    })
);

// CHECKVALIDUSERNAME
const checkValidUserNameEpic = (action$, store) => action$.pipe(
    ofType(USER.CHECKVALIDUSERNAME),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_CHECKVALIDUSERNAME + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => checkValidUserNameSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(checkValidUserNameFailureAction(error));
                    }
                })
            );
    })
);

// CHECKVALIDEMAIL
const checkValidEmailEpic = (action$, store) => action$.pipe(
    ofType(USER.CHECKVALIDEMAIL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_CHECKVALIDEMAIL + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => checkValidEmailSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(checkValidEmailFailureAction(error));
                    }
                })
            );
    })
);

// UPDATEROLEFORUSER
const updateRoleForUserEpic = (action$, store) => action$.pipe(
    ofType(USER.UPDATEROLEFORUSER),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_UPDATEROLEFORUSER, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => updateRoleForUserSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(updateRoleForUserFailureAction(error));
                    }
                })
            );
    })
);

export { getAllUserEpic, getByIdUserEpic, changeStatusUserEpic, deleteUserEpic, addUserEpic, editUserEpic, checkValidUserNameEpic, checkValidEmailEpic, updateRoleForUserEpic };