import { createAction } from "redux-actions";
export const UPLOAD_MULTIPLE = {
    CHUNK_MULTIPLE: "CHUNK_MULTIPLE_UPLOAD",
    CHUNK_MULTIPLE_SUCCESS: "CHUNK_MULTIPLE_UPLOAD_SUCCESS",
    CHUNK_MULTIPLE_FAILURE: "CHUNK_MULTIPLE_UPLOAD_FAILURE",
    
    CREATE_MULTIPLE: "CREATE_MULTIPLE_UPLOAD",
    CREATE_MULTIPLE_SUCCESS: "CREATE_MULTIPLE_UPLOAD_SUCCESS",
    CREATE_MULTIPLE_FAILURE: "CREATE_MULTIPLE_UPLOAD_FAILURE",    

    RESET_MULTIPLE: "RESET_MULTIPLE",    


};
export const chunkMultipleUploadAction = createAction(UPLOAD_MULTIPLE.CHUNK_MULTIPLE);
export const chunkMultipleUploadSuccessAction = createAction(UPLOAD_MULTIPLE.CHUNK_MULTIPLE_SUCCESS);
export const chunkMultipleUploadFailureAction = createAction(UPLOAD_MULTIPLE.CHUNK_MULTIPLE_FAILURE);

export const createMultipleUploadAction = createAction(UPLOAD_MULTIPLE.CREATE_MULTIPLE);
export const createMultipleUploadSuccessAction = createAction(UPLOAD_MULTIPLE.CREATE_MULTIPLE_SUCCESS);
export const createMultipleUploadFailureAction = createAction(UPLOAD_MULTIPLE.CREATE_MULTIPLE_FAILURE);
export const resetMultipleUploadAction = createAction(UPLOAD_MULTIPLE.RESET_MULTIPLE);