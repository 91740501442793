import { defer, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { DIRECTION } from './directionAction';
import { getNewToken } from '../../services/getNewToken';
import {
    getAllDirectionSuccessAction,
    getAllDirectionFailureAction,
    getByIdDirectionSuccessAction,
    getByIdDirectionFailureAction,
    changeStatusDirectionSuccessAction,
    changeStatusDirectionFailureAction,
    deleteDirectionSuccessAction,
    deleteDirectionFailureAction,
    addDirectionSuccessAction,
    addDirectionFailureAction,
    editDirectionSuccessAction,
    editDirectionFailureAction,
} from './directionAction';
import config from 'common/config';
const API_GETALL = config.HOST + config.VERSION + '/Direction/GetAll?pageSize=100&pageNumber=1';
const API_GETBYID = config.HOST + config.VERSION + '/Direction/GetById?id=';
const API_CHANGE_STATUS = config.HOST + config.VERSION + '/Direction/ChangeStatus/';
const API_DELETE = config.HOST + config.VERSION + '/Direction/Delete/';
const API_POST = config.HOST + config.VERSION + '/Direction/Create';
const API_UPSERT = config.HOST + config.VERSION + '/Direction/Update';

const getAllDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.GETALL),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETALL, config.HEADERS());
        })
            .pipe(
                map((response) => getAllDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getAllDirectionFailureAction(error));
                    }
                })
            );
    })
);

// GETBYID
const getByIdDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.GETBYID),
    mergeMap((action) => {
        return defer(() => {
            return ajax.getJSON(API_GETBYID + action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => getByIdDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(getByIdDirectionFailureAction(error));
                    }
                })
            );
    })
);

// CHANGE STATUS
const changeStatusDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.CHANGE_STATUS),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_CHANGE_STATUS + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => changeStatusDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(changeStatusDirectionFailureAction(error));
                    }
                })
            );
    })
);

// DELETE
const deleteDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.DELETE),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_DELETE + action.payload, null, config.HEADERS());
        })
            .pipe(
                map((response) => deleteDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(deleteDirectionFailureAction(error));
                    }
                })
            );
    })
);

// ADD
const addDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.ADD),
    mergeMap((action) => {
        return defer(() => {
            return ajax.post(API_POST, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => addDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(addDirectionFailureAction(error));
                    }
                })
            );
    })
);

// EDIT
const editDirectionEpic = (action$, store) => action$.pipe(
    ofType(DIRECTION.EDIT),
    mergeMap((action) => {
        return defer(() => {
            return ajax.put(API_UPSERT, action.payload, config.HEADERS());
        })
            .pipe(
                map((response) => editDirectionSuccessAction(response)),
                catchError((error, source) => {
                    if (error.status === 401) {
                        return getNewToken(action$, source);
                    } else {
                        return of(editDirectionFailureAction(error));
                    }
                })
            );
    })
);


export { getAllDirectionEpic, getByIdDirectionEpic, changeStatusDirectionEpic, deleteDirectionEpic, addDirectionEpic, editDirectionEpic };